import type { IFormElementProps } from "./types.tsx";
import "./index.css";
import { useMemo } from "react";
import { Tooltip } from "../Tooltip";
import { Info } from "lucide-react";
import { Message } from "../Message";
import { Typography } from "../Typography";
import { clsx } from "clsx";

export const FormElement = (props: IFormElementProps) => {
  const rootClass: string = clsx("analog-formElement", props.className);

  const messageJSX = useMemo(() => {
    if (typeof props.message === "string") {
      if (props.message.length === 0) {
        return null;
      }

      return (
        <Message
          className="analog-formElement__message"
          text={props.message}
          variant="error"
        />
      );
    }

    if (props.message === undefined) {
      return null;
    }

    return (
      <Message
        className="analog-formElement__message"
        text={props.message.text}
        variant={props.message.variant || "error"}
      />
    );
  }, [props.message]);

  return (
    <div className={rootClass}>
      {props.label && (
        <div className="analog-formElement__label">
          <Typography
            as="span"
            className="analog-formElement__labelText"
            text={props.label}
          />
          {props.required && (
            <Typography
              as="span"
              className="analog-formElement__requiredMark"
              text="*"
            />
          )}
          {props.tooltipProps && (
            <div className="analog-formElement__tooltip">
              <Tooltip
                {...props.tooltipProps}
                placement={props.tooltipProps.placement || "right-start"}
                anchor={<Info size={16} />}
              />
            </div>
          )}
          {props.adornment && (
            <div className="analog-formElement__adornment">
              {props.adornment}
            </div>
          )}
        </div>
      )}
      <div className="analog-formElement__element">{props.children}</div>
      {messageJSX}
    </div>
  );
};
