// GroupCard.tsx
import React from "react";
import styles from "./group-card.module.scss";
import { ReactComponent as DeleteIcon } from "images/close.svg";
import { ReactComponent as EditIcon } from "images/edit.svg";
import clsx from "clsx";

interface GroupCardProps {
  groupName: string;
  description: string;
  memberImages: string[];
  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const GroupCard: React.FC<GroupCardProps> = ({
  groupName,
  description,
  memberImages,
  onClick,
  onDelete,
  onEdit,
}) => {
  const hiddenCount = memberImages.length > 5 ? memberImages.length - 5 : 0;
  return (
    <div className={styles.groupCard}>
      <div onClick={onClick}>
        <div className={styles.groupContent}>
          <h2 className={styles.groupName}>{groupName}</h2>
          <p className={styles.groupDescription}>{description}</p>
        </div>
        <div className={styles.groupMembers}>
          {memberImages.slice(0, 5).map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Member ${index + 1}`}
              className={styles.memberImage}
            />
          ))}
          {hiddenCount > 0 && (
            <div className={styles.memberRemainder}>
              <span>+{hiddenCount}</span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.actionButtons}>
        <button
          type="button"
          className={clsx(styles.deleteButton)}
          onClick={onDelete}
        >
          <DeleteIcon className={styles.deleteButtonIcon} />
        </button>
        <button
          type="button"
          className={clsx(styles.deleteButton)}
          onClick={onEdit}
        >
          <EditIcon className={styles.editButtonIcon} />
        </button>
      </div>
    </div>
  );
};

export default GroupCard;
