import s from "./index.module.css";
import { Bike } from "lucide-react";

export const EmptyState = () => {
  return (
    <div className={s.emptyState}>
      <div className={s.emptyStateImage}>
        <Bike size={32} />
      </div>
      <div className={`${s.emptyStateText} analog-typography--headline`}>
        No feedback submitted yet
      </div>
    </div>
  );
};
