const IllnessThroatInfection = ({ opacity }: { opacity: number }) => {
  return (
    <svg
      width="160"
      height="410"
      viewBox="0 0 160 410"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={opacity}
    >
      <path
        opacity="0.6"
        d="M78.1168 79.2895L77.797 79.2003C73.6928 78.0544 69.7356 81.4323 70.223 85.6655L71.255 94.6295C71.599 97.618 74.1031 99.8898 77.1109 99.9423L82.9583 100.044C86.0261 100.098 88.6399 97.8284 89.0174 94.7834L90.1607 85.5611C90.6686 81.464 86.9831 78.09 82.9467 78.9566L80.9897 79.3768C80.0391 79.5809 79.0532 79.551 78.1168 79.2895Z"
        fill="#D71A21"
      />
      <path
        d="M83.9965 83.4491L83.0921 85.007C82.8249 85.4672 82.9129 86.049 83.3015 86.391V86.391C83.6951 86.7374 83.7798 87.3289 83.5018 87.7906L83.068 88.5113C82.7477 89.0434 82.9143 89.7303 83.4366 90.0313V90.0313C83.9424 90.3227 84.1174 90.979 83.8302 91.5076L83.225 92.6215C82.9803 93.072 83.1028 93.6305 83.5093 93.9178V93.9178C83.9396 94.222 84.0479 94.8254 83.7538 95.2812L82.8614 96.6644"
        stroke="#D71A21"
        strokeLinecap="round"
      />
      <path
        d="M80.3303 83.3847L79.4259 84.9425C79.1586 85.4028 79.2467 85.9846 79.6353 86.3266V86.3266C80.0289 86.673 80.1135 87.2644 79.8355 87.7262L79.4017 88.4469C79.0814 88.979 79.248 89.6659 79.7703 89.9668V89.9668C80.2761 90.2582 80.4511 90.9145 80.1639 91.4431L79.5588 92.5571C79.314 93.0075 79.4365 93.566 79.843 93.8534V93.8534C80.2734 94.1576 80.3816 94.761 80.0876 95.2167L79.1952 96.6"
        stroke="#D71A21"
        strokeLinecap="round"
      />
      <path
        d="M76.664 83.3212L75.7596 84.879C75.4924 85.3393 75.5804 85.9211 75.969 86.2631V86.2631C76.3626 86.6095 76.4472 87.201 76.1693 87.6627L75.7355 88.3834C75.4151 88.9155 75.5817 89.6024 76.1041 89.9033V89.9033C76.6099 90.1948 76.7848 90.851 76.4977 91.3796L75.8925 92.4936C75.6478 92.9441 75.7703 93.5025 76.1768 93.7899V93.7899C76.6071 94.0941 76.7153 94.6975 76.4213 95.1532L75.5289 96.5365"
        stroke="#D71A21"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IllnessThroatInfection;
