import React, { FC } from "react";
import clsx from "clsx";

import { ReactComponent as Close } from "images/close.svg";
import { ReactComponent as Attention } from "images/attention.svg";

import styles from "./attention-popup.module.scss";

type Props = {
  subtitle: string;
  description: string;
  onClose: () => void;
};

export const AttentionPopup: FC<Props> = ({
  onClose,
  subtitle,
  description,
}) => {
  return (
    <div className={styles.popupBox}>
      <div className={styles.box}>
        <div>
          <div className={styles.header}>
            <Close className={styles.closeButton} onClick={() => onClose()} />
          </div>
          <div className={styles.container}>
            <Attention />
            <h3 className={styles.title}>Attention!</h3>
            <div className={styles.subtitle}>{subtitle}</div>
            <span className={styles.description}>{description}</span>
            <button
              type="button"
              className={clsx(styles.footerAddButton, styles.activeAddButton)}
              onClick={onClose}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
