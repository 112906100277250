export const COLOR_LIGHTER_BLUE = "#BBC0CF";
export const COLOR_LIGHT_BLUE = "#B6C2E3";
export const COLOR_BLUE = "#55A1FA";
export const COLOR_BRIGHT_BLUE = "#4696ec";
export const COLOR_SALMON = "#FF616A";
export const COLOR_LIGHT_RED = "#EC4646";
export const COLOR_ORCHID = "#DA70D6";
export const COLOR_LIGHT_GREEN = "#8ECFA5";
export const COLOR_GREEN = "#12BE50";
export const COLOR_TRANSPARENT = "#191E27";
export const COLOR_DARK_GREEN = "#008B32";
export const COLOR_WHITE = "#f9fafd";
export const COLOR_LIGHT_GRAY = "#C5CDDE";
export const COLOR_GRAY = "#8C93A6";
export const COLOR_DARK_GRAY = "#585b63";
export const COLOR_TOOLTIP = "#1E2431";
export const COLOR_LIGHT_YELLOW = "#FFE37F";

export const COLOR_CHART_LINE_ONE = "#0759F8";
export const COLOR_CHART_LINE_TWO = "#8AD629";
export const COLOR_CHART_LINE_THREE = "#29C1D6";
export const COLOR_CHART_LINE_FOUR = "#B429D6";
export const COLOR_CHART_LINE_FIVE = "#F80707";
export const COLOR_CHART_LINE_SIX = "#D69129";
export const COLOR_CHART_LINE_SEVEN = "#CF5A4A";
export const COLOR_CHART_LINE_EIGHT = "#104C89";
export const COLOR_CHART_LINE_NINE = "#3773C0";

export const GRADIENT_AREA_BLUE: [string, string] = [
  "rgba(70, 150, 236, 0.2)",
  "rgba(37, 37, 41, 0)",
];

export const GRADIENT_AREA_RED: [string, string] = [
  "rgba(236, 70, 70, 0.2)",
  "rgba(37, 37, 41, 0)",
];

export const GRADIENT_AREA_WHITE: [string, string] = [
  "rgba(212, 211, 211, 0.2)",
  "rgba(37, 37, 41, 0)",
];

export const GRADIENT_AREA_GREEN: [string, string] = [
  "rgba(18, 190, 80, 0.2)",
  "rgba(18, 190, 80, 0)",
];

export const COLOR_AXIS = "#f9fafd";

export const COLOR_BAR_DEFAULT = "#FFFFFF";

export const COLOR_RADAR_SECONDARY = "#FFFFFF";

export const TEAM_PERFORMANCE_BAR_COLOR = "#12BE50";

export const TEAM_PERFORMANCE_BAR_COLOR_ONE = "#54CDDE";
export const TEAM_PERFORMANCE_BAR_COLOR_TWO = "#A1DE54";
export const DEFAULT_FONT_COLOR = "#FFFFFF";

export const RadarLabelColors = {
  mmp10Seconds: "#a1de54",
  mmp30Seconds: "#a1de54",
  mmp1Minute: "#54cdde",
  mmp5Minutes: "#54cdde",
  mmp10Minutes: "#54cdde",
  mmp20Minutes: "#54cdde",
  mmp30Minutes: "#dea754",
  mmp60Minutes: "#dea754",
};

export enum RadarLegendColors {
  primaryColor = "#A1DE54",
  secondaryColor = "#F93939",
}

export const BORDER_SCATTER_DOT = "#FFFFFF";

export const PERFORMANCE_MGMT_COLORS: {
  [key: string]: string | [string, string];
} = {
  TSB: "#FFE37F",
  TSB_GRADIENT: ["rgba(255, 227, 127, 0.2)", "rgba(255, 227, 127, 0)"],
  ATL: "#C354DE",
  ATL_GRADIENT: ["rgba(195, 84, 222, 0.2)", "rgba(195, 84, 222, 0)"],
  CTL: "#397AF9",
  CTL_GRADIENT: ["rgba(57, 122, 249, 0.2)", "rgba(57, 122, 249, 0)"],
};

export const PERFORMANCE_SEPERATOR = "#f9fafd";

export const MARKLINE_COLOR = "#008b32";

export const REPORT_TABLE_COLOR = {
  headCells: {
    style: {
      "&:first-child": {
        boxShadow: "5px 0 5px -3px rgba(0,0,0,0.5)",
        position: "sticky" as const,
        left: "0px",
        backgroundColor: "inherit",
        zIndex: 5,
      },
    },
  },
  rows: {
    style: {
      backgroundColor: "#1B212C",
      border: "none",
      color: "#FFFFFF",
      fontWeight: 500,
      fontSize: "12px",
      "&:not(:last-of-type)": {
        border: "none",
      },
      "&:nth-child(2n)": {
        backgroundColor: "#212835",
      },
    },
  },
  headRow: {
    style: {
      border: "none",
      backgroundColor: "#1B212C",
      color: "#FFFFFF",
      fontWeight: 500,
      fontSize: "10px",
    },
  },
  noData: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#FFFFFF",
      backgroundColor: "#1B212C",
    },
  },
  progress: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#FFFFFF",
      backgroundColor: "#1B212C",
    },
  },
};

export const SORT_STYLE = {
  marginLeft: "10px",
  fill: "white",
  width: "12px",
};

export const DETRAINING_LINE_COLOR = "#A1DE54";
export const DETRAINING_LABEL_COLOR = "#54CDDE";
export const DETRAINING_AREA_COLOR = "84, 205, 222";

export const COMPETITION_READY_LINE_COLOR = "#DEA754";
export const COMPETITION_READY_LABEL_COLOR = "#A1DE54";
export const COMPETITION_READY_AREA_COLOR = "161, 222, 84";

export const TRANSITIONAL_LINE_COLOR = "#FFCE22";
export const TRANSITIONAL_LABEL_COLOR = "#FFCE22";
export const TRANSITIONAL_AREA_COLOR = "255, 206, 34";

export const PRODUCTIVE_TRAINING_LINE_COLOR = "#F93939";
export const PRODUCTIVE_TRAINING_LABEL_COLOR = "#DEA754";
export const PRODUCTIVE_TRAINING_AREA_COLOR = "222, 167, 84";

export const OVERREACHING_LINE_COLOR = "#F93939";
export const OVERREACHING_LABEL_COLOR = "#F93939";
export const OVERREACHING_AREA_COLOR = "208, 39, 37";

export const COMPLETED_DURATION_BAR_ONE = "#A1DE54";
export const COMPLETED_DURATION_BAR_TWO = "#008B32";
export const DISTANCE_BAR_ONE = "#54CDDE";
export const DISTANCE_BAR_TWO = "#397AF9";
export const ELEVATION_GAIN_BAR_ONE = "#C354DE";
export const ELEVATION_GAIN_BAR_TWO = "#DEA754";
export const TSS_BAR_ONE = "#8CA8F3";
export const TSS_BAR_TWO = "#DEA754";
export const CTL_BAR_ONE = "#A1DE54";
export const CTL_BAR_TWO = "#008B32";
export const TSB_BAR_ONE = "#54CDDE";
export const TSB_BAR_TWO = "#397AF9";
export const MCP_BAR_ONE = "#A1DE54";
export const MCP_BAR_TWO = "#008B32";

export const ATHLETE_VARIANCE_RED = "#f93939";
export const ATHLETE_VARIANCE_GREEN = "#a1de54";
export const ATHLETE_VARIANCE_ORANGE = "#dea754";

export const REPORT_HEALTH_MEN_TABLE_COLOR = {
  headCells: {
    style: {
      "&:first-child": {
        // boxShadow: "5px 0 5px -3px rgba(0,0,0,0.5)",
        // position: "sticky" as const,
        // left: "0px",
        // backgroundColor: "inherit",
        // zIndex: 5,
      },
    },
  },
  rows: {
    style: {
      backgroundColor: "#1B212C",
      border: "none",
      color: "#FFFFFF",
      fontWeight: 500,
      fontSize: "12px",
      "&:not(:last-of-type)": {
        border: "none",
      },
      "&:nth-child(2n)": {
        backgroundColor: "#212835",
      },
    },
  },
  headRow: {
    style: {
      border: "none",
      backgroundColor: "#1B212C",
      color: "#FFFFFF",
      fontWeight: 500,
      fontSize: "12px",
    },
  },
  noData: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#FFFFFF",
      backgroundColor: "#1B212C",
    },
  },
  progress: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#FFFFFF",
      backgroundColor: "#1B212C",
    },
  },
};

export const BODY_CONTAINER_FILL_COLOR_MEN = "#212835";
export const BODY_CONTAINER_STROKE_COLOR_MEN = "#FFFFFF";

export const CHAT_LOADER_COLOR = "#dadde3";
export const HRV_RHR_LIGHT_GREEN = "#90EE90";
export const HRV_RHR_DARK_GREEN = "#008000";
export const HRV_RHR_LIGHT_YELLOW = "#FFFFE0";
export const HRV_RHR_DARK_ORANGE = "#FF8C00";
export const HRV_RHR_YELLOW = "#FFD700";
export const HRV_RHR_ORANGE = "#FFA500";
export const HRV_RHR_RED = "#FF0000";
export const MCP_LIGHT_GREEN = "#A4D7A7";
export const MCP_MEDIUM_GREEN = "#4CAF50";
export const MCP_DARK_GREEN = "#2E7D32";
export const MCP_LIGHT_ORANGE = "#FFCC80";
export const MCP_MEDIUM_ORANGE = "#FF9800";
export const MCP_DARK_RED = "#B71C1C";
