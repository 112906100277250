import type { MouseEvent, ReactNode } from "react";
import type { IToastProps, IToastVariant } from "./types.tsx";
import { AlertTriangle, CheckCircle, Info, X, XCircle } from "lucide-react";
import { IconButton } from "../Button/IconButton";
import { clsx } from "clsx";
import { Typography } from "../Typography";

export const ToastItem = ({
  variant = "info",
  title,
  message,
  onDismiss,
}: IToastProps) => {
  const iconMap: Record<IToastVariant, ReactNode> = {
    info: <Info size={24} />,
    success: <CheckCircle size={24} />,
    warning: <AlertTriangle size={24} />,
    error: <XCircle size={24} />,
  };

  const rootClass: string = clsx("analog-toast", `analog-toast--${variant}`);

  const handleDismiss = (e: MouseEvent) => {
    e.stopPropagation();
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <div className={rootClass}>
      <div className="analog-toast__icon">{iconMap[variant]}</div>
      <div className="analog-toast__details">
        {title && (
          <Typography
            as="div"
            className="analog-toast__details-title"
            variant="body"
            weight="bold"
            text={title}
          />
        )}
        {message && (
          <Typography
            as="div"
            className="analog-toast__details-message"
            variant="subtitle"
            weight="regular"
            text={message}
          />
        )}
      </div>
      <div className="analog-toast__actions">
        <IconButton
          size="s"
          variant="quietNeutral"
          onClickCapture={handleDismiss}
          content={<X />}
        />
      </div>
    </div>
  );
};
