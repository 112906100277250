import "./index.css";
import type { ITooltipProps } from "./types.tsx";
import { Dropdown } from "../Dropdown";
import { clsx } from "clsx";

export const Tooltip = (props: ITooltipProps) => {
  const rootClass = clsx("analog-tooltip", props.className);
  const delay = props.delay || {
    open: 200,
    close: 0,
  };

  return (
    <Dropdown
      {...props}
      className={rootClass}
      interactions={["hover", "focus"]}
      delay={delay}
      contentClassName="analog-tooltip__content"
    />
  );
};
