export const msalConfig = {
  auth: {
    clientId: "7c983056-f902-4be5-81d7-32c8de649b7b",
    authority: "https://cyclingdev.ciamlogin.com/",
    redirectUri: "https://cyclinguae-dev.analog.io",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};
