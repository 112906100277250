import "./index.css";
import { IEmptyStateProps } from "./types.tsx";
import { clsx } from "clsx";
import { Button } from "../Button/Button";
import { Typography } from "../Typography";

export const EmptyState = ({
  icon,
  title,
  text,
  buttonProps,
  className,
}: IEmptyStateProps) => {
  const rootClass: string = clsx("analog-emptyState", className);

  return (
    <div className={rootClass}>
      <div className="analog-emptyState__inner">
        {!!icon && (
          <div className="analog-emptyState__illustration" role="presentation">
            {icon}
          </div>
        )}
        {!!title && (
          <Typography
            className="analog-emptyState__heading"
            as="h3"
            variant="h3"
            text={title}
          />
        )}
        {!!text && (
          <Typography
            className="analog-emptyState__text"
            as="p"
            variant="headline"
            text={text}
          />
        )}
        {!!buttonProps && (
          <div className="analog-emptyState__actions">
            <Button {...buttonProps} />
          </div>
        )}
      </div>
    </div>
  );
};
