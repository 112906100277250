import s from "./index.module.css";
import {
  COLOR_WHITE,
  MCP_DARK_GREEN,
  MCP_DARK_RED,
  MCP_LIGHT_GREEN,
  MCP_LIGHT_ORANGE,
  MCP_MEDIUM_GREEN,
  MCP_MEDIUM_ORANGE,
} from "../../../../../../components/charts/colors";
import { useContext } from "react";
import { AthleteCardContext } from "../../../../context";
import { Skeleton } from "../../../../../../storybook";

export const MCP = () => {
  const { athlete, athleteLoading } = useContext(AthleteCardContext);

  const getMcpVariance = (value: number | undefined) => {
    if (!value) {
      return null;
    }

    let color = COLOR_WHITE;

    if (value === 0) {
      color = COLOR_WHITE;
    } else if (value > 0 && value <= 10) {
      color = MCP_LIGHT_GREEN; // #A4D7A7
    } else if (value >= 11 && value <= 20) {
      color = MCP_MEDIUM_GREEN; // #4CAF50
    } else if (value >= 21) {
      color = MCP_DARK_GREEN; // #2E7D32
    } else if (value < 0 && value >= -10) {
      color = MCP_LIGHT_ORANGE; // #FFCC80
    } else if (value <= -11 && value >= -20) {
      color = MCP_MEDIUM_ORANGE; // #FF9800
    } else if (value <= -21) {
      color = MCP_DARK_RED; // #B71C1C
    }

    const sign = value > 0 ? "+" : "";
    const signValue = `${sign}${value.toFixed(0)}`;
    return (
      <div
        className={`${s.mcpValueWPercent} analog-typography--subtitle`}
        style={{ color }}
      >
        ({signValue})
      </div>
    );
  };

  const getMcpVariancePercentage = (value: number | undefined) => {
    if (!value) {
      return null;
    }
    let color = COLOR_WHITE;

    if (value === 0) {
      color = COLOR_WHITE;
    } else if (value > 0 && value <= 5) {
      color = MCP_LIGHT_GREEN; // #A4D7A7
    } else if (value >= 6 && value <= 10) {
      color = MCP_MEDIUM_GREEN; // #4CAF50
    } else if (value >= 11) {
      color = MCP_DARK_GREEN; // #2E7D32
    } else if (value < 0 && value >= -5) {
      color = MCP_LIGHT_ORANGE; // #FFCC80
    } else if (value <= -6 && value >= -10) {
      color = MCP_MEDIUM_ORANGE; // #FF9800
    } else if (value <= -11) {
      color = MCP_DARK_RED; // #B71C1C
    }

    const sign = value > 0 ? "+" : "";
    const signValue = `${sign}${value.toFixed(0)}%`;
    return (
      <div
        className={`${s.mcpValueWKGPercent} analog-typography--subtitle`}
        style={{ color }}
      >
        ({signValue})
      </div>
    );
  };

  if (athleteLoading) {
    return <Skeleton width="140px" height="48px" />;
  }

  return (
    <div className={s.cardItemVertical}>
      <div className={s.mcpLabel}>mCP</div>
      <div className={s.mcpValue}>
        <div className={s.statValue}>
          {getMcpVariancePercentage(athlete?.mCPRelativeVariancePercentage)}
          <div className={`${s.mcpValueWKGValue} analog-typography--headline`}>
            {athlete?.mCPRelative?.toFixed(2) || "--"}
          </div>
          <div className={`${s.tokenPlaceholder} analog-typography--subtitle`}>
            W/kg
          </div>
        </div>

        <div className={s.statValue}>
          {getMcpVariance(athlete?.mCPVariance)}
          <div className={`${s.mcpValueWValue} analog-typography--headline`}>
            {athlete?.mcp?.toFixed(0) || "--"}
          </div>
          <div className={`${s.tokenPlaceholder} analog-typography--subtitle`}>
            W
          </div>
        </div>
      </div>
    </div>
  );
};
