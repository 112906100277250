import "./index.css";
import { CyclingLogoUAE } from "../../components/CyclingLogoUAE";
import { ReactNode, useEffect } from "react";
import { RoleType } from "../../enums.ts";
import { useSelector } from "../../hooks/app-hooks.ts";
import { getAccount, getAuthState } from "../../store/slices/auth.ts";
import { useHistory } from "react-router-dom";
import { CyclingLogoADQ } from "../../components/CyclingLogoADQ";
import { useIsAuthenticated } from "@azure/msal-react";

type IProps = {
  children: ReactNode;
};

export const Auth = ({ children }: IProps) => {
  const videoSource = "banner.mp4";
  const flavorType = process.env.REACT_APP_FLAVOR;

  const history = useHistory();
  const role = useSelector(getAccount);

  const authenticatedCredentials = useSelector(getAuthState);
  const authenticatedMSFT = useIsAuthenticated();
  const authenticated = authenticatedMSFT || authenticatedCredentials;

  useEffect(() => {
    const pathname = history.location.pathname;
    const isAuthPage = pathname === "/sign-in";

    if (authenticated && isAuthPage) {
      let rememberURL = localStorage.getItem("rememberURL");

      if (rememberURL === "undefined") {
        rememberURL = null;
        localStorage.removeItem("rememberURL");
      }

      if (rememberURL) {
        history.push(rememberURL);
        localStorage.removeItem("rememberURL");
      } else {
        history.push(role === RoleType.coach ? "/team-dashboard" : "/wellness");
      }
    }
    if (!role) history.push("/sign-in");
  }, [history, authenticated, role]);

  return (
    <div className="auth__underlay">
      <video
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
        className="auth__video"
      >
        <source src={videoSource} type="video/mp4" />
      </video>
      <div className="auth">
        <header className="auth__header">
          {flavorType === "uaeMen" ? <CyclingLogoUAE /> : <CyclingLogoADQ />}
        </header>
        <div className="auth__main">{children}</div>
      </div>
    </div>
  );
};
