import React, { FC, useMemo } from "react";

import { useBoundedCharts } from "hooks/use-bounded-charts";

import { Loading } from "components/loading/loading";

import { getChartMeta } from "pages/wellness/body-composition/body-composion.settings";

import { ChartSize } from "components/charts/constants";
import {
  COLOR_CHART_LINE_FOUR,
  COLOR_CHART_LINE_FIVE,
  COLOR_CHART_LINE_SIX,
} from "components/charts/colors";

import styles from "./body.composition.module.scss";
import { ScatterChartWithHeader } from "components/charts/scatter-chart/scatter-chart";
import { DateSelector } from "components/date-selector/date-selector";

export const BodyComposition: FC = () => {
  const [athletePoints] = useBoundedCharts(getChartMeta, "bodyComposition");

  const wellnessChartYAxis = [
    { name: "Weight", gridIndex: 0, yAxisIndex: 0 },
    { name: "Fat %", gridIndex: 0, yAxisIndex: 1 },
    { name: "Skin Fold sum", gridIndex: 1, yAxisIndex: 0 },
  ];
  const wellnessChartXAxis = [{ gridIndex: 0 }, { gridIndex: 1 }];

  const categoriesSeries = useMemo(
    () => [
      {
        name: `Weight`,
        suffix: "kg",
        color: COLOR_CHART_LINE_FIVE,
        chartType: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        values:
          athletePoints?.values?.bodyMass?.map((e) => {
            return {
              value: e.value,
              itemStyle: { decimalPoint: 1 },
            };
          }) || [],
      },
      {
        name: `Fat %`,
        suffix: "%",
        color: COLOR_CHART_LINE_FOUR,
        chartType: "line",
        xAxisIndex: 0,
        yAxisIndex: 1,
        values:
          athletePoints?.values?.bodyFatPercent?.map((e) => {
            return {
              value: e.value,
              itemStyle: { decimalPoint: 1 },
            };
          }) || [],
      },
      {
        name: `Skin Fold sum`,
        suffix: "mm",
        color: COLOR_CHART_LINE_SIX,
        chartType: "line",
        xAxisIndex: 1,
        yAxisIndex: 2,
        values:
          athletePoints?.values?.skinFold?.map((e) => {
            return {
              value: e.value,
              itemStyle: { decimalPoint: 1 },
            };
          }) || [],
      },
    ],
    [athletePoints],
  );

  if (!athletePoints?.loaded) {
    return (
      <div>
        <div className={styles.content}>
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div data-testid="body-composition">
      <div className={styles.content}>
        <ScatterChartWithHeader
          name="Body Composition"
          source="Coach, Athlete, TP, AI"
          type={ChartSize.Big}
          yAxis={wellnessChartYAxis}
          xAxis={wellnessChartXAxis}
          showLegendInSourceBar={true}
          showGrid={false}
          headerComponent={
            <div className={styles.headerContainer}>
              <div className={styles.bigTitle}>
                <span className={styles.bold}>{"Body Composition"}</span>
              </div>
              <div className={styles.selectors}>
                <DateSelector header={true} />
              </div>
            </div>
          }
          series={categoriesSeries}
          labels={athletePoints.labels}
        />
      </div>
    </div>
  );
};
