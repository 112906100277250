import { useState } from "react";
import { Input } from "./";
import { IInputPasswordProps, IInputSize } from "./types.tsx";
import { IconButton } from "../Button/IconButton";
import { Eye, EyeOff } from "lucide-react";
import { IButtonSize } from "../Button/types.ts";

export const InputPassword = (props: IInputPasswordProps) => {
  const size: IInputSize = props.size || "m";
  const [visible, setVisible] = useState<boolean>(false);

  const onChangeType = () => {
    setVisible((visible: boolean) => !visible);
  };

  const buttonSize: Record<IInputSize, IButtonSize> = {
    s: "xs",
    m: "s",
    l: "m",
  };

  const title: string = visible
    ? props.translations?.show || "Show"
    : props.translations?.hide || "Hide";

  return (
    <Input
      {...props}
      placeholder={props.placeholder || "Password"}
      type={visible ? "text" : "password"}
      endAdornment={
        <IconButton
          size={buttonSize[size]}
          variant="quietNeutral"
          onClick={onChangeType}
          title={title}
          content={visible ? <EyeOff /> : <Eye />}
        />
      }
    />
  );
};
