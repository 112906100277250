import { forwardRef } from "react";
import "./index.css";
import { ISelectProps, ISelectSize, ISelectVariant } from "./types.tsx";
import { useViewModel } from "./useViewModel";
import { SelectContent } from "./SelectContent";
import { SelectToggle } from "./SelectToggle";
import { clsx } from "clsx";
import { SelectContext } from "./context.tsx";
import { Dropdown } from "../Dropdown";

export const Select = forwardRef<HTMLDivElement, ISelectProps>((props, ref) => {
  const context = useViewModel(props);
  const { dropdownProps, onRenderAnchor, toggleOpen } = context;
  const isOpen = !!dropdownProps?.isOpen;

  const size: ISelectSize = props.size || "m";
  const variant: ISelectVariant = props.variant || "dark";
  const rootClass: string = clsx(
    "analog-select",
    `analog-select--${size}`,
    `analog-select--${variant}`,
    props.className,
  );

  const anchor = onRenderAnchor ? (
    onRenderAnchor(isOpen)
  ) : (
    <SelectToggle ref={ref} />
  );

  return (
    <SelectContext.Provider value={context}>
      <div className={rootClass}>
        <Dropdown
          {...dropdownProps}
          setIsOpen={toggleOpen}
          anchor={anchor}
          content={<SelectContent />}
          freezeWidth
          interactions={["click", "dismiss", "listNavigation"]}
        />
      </div>
    </SelectContext.Provider>
  );
});

Select.displayName = "Select";
