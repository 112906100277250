import "./index.css";
import { IMessageProps } from "./types.ts";
import { clsx } from "clsx";
import { Typography } from "../Typography";

export const Message = ({
  text,
  variant = "neutral",
  className,
}: IMessageProps) => {
  const messageClass: string = clsx(
    "analog-message",
    `analog-message--${variant}`,
    className,
  );

  return (
    <Typography as="p" className={messageClass} text={text} role="alert" />
  );
};
