const IllnessSinusInfection = ({ opacity }: { opacity: number }) => {
  return (
    <svg
      width="160"
      height="410"
      viewBox="0 0 160 410"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={opacity}
    >
      <circle cx="80" cy="51" r="8" fill="#ADFF2F" fillOpacity="0.6" />
      <circle cx="80" cy="51" r="7" fill="#ADFF2F" fillOpacity="0.6" />
    </svg>
  );
};

export default IllnessSinusInfection;
