import React, { FC, useCallback, useState } from "react";

import { getCoachProfile } from "store/slices/shared";
import { useSelector } from "hooks/app-hooks";
import clsx from "clsx";

import styles from "./coach-info.module.scss";

import { ReactComponent as SignOutIcon } from "images/sign-out.svg";
import { ReactComponent as SettingsIcon } from "images/settings.svg";

import { DropdownMenuItemComponent } from "components/dropdown-profile-btns/dropdown-profile";
import { useHistory } from "react-router-dom";
import { setAccount, setAuthState } from "store/slices/auth";
import { RoleType } from "enums";
import { trackUserInteractionEvent } from "appInsights/logInsights";
import { useAppDispatch } from "../../store";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

type Props = {
  className?: string;
};

export const CoachInfo: FC<Props> = ({ className }) => {
  const coachProfile = useSelector(getCoachProfile);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const { instance } = useMsal();
  const authenticatedMs = useIsAuthenticated();

  const onLogout = useCallback(() => {
    dispatch(setAuthState(false));
    trackUserInteractionEvent("Logout", { role: RoleType.coach });
    dispatch(setAccount(RoleType.cyclist));
    history.push("/sign-in");
  }, [dispatch, history]);

  const handleSingOut = useCallback(async () => {
    if (authenticatedMs) {
      instance.logoutRedirect().then(onLogout);
    } else {
      onLogout();
    }
  }, [authenticatedMs, instance, onLogout]);

  const handleSettings = async () => {
    history.push("/settings");
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  return (
    <div className={clsx(styles.wrapper, className)} onClick={toggleDropdown}>
      <div className={styles.info}>
        <div className={styles.name}>
          {coachProfile?.firstName}{" "}
          <span className={styles.strong}>{coachProfile?.lastName}</span>
        </div>
        <div className={styles.role}>
          <span>{coachProfile?.role} </span>
        </div>
      </div>
      <div className={styles.avatar}>
        <img
          className={styles.image}
          src={coachProfile?.picture}
          alt="User avatar"
        />
      </div>
      {isDropdownVisible && (
        <div className={styles.dropdown}>
          <ul className={styles.dropdownMenu}>
            <li>
              <DropdownMenuItemComponent
                icon={<SettingsIcon />}
                text="Settings"
                className={styles.icon}
                handleClick={handleSettings}
              />
              <DropdownMenuItemComponent
                icon={<SignOutIcon />}
                text="Logout"
                className={styles.icon}
                handleClick={handleSingOut}
              />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
