import { useCallback, useEffect, useMemo, useState } from "react";
import { ISelectOption, OptionType } from "./types.tsx";
import debounce from "lodash/debounce";

export const useSelectSearch = (options: ISelectOption[]) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<ISelectOption[]>([]);

  const handleFilterOptions = useCallback((): void => {
    const filteredOptions: ISelectOption[] = [];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const notOption = !(
        option.type === OptionType.Option || option.type === undefined
      );
      const hasSearchWord = option.text
        .toLowerCase()
        .includes(searchValue.toLowerCase());

      if (notOption || hasSearchWord) {
        filteredOptions.push(option);
      }
    }

    const result: ISelectOption[] = [];

    for (let i = 0; i < filteredOptions.length; i++) {
      const isCurrentHeading = filteredOptions[i].type === OptionType.Heading;
      const isNextHeading =
        filteredOptions[i + 1] &&
        filteredOptions[i + 1].type === OptionType.Heading;

      if (isCurrentHeading && isNextHeading) {
        continue;
      }

      result.push(filteredOptions[i]);
    }

    if (
      result[result.length - 1] &&
      result[result.length - 1].type === OptionType.Heading
    ) {
      result.pop();
    }

    setFilteredOptions(result);
  }, [searchValue, options]);

  const debouncedFilter = useMemo(() => {
    return debounce(handleFilterOptions);
  }, [handleFilterOptions]);

  useEffect(debouncedFilter, [debouncedFilter]);

  const handleSearch = useCallback((value: string): void => {
    setSearchValue(value);
  }, []);

  const handleClearSearch = useCallback((): void => {
    setSearchValue("");
  }, []);

  return {
    searchValue,
    filteredOptions,
    handleSearch,
    handleClearSearch,
  };
};
