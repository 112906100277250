import { APIResponseV2, WeeklyReport } from "types";
import { axiosInstance } from "axios-instance/axios-instance";
import { formatDateAPI } from "utils";

export const getWeeklyReport = async (
  year: number,
  week: number,
): Promise<WeeklyReport[]> => {
  const resp = await axiosInstance.get(`/report/weekly/${year}/${week}`);
  return (resp.data || []).map((report: WeeklyReport) => report);
};

export const getGroupReport = async (
  startDate: Date,
  endDate: Date,
  groupId: number,
): Promise<WeeklyReport[]> => {
  const resp = await axiosInstance.get(
    `/report/group/${formatDateAPI(startDate)}/${formatDateAPI(
      endDate,
    )}?groupId=${groupId}`,
  );
  return (resp.data || []).map((report: WeeklyReport) => report);
};
