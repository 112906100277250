const NauseaFront = ({ opacity }: { opacity: number }) => {
  return (
    <svg
      width="160"
      height="410"
      viewBox="0 0 160 410"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={opacity}
    >
      <g filter="url(#filter0_b_1766_75878)">
        <path
          opacity="0.9"
          d="M92.8336 20.4471C103.31 23.2543 109.169 32.5801 108.505 43.1842C108.078 50.0116 105.532 56.3421 102.847 62.5334C95.1618 80.2334 77.9361 77.3831 77.9361 77.3831L77.8948 77.372C77.8948 77.372 61.5848 71.2365 63.7836 52.0665C64.5525 45.3661 65.5142 38.6067 68.5582 32.4805C73.2836 22.969 83.0214 17.8179 93.4982 20.6252"
          fill="url(#paint0_linear_1766_75878)"
          fillOpacity="0.3"
        />
      </g>
      <g filter="url(#filter1_b_1766_75878)">
        <path
          opacity="0.9"
          d="M57.4992 24.8677C66.8925 19.4445 77.6296 21.896 84.6579 29.8641C89.1837 34.9937 91.86 41.2702 94.339 47.5469C101.421 65.4968 87.2249 75.6617 87.2249 75.6617L87.1879 75.6831C87.1879 75.6831 71.3164 82.8775 59.316 67.7675C55.1218 62.4859 51.0222 57.0263 48.8428 50.542C45.4585 40.4749 48.7018 29.9469 58.0951 24.5237"
          fill="url(#paint1_linear_1766_75878)"
          fillOpacity="0.3"
        />
      </g>
      <path
        opacity="0.9"
        d="M79.3188 19C90.1652 19 98.2381 26.4916 100.341 36.9064C101.695 43.6117 100.875 50.3853 99.8834 57.0607C97.0415 76.1466 79.665 77.8518 79.665 77.8518H79.6223C79.6223 77.8518 62.28 76.1466 59.4423 57.0607C58.4508 50.3896 57.6303 43.6117 58.985 36.9064C61.0877 26.4959 69.1605 19 80.0069 19"
        fill="#397AF9"
        fillOpacity="0.86"
      />
      <path
        d="M48.2692 49C35.7691 50.5 25.369 59.5 79.769 59.5C134.169 59.5 122.269 50.5 108.269 49"
        stroke="#81ACFF"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M48.273 35.5C30.273 36.5 16.0288 45 79.5951 45C143.161 45 127.773 35.5 108.273 35.5"
        stroke="#81ACFF"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M62.8484 66C55.9645 68 49.8916 72 80.671 72C111.45 72 104.246 68 96.7963 66"
        stroke="#81ACFF"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_b_1766_75878"
          x="59.5797"
          y="15.8613"
          width="52.976"
          height="65.6914"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1766_75878"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1766_75878"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_1766_75878"
          x="43.5387"
          y="17.9619"
          width="56.7798"
          height="63.4326"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1766_75878"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1766_75878"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1766_75878"
          x1="78.273"
          y1="79"
          x2="126.773"
          y2="21.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.279356" stopColor="#397AF9" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1766_75878"
          x1="54.7729"
          y1="26.001"
          x2="76.2729"
          y2="73.501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="0.563123" stopColor="#397AF9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NauseaFront;
