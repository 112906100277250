import "./index.css";
import type { IPopoverProps } from "./types.tsx";
import { Dropdown } from "../Dropdown";
import { clsx } from "clsx";

export const Popover = (props: IPopoverProps) => {
  const rootClass = clsx("analog-popover", props.className);

  return (
    <Dropdown
      {...props}
      className={rootClass}
      interactions={["click", "dismiss"]}
      contentClassName="analog-popover__content"
    />
  );
};
