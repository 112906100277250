import React, { FC, useEffect, useState } from "react";
import Select from "react-windowed-select";
import clsx from "clsx";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { getCurrentWeekRange, setCurrentWeekRange } from "store/slices/shared";
import { ReactComponent as CalendarPrimary } from "images/calendar-primary.svg";
import styles from "./report-week-selector.module.scss";
import "./report-week-selector.overwrite.scss";
import { WeekReportRange } from "enums";
import { generateWeeklyOptions } from "pages/weekly-report/utils";
import { useAppDispatch } from "../../store";

interface SelectorOption {
  label: string;
  value: WeekReportRange;
}

interface Props {
  small?: boolean;
  header?: boolean;
}

export const ReportWeekSelector: FC<Props> = ({ small, header }) => {
  const dispatch = useAppDispatch();
  const currentWeekRange = useSelector(getCurrentWeekRange);
  const [options, setOptions] = useState<SelectorOption[]>([]);

  useEffect(() => {
    const weeklyOptions = generateWeeklyOptions().map((range) => ({
      label: range.label,
      value: range,
    }));
    setOptions(weeklyOptions);
  }, []);

  const value: SelectorOption | undefined = options.find(
    (option) =>
      new Date(option.value.startDate).getTime() ===
      new Date(currentWeekRange.startDate).getTime(),
  );

  const handleChange = (newOption: any) => {
    dispatch(setCurrentWeekRange(newOption.value));
  };

  return (
    <div className={styles.wrapper}>
      {!small && <CalendarPrimary className={styles.calendar} />}
      {/* @ts-ignore */}
      <Select
        isClearable={false}
        isSearchable={false}
        options={options}
        value={value}
        defaultValue={value}
        onChange={handleChange}
        className={clsx(
          "report-week-selector__select",
          small && "report-week-selector__select--small",
          header && "report-week-selector__select--header",
        )}
        classNamePrefix="react-select"
      />
    </div>
  );
};
