import "./index.css";
import { Button, Checkbox, Divider, FormElement } from "../../storybook";
import { InputControl } from "../../components/controls/InputControl";
import { useViewModel } from "./useViewModel.tsx";
import { FormProvider } from "react-hook-form";
import { InputPasswordControl } from "../../components/controls/InputPasswordControl";
import { Link } from "react-router-dom";
import { Auth } from "../Auth";
import { useTranslation } from "react-i18next";
import { ReactComponent as MicrosoftLogoOfficial } from "../../assets/icons/ms-logo-official.svg";
import { ROUTES } from "../../router/routes.ts";
import { isLocalDevelopment } from "../../utils/isLocalDevelopment.ts";

export const SignIn = () => {
  const { t } = useTranslation();
  const {
    form,
    onSubmit,
    remember,
    handleRememberChange,
    errorMessage,
    loading,
    errors,
    signInWithMicrosoft,
    stayIdle,
  } = useViewModel();

  return (
    <FormProvider {...form}>
      <Auth>
        <form className="sign-in__form" onSubmit={onSubmit}>
          <h3 className="sign-in__title analog-typography--h3 bold">
            {t("label.sign_in")}
          </h3>

          <div className="sign-in__form-fields">
            <FormElement
              label={t("label.username")}
              message={errors.username?.message}
            >
              <InputControl
                size="l"
                name="username"
                placeholder={t("placeholder.username")}
                invalid={!!errors.username || !!errorMessage}
              />
            </FormElement>
            <FormElement
              label={t("label.password")}
              message={errors.password?.message || errorMessage}
            >
              <InputPasswordControl
                size="l"
                name="password"
                placeholder={t("placeholder.password")}
                invalid={!!errors.password || !!errorMessage}
              />
            </FormElement>
          </div>

          <div className="sign-in__extras">
            <Checkbox
              label={t("label.remember_me")}
              checked={remember}
              onChange={handleRememberChange}
            />

            <Link to={`/${ROUTES.FORGOT_PASSWORD}`}>
              <Button
                size="xs"
                variant="quietNeutral"
                content={t("label.forgot_password")}
              />
            </Link>
          </div>

          <div className="sing-in__footer">
            <Button
              type="submit"
              size="l"
              fullWidth
              content={t("action.login")}
              loading={loading}
            />
            {isLocalDevelopment() && (
              <>
                <Divider />
                <Button
                  className="ms-sign-in__button"
                  type="button"
                  size="l"
                  fullWidth
                  content={t("action.login_with_microsoft")}
                  variant="quietNeutral"
                  onClick={signInWithMicrosoft}
                  startIcon={<MicrosoftLogoOfficial />}
                  loading={stayIdle}
                  spinnerPosition="start"
                />
              </>
            )}
          </div>
        </form>
      </Auth>
    </FormProvider>
  );
};
