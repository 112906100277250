import { Layout } from "components/layout/layout";
import styles from "./medical-report-table.module.scss";
import { AthleteSearch } from "pages/athlete-selection/athlete-search/athlete-search";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { PrimaryButton } from "components/primary-button/primary-button";
import { ReactComponent as FilterIcon } from "images/filter_icon.svg";
import { ReactComponent as SortIcon } from "images/sort_icon.svg";
import { ReportWeekSelector } from "components/report-week-selector/report-week-selector";
import DataTable from "react-data-table-component";
import { Athlete, WeeklyReport } from "types";
import {
  getAllCommentEnabledReports,
  getAthleteList,
  getFilteredWeeklyReportData,
  getFullWeeklyReportData,
  getSeletedIds,
  getToggleCommentPopup,
  setFilteredWeeklyReportData,
  setSelectedIds,
  setToggleCommentPopup,
  setWizardReportData,
} from "store/slices/shared";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { AthletePopup } from "components/athlete-popup/athlete-popup";
import { SORT_STYLE } from "components/charts/colors";
import { REPORT_HEALTH_MEN_TABLE_COLOR } from "clients/uaeMen/colors";
import { REPORT_HEALTH_WOMEN_TABLE_COLOR } from "clients/adqWomen/colors";
import { CoachFeedback } from "pages/weekly-report/components/coach-feedback/coach-feedback";
import NotifyReporting from "pages/weekly-report/components/notify-reporting/notify-reporting";
import { FeedbackWizard } from "pages/weekly-report/components/feedback-wizard/feedback-wizard";
import { useHistory } from "react-router-dom";
import { getEnumValueForIssue } from "utils";
import {
  HealthEnums,
  fetchAllTeamMedicalReports,
  setSelectedMedicalReport,
  setSelectedMedicalReportAthleteAction,
  setSelectedMedicalReportIdAction,
} from "store/slices/health";
import { FlavorType } from "enums";
import { ReactComponent as Comment } from "images/comment-box.svg";
import { ReactComponent as Attachment } from "images/attachment.svg";
import AthleteDetails from "components/athlete-details/athlete-details";
import { useAppDispatch } from "../../store";

type Props = {
  loading: boolean;
};

interface Report {
  id: number;
  recordType: string;
  issue: string;
  moderate: string;
  date: string;
  recovered: string;
  reported: string;
  details: any;
  athlete: any;
  severity?: string;
  createdBy?: string;
  estimatedRecovery?: number;
  notesCount?: number;
  attachmentsCount?: number;
  onsetDate?: number;
  athleteId?: number;
}

export const MedicalReportTable: FC<Props> = ({ loading }) => {
  const dispatch = useAppDispatch();
  const [isTablet, setIsTablet] = useState(false);
  const data = useSelector(getFullWeeklyReportData);
  const commentPopup = useSelector(getToggleCommentPopup);
  const filteredDataState = useSelector(getFilteredWeeklyReportData);
  const [filteredData, setFilteredData] = useState<Report[]>([]);
  const selectedIds = useSelector(getSeletedIds);
  const [input, setInput] = useState<string>("");
  const [togglePopup, setTogglePopup] = useState(false);
  const allCommentEnabledReports = useSelector(getAllCommentEnabledReports);
  const athleteList = useSelector(getAthleteList);

  const history = useHistory();
  const medicalReportsData = useSelector(
    (state: any) => state.health.allTeamMedicalReports,
  );
  const healthEnums = useSelector((state: any) => state.health.healthEnums);
  const flavorType = process.env.REACT_APP_FLAVOR;

  const handleRowClicked = (row: Report) => {
    // dispatch(setSelectedMedicalReport(row.id));
    dispatch(setSelectedMedicalReportIdAction(row.id));
    dispatch(setSelectedMedicalReportAthleteAction(row.athlete));
    history.push("/medical-report-details");
  };

  const formattedData = useMemo(
    () =>
      medicalReportsData.map((item: Report) => ({
        id: item.id,
        athlete: athleteList?.find((x) => x?.id === item?.athleteId),
        recordType: item.recordType,
        issue:
          getEnumValueForIssue(healthEnums, item?.recordType, item?.issue) ||
          "Not Specified",
        moderate: item.severity || "Not Specified",
        date:
          item?.onsetDate &&
          new Date(item.onsetDate * 1000).toLocaleDateString(),
        recovered:
          item?.estimatedRecovery &&
          new Date(item.estimatedRecovery * 1000).toLocaleDateString(),
        reported: item.createdBy,
        details: (
          <>
            <Comment
              className={styles.commentIcon}
              aria-label="Comments"
              title="Comments"
            />
            <span className={styles.count}>{item.notesCount}</span>
            <Attachment
              className={styles.attachmentIcon}
              aria-label="Attachments"
              title="Attachments"
            />
            <span className={styles.count}>{item.attachmentsCount}</span>
          </>
        ),
      })),
    [medicalReportsData, athleteList, healthEnums],
  );

  useEffect(() => {
    if (formattedData?.length) {
      setFilteredData(formattedData);
    }
  }, [formattedData]);

  useEffect(() => {
    const checkIfTablet = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1439) {
        setIsTablet(true);
      } else {
        setIsTablet(false);
      }
    };

    checkIfTablet();

    window.addEventListener("resize", checkIfTablet);
    return () => window.removeEventListener("resize", checkIfTablet);
  }, []);

  useEffect(() => {
    dispatch(fetchAllTeamMedicalReports);
  }, [dispatch]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    const filtered = formattedData?.filter((report: Report) => {
      const athlete = report?.athlete as Athlete;
      const athleteName =
        `${athlete?.firstName} ${athlete?.lastName}`?.toLowerCase();
      return athleteName.includes(event.target.value.toLowerCase());
    });
    setFilteredData(filtered);
    // if (selectedIds) {
    //     dispatch(setSelectedIds(null));
    //     dispatch(setFilteredWeeklyReportData(data));
    // }
  };

  const handleFilterPopup = useCallback(() => {
    if (selectedIds && selectedIds.length > 0) {
      const filtered = formattedData?.filter((report: Report) => {
        return selectedIds.includes(report.athlete?.id);
      });
      // dispatch(setFilteredWeeklyReportData(filtered));
      setFilteredData(filtered);
    } else {
      // dispatch(setFilteredWeeklyReportData(data));
      setFilteredData(formattedData);
    }
    setTogglePopup(false);
  }, [formattedData, selectedIds]);

  const handleClearAll = () => {
    // dispatch(setFilteredWeeklyReportData(data));
    setFilteredData(formattedData);
    // dispatch(setSelectedIds(null));
    setTogglePopup(false);
  };

  const handleStartReporting = () => {
    dispatch(setWizardReportData(allCommentEnabledReports));
    dispatch(
      setToggleCommentPopup({
        toggle: false,
        wizardToggle: true,
        athleteReport: undefined,
      }),
    );
  };

  const topComponent = (
    <>
      <div className={styles.topWrapper}>
        <div className={styles.searchFilter}>
          <AthleteSearch
            input={input}
            handleChange={handleChange}
            className={styles.search}
          />
        </div>
        <div className={styles.exportDropdown}>
          <PrimaryButton
            className={styles.button}
            onClick={() => setTogglePopup(true)}
            title={isTablet ? undefined : "Filter"}
            icon={<FilterIcon />}
          />
        </div>
      </div>
      {allCommentEnabledReports?.length > 0 && (
        <NotifyReporting handleStartReporting={handleStartReporting} />
      )}
    </>
  );

  const getEnumValue = (parentKey: string, childkey: string) => {
    return healthEnums[parentKey]?.[childkey];
  };

  const getSeverityRowColor = (row: { moderate: string }) => {
    if (row?.moderate === "MODERATE") return "orange";
    else if (row?.moderate === "SEVERE") return "red";
    else if (row?.moderate === "REHABILITATION") return "#397AF9";
    else if (row?.moderate === "MILD") return "green";
    else return flavorType === FlavorType.adqWomen ? "black" : "white";
  };

  const columns = [
    {
      name: "Athelete",
      selector: (row: Report) => row.athlete,
      sortable: true,
      id: "athlete",
      cell: (row: Report) => (
        <AthleteDetails athlete={row.athlete as Athlete} />
      ),
      width: "300px",
      //   style: {
      //     position: "sticky",
      //     left: "0px",
      //     backgroundColor: "inherit",
      //     zIndex: 5,
      //     boxShadow: "5px 0 5px -3px rgba(0,0,0,0.5)",
      //   },
    },

    {
      name: "Issue",
      selector: (row: Report) => row.issue,
      sortable: true,
      cell: (row: Report) => (
        <div onClick={() => handleRowClicked(row)}>
          <div style={{ fontWeight: "bold" }}>{row?.issue}</div>
          <div style={{ color: "#6c757d", fontSize: "12px", marginTop: "3px" }}>
            {/* {getEnumValue(row?.recordType === "PHYSIO" ? "type" : "severity", row?.moderate) || "Not specific"} •{" "} */}
            {/* todo: row.recordType to be added */}
            {getEnumValue("reportType", row?.recordType) || "Not specific"}
          </div>
        </div>
      ),
      grow: 1,
    },
    {
      name: "Severity / Stage",
      selector: (row: Report) => row.moderate,
      sortable: true,
      cell: (row: Report) => (
        <span
          onClick={() => handleRowClicked(row)}
          style={{
            color: getSeverityRowColor(row),
          }}
        >
          {healthEnums?.severity[row?.moderate] ||
            healthEnums?.rehabilitationStage[row?.moderate] ||
            "Not Specified"}
        </span>
      ),
      // center: true,
    },
    {
      name: "Onset date",
      selector: (row: Report) => row.date,
      sortable: true,
    },
    {
      name: "Recovered By",
      selector: (row: Report) => row.recovered,
      sortable: true,
    },
    {
      name: "Reported By",
      selector: (row: Report) => row.reported,
      sortable: true,
    },
    {
      name: "Details",
      selector: (row: Report) => row.details,
      sortable: true,
    },
  ];

  return (
    <>
      <Layout
        topMenuItems={[]}
        top={topComponent}
        contentClassName={styles.contentWrapper}
        showAdditonalComps={false}
        customHeader={<div className={styles.header}>Medical Report</div>}
        content={
          <DataTable
            columns={columns}
            data={filteredData}
            className={styles.tableData}
            sortIcon={
              <SortIcon className={styles.filterIcon} style={SORT_STYLE} />
            }
            customStyles={
              flavorType === FlavorType.adqWomen
                ? REPORT_HEALTH_WOMEN_TABLE_COLOR
                : REPORT_HEALTH_MEN_TABLE_COLOR
            }
            onRowClicked={handleRowClicked}
            onRowDoubleClicked={handleRowClicked}
          />
        }
        showCalendarComp={false}
        dataTestId="table-view-layout"
      />
      {togglePopup && (
        <AthletePopup
          handleClearAll={handleClearAll}
          handleSubmit={handleFilterPopup}
          athleteList={formattedData?.map(
            (report: Report) => report?.athlete as Athlete,
          )}
          onClose={() => {
            setTogglePopup(false);
          }}
        />
      )}
    </>
  );
};
