import "./index.css";
import { Button, EmptyState, FormElement } from "../../storybook";
import { InputControl } from "../../components/controls/InputControl";
import { useViewModel } from "./useViewModel.tsx";
import { FormProvider } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "../Auth";
import { MailOpen } from "lucide-react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../router/routes.ts";

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { form, onSubmit, loading, errors, sentToEmail } = useViewModel();

  return (
    <FormProvider {...form}>
      <Auth>
        {sentToEmail ? (
          <EmptyState
            icon={<MailOpen size={48} />}
            title={t("banner.send_otp_title")}
            text={t("banner.send_otp_message", { sentToEmail })}
            buttonProps={{
              content: t("action.back_to_login"),
              onClick: () => {
                history.push(`/${ROUTES.SIGN_IN}`);
              },
            }}
          />
        ) : (
          <form className="forgot-password__form" onSubmit={onSubmit}>
            <h3 className="forgot-password__title analog-typography--h3 bold">
              {t("label.forgot_password")}
            </h3>

            <div className="forgot-password__form-fields">
              <FormElement
                label={t("label.email")}
                message={errors.email?.message}
              >
                <InputControl
                  size="l"
                  name="email"
                  placeholder={t("placeholder.email")}
                  invalid={!!errors.email}
                />
              </FormElement>
            </div>

            <div className="forgot-password__footer">
              <Button
                type="submit"
                size="l"
                fullWidth
                content={t("action.reset_password")}
                loading={loading}
              />
              <Link to={`/${ROUTES.SIGN_IN}`}>
                <Button
                  size="l"
                  fullWidth
                  variant="quietNeutral"
                  content={t("action.back_to_login")}
                />
              </Link>
            </div>
          </form>
        )}
      </Auth>
    </FormProvider>
  );
};
