import React, { FC, useState, useEffect, useRef } from "react";
import clsx from "clsx";

import styles from "./add-comments.module.scss";
import { useDispatch } from "hooks/app-hooks";
import { setToggleCommentPopup } from "store/slices/shared";
import { WeeklyReport } from "types";
import Tippy from "@tippyjs/react";
import { useAppDispatch } from "../../../../store";

type Props = {
  className?: string;
  athleteReport: WeeklyReport;
  isDisabled?: boolean;
  isEdit?: boolean;
};

export const AddComments: FC<Props> = ({
  className,
  athleteReport,
  isDisabled,
  isEdit,
}) => {
  const dispatch = useAppDispatch();

  const handleCommentPopup = () => {
    dispatch(
      setToggleCommentPopup({
        toggle: true,
        athleteReport,
      }),
    );
  };

  return !isEdit ? (
    <>
      <div className={styles.wrapper}>
        {isDisabled ? (
          <div className={styles.noCommentButton}>-</div>
        ) : (
          <button
            type="button"
            className={
              isDisabled ? styles.disabled : clsx(styles.button, className)
            }
            onClick={isDisabled === false ? handleCommentPopup : undefined}
            data-testid="add-comments-button"
          >
            Add Comment
          </button>
        )}
      </div>
    </>
  ) : (
    <span className={styles.editCommentWrapper}>
      {!isDisabled && (
        <span
          className={isDisabled ? styles.editDisabled : styles.editComment}
          onClick={isDisabled === false ? handleCommentPopup : undefined}
        >
          {athleteReport.comments && athleteReport.comments.length >= 75 && (
            <Tippy
              content={
                <div className={styles.commentTooltipContent}>
                  <span>{athleteReport.comments}</span>
                </div>
              }
            >
              <span>Read / </span>
            </Tippy>
          )}
          Edit
        </span>
      )}
    </span>
  );
};
