import React, { FC, useEffect } from "react";

import { DateRange } from "enums";
import { Athlete, ChartMeta } from "types";
import {
  calculateDateRange,
  formatDateAPI,
  sortDataBasedOnAthleteOrder,
} from "utils";
import { useDispatch, useSelector } from "hooks/app-hooks";
import {
  getAthleteList,
  getCurrentDateRange,
  getSelectedGroup,
} from "store/slices/shared";
import { fetchChartData } from "store/slices/chart";
import { APICriticalPowerWChartResponse } from "api/chart";

import { CriticalPowerW } from "components/critical-power-w/critical-power-w";
import { useAppDispatch } from "../../../store";

const getChartMeta = (
  name: string,
  dateRange: DateRange,
  athleteList: Athlete[],
  athleteIds: number[],
): ChartMeta<APICriticalPowerWChartResponse[]> => {
  const { startDate, endDate } = calculateDateRange(dateRange);
  return {
    api: {
      url: "/performance/criticalPower/:fromDate/:toDate?athleteIds=:athleteIds",
      params: {
        ":fromDate": formatDateAPI(startDate),
        ":toDate": formatDateAPI(endDate),
        ":athleteIds": athleteIds.join("&athleteIds="),
      },
    },
    charts: [
      {
        name: `${name}CriticalPowerW`,
        getLabels: (data: APICriticalPowerWChartResponse[]) => {
          const sortedData =
            sortDataBasedOnAthleteOrder(data, athleteList) || data;
          return sortedData?.map((item) => {
            const athleteName = athleteList.find(
              (athlete) => athlete.id === item?.athleteId,
            );
            return `${athleteName?.firstName ?? ""} ${
              athleteName?.lastName ?? ""
            }`.trim();
          });
        },
        getValues: (data: APICriticalPowerWChartResponse[]) => {
          const sortedData =
            sortDataBasedOnAthleteOrder(data, athleteList) || data;
          return {
            cp: sortedData?.map((item) => ({ value: item?.cp })),
            cpRelative: sortedData?.map((item) => ({
              value: item?.cp / item?.athleteWeight,
            })),
            w: sortedData?.map((item) => ({ value: item?.w })),
          };
        },
      },
    ],
  };
};

export const TeamCriticalPowerW: FC = () => {
  const dispatch = useAppDispatch();
  const currentDateRange = useSelector(getCurrentDateRange);
  const athleteList = useSelector(getAthleteList);
  const currentGroup = useSelector(getSelectedGroup);

  useEffect(() => {
    if (currentGroup && currentGroup.athleteIds.length > 0) {
      dispatch(
        fetchChartData<APICriticalPowerWChartResponse[]>(
          getChartMeta(
            "athlete",
            currentDateRange,
            athleteList,
            currentGroup?.athleteIds,
          ),
        ),
      );
    }
  }, [dispatch, currentDateRange, currentGroup, athleteList]);

  const criticalPowerW = useSelector(
    (state) => state.chart.athleteCriticalPowerW,
  );

  return <CriticalPowerW data={criticalPowerW} />;
};
