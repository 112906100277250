import { IDatepickerContext, IDatepickerProps } from "./types.tsx";
import { MouseEvent, useCallback, useMemo, useRef } from "react";
import { convertDateToString } from "./utils/convertDateToString";
import { useControlledValue } from "../../hooks/useControlledValue.ts";

export const useViewModel = (props: IDatepickerProps): IDatepickerContext => {
  const {
    dropdownProps,
    onClear,
    value,
    range,
    setDisplayValue,
  }: IDatepickerProps = props;

  const { value: isOpen, onChange: setIsOpen } = useControlledValue<boolean>({
    controlledValue: dropdownProps?.isOpen,
    controlledOnChange: dropdownProps?.setIsOpen,
  });

  const innerRange = useRef<Date[]>([]);
  const currentInnerRangePointer = useRef<number>(0);

  const displayValue: string = useMemo((): string => {
    if (!value) {
      return "";
    }

    if (range) {
      if (value.length === 0) {
        return "";
      }

      if (setDisplayValue) {
        return setDisplayValue(value[0], value[1]);
      }

      const fromDate = convertDateToString(value[0]);
      const toDate = convertDateToString(value[1]);
      return `${fromDate} - ${toDate}`;
    }

    return setDisplayValue
      ? setDisplayValue(value)
      : convertDateToString(value);
  }, [value, range, setDisplayValue]);

  // useEffect(() => {
  //   if (props.disabled) {
  //     setIsOpen(false);
  //   }
  // }, [props.disabled]);

  const onOpenChange = useCallback(
    (open: boolean): void => {
      if (props.disabled) {
        return;
      }
      if (!open) {
        // innerRange.current = [];
        // currentInnerRangePointer.current = 0;
      }
      setIsOpen(open);
    },
    [props.disabled, setIsOpen],
  );

  const handleClear = onClear
    ? (e?: MouseEvent): void => {
        e?.stopPropagation();
        onClear();
      }
    : undefined;

  return {
    ...props,
    dropdownProps: {
      ...props.dropdownProps,
      isOpen: isOpen || false,
    },
    handleClear,
    onOpenChange,
    displayValue,
    innerRange,
    currentInnerRangePointer,
  };
};
