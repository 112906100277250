import "./index.css";
import { ISpinnerProps } from "./types";
import { clsx } from "clsx";

export const Spinner = ({
  size = "s",
  variant = "primary",
  role = "status",
  className,
  translations,
}: ISpinnerProps) => {
  const ariaLabel = translations?.ariaLabel || "Loading";
  const rootClass: string = clsx(
    "analog-spinner",
    `analog-spinner--${size}`,
    `analog-spinner--${variant}`,
    className,
  );
  const innerClass: string = clsx("analog-spinner__inner");

  return (
    <div className={rootClass} aria-label={ariaLabel} role={role}>
      <div className={innerClass} />
    </div>
  );
};
