import { ICalendarContext, ICalendarProps, TimePeriod } from "./types";
import { useCallback, useEffect, useRef, useState } from "react";

export const useViewModel = (props: ICalendarProps): ICalendarContext => {
  const {
    value,
    range,
    isOpen,
    onOpenChange,
    innerRange: outsideInnerRange,
    currentInnerRangePointer: outsideCurrentInnerRangePointer,
  } = props;

  const localIsOpen = isOpen || true;
  const localOnOpenChange = onOpenChange || ((_: boolean) => {});
  const localInnerRange = useRef<Date[]>([]);
  const localCurrentInnerRangePointer = useRef<number>(0);

  const innerRange = outsideInnerRange || localInnerRange;
  const currentInnerRangePointer =
    outsideCurrentInnerRangePointer || localCurrentInnerRangePointer;

  const innerValueInit = useRef<boolean>(false);

  const [dateForPeriod, setDateForPeriod] = useState<Date>(new Date());
  const [timePeriod, setTimePeriod] = useState<TimePeriod>(TimePeriod.Days);

  const onDateForPeriodChange = useCallback((value: Date) => {
    const next = new Date(value);
    next.setDate(1);
    setDateForPeriod(next);
  }, []);

  const handleTimePeriodChange = useCallback((timePeriod: TimePeriod): void => {
    setTimePeriod(timePeriod);
  }, []);

  useEffect(() => {
    if (!innerValueInit.current) {
      const next: Date | undefined = range ? value[0] : value;
      onDateForPeriodChange(next || new Date());
      innerValueInit.current = true;
      handleTimePeriodChange(TimePeriod.Days);
    }
  }, [onDateForPeriodChange, value, range, handleTimePeriodChange]);

  useEffect(() => {
    return () => {
      innerValueInit.current = false;
    };
  }, []);

  return {
    ...props,
    timePeriod,
    handleTimePeriodChange,
    dateForPeriod,
    onDateForPeriodChange,
    innerValueInit,
    isOpen: localIsOpen,
    onOpenChange: localOnOpenChange,
    innerRange,
    currentInnerRangePointer,
  };
};
