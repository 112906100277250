import { FC, useState } from "react";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";
import { ReactComponent as RadioButton } from "images/radio-button.svg";
import { ReactComponent as SelectedRadioButton } from "images/selected-radio-button.svg";

import styles from "./fairly-light-popup.module.scss";
import { PrimaryButton } from "components/primary-button/primary-button";
import { postData } from "../input-popup/api";
import { formatDatePower } from "utils";
import { useSelector } from "hooks/app-hooks";
import { getCurrentAthlete } from "store/slices/shared";
import { getIsCyclist } from "store/slices/auth";
import { CountdownTimer } from "../success-countdown";

type Props = {
  onClose: () => void;
};

type EffortLevel =
  | "No effort at all (Rest)"
  | "Very very light"
  | "Very light"
  | "Fairly light"
  | "Somewhat hard"
  | "Hard"
  | "Very hard"
  | "Very very hard"
  | "Maximal effort";

export const FairlyLightPopup: FC<Props> = ({ onClose }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const currentAthlete = useSelector(getCurrentAthlete);
  const isCyclist: boolean = useSelector(getIsCyclist);
  const [success, setSuccess] = useState<boolean>(false);
  const [selectedEffort, setSelectedEffort] = useState<EffortLevel | null>(
    null,
  );
  const effortLevels: EffortLevel[] = [
    "No effort at all (Rest)",
    "Very very light",
    "Very light",
    "Fairly light",
    "Somewhat hard",
    "Hard",
    "Very hard",
    "Very very hard",
    "Maximal effort",
  ];

  const handleRadioChange = (effort: EffortLevel) => {
    if (errorMessage) {
      setErrorMessage(null);
    }
    setSelectedEffort(effort);
  };
  const handleSubmitClick = async (): Promise<void> => {
    if (selectedEffort == null) {
      setErrorMessage("Please select an effort level");
      return;
    }
    const response = await postData(
      currentAthlete?.id || 0,
      { rpe: selectedEffort },
      isCyclist ? 0 : 1,
      formatDatePower(new Date()),
      "rpe",
    );

    if (response === null) {
      setErrorMessage("Failed to submit data");
    } else {
      setSuccess(true);
    }
  };

  return (
    <div className={clsx(styles.popupBox, "comparison-dropdown")}>
      <div
        className={clsx(styles.inputBox, styles.box)}
        data-testid="add-comparison-list"
      >
        <div>
          <div className={styles.header}>
            <h3 className={styles.title}>RPE - Rate of Perceived Exertion</h3>
            <Close
              className={styles.closeButton}
              onClick={onClose}
              aria-label="Close"
              title="Close"
            />
          </div>
          {success ? (
            <div className={styles.countDown}>
              <CountdownTimer duration={3} onComplete={onClose} />
            </div>
          ) : (
            <div className={styles.container}>
              <div>
                {effortLevels.map((effort, index) => (
                  <label key={index}>
                    <input
                      type="radio"
                      name="effort"
                      value={effort}
                      checked={selectedEffort === effort}
                      onChange={() => handleRadioChange(effort)}
                    />
                    <div className={styles.options}>
                      {selectedEffort === effort ? (
                        <SelectedRadioButton />
                      ) : (
                        <RadioButton />
                      )}
                      {effort}
                    </div>
                  </label>
                ))}
              </div>
            </div>
          )}
          {errorMessage && (
            <div className={styles.errorMessage}>{errorMessage}</div>
          )}
          <div className={styles.footer}>
            <PrimaryButton
              disabled={selectedEffort === null || success}
              className={styles.addButton}
              onClick={handleSubmitClick}
              title={"Submit"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
