import { FC, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import styles from "./chat-suggestion.module.scss";
import { PrimaryButton } from "components/primary-button/primary-button";

type Props = {
  onSuggestionClick: (message: string) => void;
  isLoading: boolean;
  suggestion: string;
};

export const ChatSuggestion: FC<Props> = ({
  onSuggestionClick,
  suggestion,
  isLoading,
}) => {
  const handleSuggestionClick = () => {
    onSuggestionClick(suggestion);
  };
  return (
    <button
      className={clsx(styles.chatSuggestion, isLoading && styles.disabled)}
      onClick={handleSuggestionClick}
      disabled={isLoading}
    >
      {suggestion}
    </button>
  );
};
