import s from "./index.module.css";
import { useContext } from "react";
import { AthleteCardContext } from "../../../../context";
import { Skeleton } from "../../../../../../storybook";

export const TSB = () => {
  const { athlete, athleteLoading } = useContext(AthleteCardContext);

  const tsbStyle =
    athlete?.tsb === undefined
      ? undefined
      : {
          color:
            athlete?.tsb > 25
              ? "#DEA754"
              : athlete?.tsb <= -30
                ? "#F93939"
                : undefined,
        };

  if (athleteLoading) {
    return <Skeleton width="60px" height="24px" />;
  }

  return (
    <div className={s.statValue}>
      <div className={`${s.tokenSecondary} analog-typography--headline`}>
        TSB
      </div>
      <div
        className={`${s.tokenPrimary} analog-typography--headline bold`}
        style={tsbStyle}
      >
        {athlete?.tsb?.toFixed(0) || "--"}
      </div>
    </div>
  );
};
