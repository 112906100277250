import { axiosInstance } from "axios-instance/axios-instance";
import {
  APIResponse,
  HeartRateMeasurement,
  WellnessLastUpdates,
  Menstrual,
} from "types";
import { formatDateAPI } from "utils";
import { DateRange } from "enums";
import {
  adaptLastUpdateScore,
  adaptWellnessLastUpdateScore,
  APILastUpdateScore,
  APIWellnessLastUpdateScore,
} from "./utils";

interface APIWellnessLastUpdate {
  hrv: APILastUpdateScore;
  rhr: APILastUpdateScore;
  bodyMass: APILastUpdateScore;
  skinFold: APILastUpdateScore;
  bodyFatPercent: APILastUpdateScore;
  bmi: APILastUpdateScore;
  fatMass: APILastUpdateScore;
  height: APILastUpdateScore;
  wellness: APIWellnessLastUpdateScore;
}

interface APIHeartRateMeasurement {
  athleteIds: number[];
  startDate: string;
  endDate: string;
  average: {
    hrv: number;
    rhr: number;
  };
  source: {
    hrv: string;
    rhr: string;
  };
  points: [
    {
      startDate: Date;
      endDate: Date;
      data: {
        hrv: number;
        rhr: number;
      };
    },
  ];
}

const getLastUpdates = async (
  athleteId: number,
  athleteIds?: number[],
): Promise<WellnessLastUpdates | undefined> => {
  const comparisonString = athleteIds
    ? `&athleteIds=${athleteIds?.join("&athleteIds=") || ""}`
    : "";
  const { data }: APIResponse<APIWellnessLastUpdate> = await axiosInstance.get(
    `/wellness/overall/lastUpdated?athleteId=${athleteId}${comparisonString}`,
  );
  if (data) {
    return {
      athlete: {
        hrv: adaptLastUpdateScore(data.hrv),
        rhr: adaptLastUpdateScore(data.rhr),
        bodyMass: adaptLastUpdateScore(data.bodyMass),
        skinFold: adaptLastUpdateScore(data.skinFold),
        bodyFatPercent: adaptLastUpdateScore(data.bodyFatPercent),
        bmi: adaptLastUpdateScore(data.bmi),
        fatMass: adaptLastUpdateScore(data.fatMass),
        height: adaptLastUpdateScore(data.height),
        wellness: adaptWellnessLastUpdateScore(data.wellness),
      },
    };
  }
  return undefined;
};

const getHeartRateMeasurement = async (
  startDate: Date,
  endDate: Date,
  dateRange: DateRange,
  athleteId?: number,
): Promise<HeartRateMeasurement | undefined> => {
  const { data }: APIResponse<APIHeartRateMeasurement> =
    await axiosInstance.get(
      `/wellness/heartRateMeasurement/${formatDateAPI(
        startDate,
      )}/${formatDateAPI(endDate)}?unit=DAY&athleteId=${athleteId}`,
    );

  return data
    ? {
        startDate: data.startDate,
        endDate: data.endDate,
        average: data.average,
        source: data.source,
        points: data.points,
      }
    : undefined;
};

interface APIMenstrual {
  data: Menstrual[];
}

const getMenstrual = async (
  athleteId?: number,
): Promise<Menstrual[] | undefined> => {
  const { data }: APIResponse<APIMenstrual> = await axiosInstance.get(
    `/menstrual?athleteId=${athleteId}`,
  );
  return data?.data || undefined;
};

export const wellness = {
  getLastUpdates,
  getHeartRateMeasurement,
  getMenstrual,
};
