import React, { FC } from "react";
import clsx from "clsx";
import { ReactComponent as SearchIconSVG } from "images/search.svg";
import styles from "./athlete-search.module.scss";
import "./styles.scss";

type Props = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  input: string;
  className?: string;
};

export const AthleteSearch: FC<Props> = ({
  handleChange,
  input,
  className,
}) => {
  return (
    <div className={clsx(styles.wrapper, "search-input", className)}>
      <input
        type="text"
        placeholder="Search For Athlete"
        value={input}
        onChange={handleChange}
        className={clsx(styles.control, "custom-input-class")}
      />
      <SearchIconSVG className={styles.searchIcon} />
    </div>
  );
};
