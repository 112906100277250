const IllnessStomachPain = ({ opacity }: { opacity: number }) => {
  return (
    <svg
      width="160"
      height="410"
      viewBox="0 0 160 410"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={opacity}
    >
      <circle cx="80" cy="180" r="25" fill="#FF4500" fillOpacity="0.5" />
      <circle cx="80" cy="180" r="22.619" fill="#FF4500" fillOpacity="0.5" />
      <circle cx="80" cy="180" r="18.5476" stroke="#FF4500" />
      <circle cx="80" cy="180" r="12.5952" stroke="#FF4500" />
    </svg>
  );
};

export default IllnessStomachPain;
