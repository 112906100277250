import "./index.css";
import {
  Button,
  EmptyState,
  FormElement,
  Message,
  Skeleton,
  Typography,
} from "../../storybook";
import { useViewModel } from "./useViewModel.tsx";
import { FormProvider } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "../Auth";
import { CheckCircle2 } from "lucide-react";
import { InputPasswordControl } from "../../components/controls/InputPasswordControl";
import { FormElementSkeleton } from "../../storybook/stories/components/FormElement/FormElementSkeleton.tsx";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../router/routes.ts";

export const ResetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { form, onSubmit, loading, errors, validating, passwordChanged } =
    useViewModel();

  return (
    <FormProvider {...form}>
      <Auth>
        {passwordChanged ? (
          <EmptyState
            icon={<CheckCircle2 size={48} />}
            title={t("banner.reset_password_title")}
            text={t("banner.reset_password_message")}
            buttonProps={{
              content: t("action.back_to_login"),
              onClick: () => {
                history.push(`/${ROUTES.SIGN_IN}`);
              },
            }}
          />
        ) : (
          <form className="reset-password__form" onSubmit={onSubmit}>
            <h3 className="reset-password__title analog-typography--h3 bold">
              {t("label.reset_password")}
            </h3>

            <div className="reset-password__form-fields">
              {validating ? (
                <FormElementSkeleton size="l" />
              ) : (
                <FormElement
                  label={t("label.new_password")}
                  message={errors.password?.message}
                >
                  <InputPasswordControl
                    size="l"
                    name="password"
                    placeholder={t("placeholder.password")}
                    invalid={!!errors.password}
                  />
                </FormElement>
              )}

              {validating ? (
                <FormElementSkeleton size="l" />
              ) : (
                <FormElement
                  label={t("label.confirm_password")}
                  message={errors.reTypePassword?.message}
                >
                  <InputPasswordControl
                    size="l"
                    name="reTypePassword"
                    placeholder={t("placeholder.password")}
                    invalid={!!errors.reTypePassword}
                  />
                </FormElement>
              )}

              <Typography
                className="reset-password__requirement"
                variant="subtitle"
                text={t("label.password_requirement")}
              />
            </div>

            <div className="reset-password__footer">
              {validating ? (
                <Skeleton width="100%" height="48px" />
              ) : (
                <Button
                  type="submit"
                  size="l"
                  fullWidth
                  content={t("action.reset_password")}
                  loading={loading}
                />
              )}
              <Link to={`/${ROUTES.SIGN_IN}`}>
                <Button
                  size="l"
                  fullWidth
                  variant="quietNeutral"
                  content={t("action.back_to_login")}
                />
              </Link>
            </div>
          </form>
        )}
      </Auth>
    </FormProvider>
  );
};
