import s from "./index.module.css";
import { HealthMetricsPanel } from "./components/HealthMetricsPanel";
import { FeedbackForm } from "./components/FeedbackForm";
import { useCallback, useContext, useState } from "react";
import { AthleteCardContext } from "../../context";
import { EmptyState } from "./components/EmptyState";
import { Button } from "../../../../storybook";

export const Feedback = () => {
  const { athleteLoading, wellnessData } = useContext(AthleteCardContext);
  const [showForm, setShowForm] = useState<boolean>(false);

  const openForm = useCallback(() => setShowForm(true), []);
  const dismissForm = useCallback(() => setShowForm(false), []);

  return (
    <div className={s.feedback}>
      <div className={s.feedbackCard}>
        {!athleteLoading && !wellnessData?.wellness?.data ? (
          <EmptyState />
        ) : (
          <HealthMetricsPanel />
        )}
        <Button size="l" content="Enter your feedback" onClick={openForm} />
      </div>
      {showForm && <div className={`${s.overlay}`} />}

      {showForm && <FeedbackForm onDismiss={dismissForm} />}
    </div>
  );
};
