import { FC, useEffect, useMemo, useState } from "react";
import { ScatterChartWithHeader } from "components/charts/scatter-chart/scatter-chart";
import { ChartSize } from "components/charts/constants";
import styles from "./bar-scatter-chart.module.scss";
import { ChartData } from "types";
import { DateSelector } from "components/date-selector/date-selector";
import { BarScatterModel } from "api/chart";
import { useSelector } from "hooks/app-hooks";
import { getCurrentAthleteName } from "store/slices/shared";
import { DateGroupBySelector } from "components/date-group-by/date-group-by";
import { TypeSelector } from "components/type-selector/type-selector";
import clsx from "clsx";

type Props = {
  chartTitle: string;
  chartSubTitle?: string;
  data?: ChartData;
  labelArray?: string[];
  dataArray: BarScatterModel[];
  selectors?: React.ReactNode;
  chartAdditionalHeaderComp?: React.ReactNode;
  maxHeight?: number;
  rotateXAxisLabels?: boolean;
  formatLabels?: boolean;
  showXAxisLine?: boolean;
  showYAxisLine?: boolean;
  showToolTipTitle?: boolean;
  showGroupBySelector?: boolean;
  showTypeSelector?: boolean;
  addBorder: boolean;
};

export const BarScatterChart: FC<Props> = ({
  chartTitle,
  data,
  labelArray,
  dataArray,
  chartSubTitle,
  selectors,
  chartAdditionalHeaderComp,
  maxHeight,
  rotateXAxisLabels = true,
  formatLabels = true,
  showXAxisLine = true,
  showYAxisLine = false,
  showToolTipTitle = true,
  showGroupBySelector = true,
  showTypeSelector = false,
  addBorder = false,
}) => {
  const [isTablet, setIsTablet] = useState(false);
  const currentAthleteName = useSelector(getCurrentAthleteName);

  useEffect(() => {
    const checkIfTablet = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1439) {
        setIsTablet(true);
      } else {
        setIsTablet(false);
      }
    };

    checkIfTablet();

    window.addEventListener("resize", checkIfTablet);
    return () => window.removeEventListener("resize", checkIfTablet);
  }, []);

  const labels = useMemo(
    () => data?.labels || labelArray || [],
    [data, labelArray],
  );

  return (
    <div>
      <div
        className={clsx(styles.content, addBorder && styles.addBorder)}
        style={maxHeight ? { maxHeight: `${maxHeight}px` } : {}}
      >
        <ScatterChartWithHeader
          name={chartTitle}
          type={ChartSize.Big}
          barMaxWidth="20px"
          rotateXAxisLabels={rotateXAxisLabels}
          headerComponent={
            <div className={styles.headerWarpper}>
              <div className={styles.headerContainer}>
                <div className={styles.bigTitle}>
                  <span className={styles.bold}>{chartTitle}</span>
                  {chartSubTitle && (
                    <div>
                      <span className={styles.subTitle}>{chartSubTitle}</span>
                    </div>
                  )}
                </div>
                <div className={styles.selectors}>
                  {!selectors ? (
                    <>
                      {showTypeSelector && <TypeSelector />}
                      <DateSelector />
                      {showGroupBySelector && <DateGroupBySelector />}
                    </>
                  ) : (
                    selectors
                  )}
                </div>
              </div>
              {chartAdditionalHeaderComp}
            </div>
          }
          source={"TP"}
          showLegendInSourceBar={true}
          yLabelMargins={20}
          bottomMargin={10}
          labels={labels}
          series={dataArray}
          containLabels={true}
          isTablet={isTablet}
          intervals={0}
          customToolTipTitle={showToolTipTitle ? currentAthleteName : undefined}
          showGrid={false}
          yAxis={dataArray.map((item) => item)}
          showCrosshair={true}
          formatLabels={formatLabels}
          showXAxisLine={showXAxisLine}
          showYAxisLine={showYAxisLine}
          showYAxisName={false}
        />
      </div>
    </div>
  );
};
