import { trackError } from "appInsights/logInsights";
import { axiosInstance } from "axios-instance/axios-instance";
import { te } from "date-fns/locale";
import { FlavorType } from "enums";
import {
  APIResponseV2,
  Profile,
  CoachProfile,
  NewPassword,
  APIResponse,
  SetNewPassword,
} from "types";
import { getTeamName } from "utils";

export const updateProfile = async (
  param: Profile,
): Promise<Profile | null> => {
  try {
    const { data }: APIResponseV2<Profile> = await axiosInstance.post(
      `/user/update`,
      param,
    );

    return data.data;
  } catch (err) {
    trackError(err as Error, { location: "updateProfile" });
    return null;
  }
};

export const getProfile = async (): Promise<Profile | null> => {
  try {
    const { data }: APIResponseV2<Profile> =
      await axiosInstance.get(`/user/profile`);

    // @ts-ignore
    return data;
  } catch (err) {
    trackError(err as Error, { location: "getProfile" });
    return null;
  }
};

export const getCoachProfile = async (): Promise<CoachProfile | null> => {
  try {
    const { data }: APIResponseV2<CoachProfile> =
      await axiosInstance.get(`/coach/profile`);

    return data.data;
  } catch (err) {
    trackError(err as Error, { location: "getCoachProfile" });
    return null;
  }
};

interface ChangePasswordResponse {
  code?: number;
  message: string;
  data?: string;
  status?: string;
}

export const updatePassword = async (
  param: NewPassword,
): Promise<ChangePasswordResponse | null> => {
  try {
    const response: APIResponse<ChangePasswordResponse> =
      await axiosInstance.post(`/user/changePassword`, param);

    return response.data || null;
  } catch (err: APIResponse<ChangePasswordResponse> | any) {
    trackError(err as Error, { location: "updatePassword" });
    return err.data || null;
  }
};

export const sendOTP = async (
  email: string,
): Promise<ChangePasswordResponse | null> => {
  try {
    // const flavorType = process.env.REACT_APP_FLAVOR;
    // const team = flavorType === FlavorType.adqWomen ? "women" : "men";
    const team = getTeamName();
    const { data }: APIResponse<ChangePasswordResponse> =
      await axiosInstance.get(`/sendOtp?email=${email}&team=${team}`);

    return data || null;
  } catch (err: APIResponse<ChangePasswordResponse> | any) {
    trackError(err as Error, { location: "sendOtp" });
    return Promise.reject(err);
  }
};

export const verifyOTP = async (
  email: string,
  otp: string,
  team: string,
): Promise<ChangePasswordResponse | null> => {
  try {
    let teamName = team;
    if (!team) teamName = getTeamName();
    console.log("team: ", teamName);
    const { data }: APIResponse<ChangePasswordResponse> =
      await axiosInstance.get(
        `/verifyOtp?email=${email}&otp=${otp}&team=${teamName}`,
      );

    return data || null;
  } catch (err: APIResponse<ChangePasswordResponse> | any) {
    trackError(err as Error, { location: "verifyOtp" });
    return Promise.reject(err);
  }
};

export const setNewPassword = async (
  param: SetNewPassword,
): Promise<ChangePasswordResponse | null> => {
  console.log("Params in setNewPassword: ", param);
  try {
    const { data }: APIResponse<ChangePasswordResponse> =
      await axiosInstance.post(`/setNewPassword`, param);

    return data || null;
  } catch (err) {
    trackError(err as Error, { location: "setNewPassword" });
    return Promise.reject(err);
  }
};
