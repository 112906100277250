import "./index.css";
import UAECyclingLogoImage from "../../assets/illustrations/cycling-logo-uae.svg";
import { Typography } from "../../storybook";

export const CyclingLogoUAE = () => {
  return (
    <div className="cycling-logo-uae">
      <div className="cycling-logo-uae__image">
        <img src={UAECyclingLogoImage} alt="UAE Cycling Logo" />
        <div className="cycling-logo-uae__details">
          <Typography text="UAE CYCLING" variant="h4" weight="bold" />
          <Typography text="Analytics Platform" variant="subtitle" />
        </div>
      </div>
      <div className="cycling-logo-uae__underline" />
    </div>
  );
};
