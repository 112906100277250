import { FC, useState } from "react";
import clsx from "clsx";
import styles from "./chat-footer.module.scss";
import { ChatInput } from "../chat-input/chat-input";
import { useSelector } from "hooks/app-hooks";
import { getCurrentAthleteName } from "store/slices/shared";
import { ChatSuggestion } from "../chat-suggestion/chat-suggestion";
import { ReactComponent as ChatChevron } from "images/chevron-up.svg";

type Props = {
  onSuggestionClick: (suggestion: string) => void;
  onChangeMultiline: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSendClick: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  isLoading: boolean;
};

export const ChatFooter: FC<Props> = ({
  onSuggestionClick,
  isLoading,
  onSendClick,
  onChangeMultiline,
  onKeyDown,
}) => {
  const athleteName = useSelector(getCurrentAthleteName);
  const [showSuggestion, isShowSuggestion] = useState(false);
  const promptList = [
    `How has ${athleteName}'s VO2 Max changed over the past six months?`,
    `Analyze ${athleteName}'s HRV and RHR over the past 30 days in relation to their training load. Identify and discuss any significant trends or patterns, highlighting key insights without detailing daily figures.`,
    `Analyze ${athleteName}'s performance over the past month.`,
    `Identify ${athleteName}'s peak modeled Critical Power (mCP) over the past 365 days. Analyze the distribution of training intensity during the 90 days leading up to this peak, providing key insights and observations. Additionally, provide a monthly recap of training intensity distribution, including the percentage of time spent in each zone using the classic training zone model.`,
  ];

  const handleSuggestionClick = (suggestion: string) => {
    isShowSuggestion(false);
    onSuggestionClick(suggestion);
  };

  return (
    <div className={styles.chatFooter}>
      <ChatInput
        isLoading={isLoading}
        name="chatInput"
        onSend={onSendClick}
        onChangeMultiline={onChangeMultiline}
        onKeyDown={onKeyDown}
      />
      <div className={styles.promptWrapper}>
        <div
          className={styles.examplePrompt}
          onClick={() => isShowSuggestion(!showSuggestion)}
        >
          <div className={styles.promptText}>Example prompts</div>
          <ChatChevron
            className={clsx(
              styles.chevron,
              !showSuggestion && styles.chevronDown,
            )}
          />
        </div>
        {showSuggestion &&
          promptList.map((prompt, index) => (
            <ChatSuggestion
              key={index}
              isLoading={isLoading}
              onSuggestionClick={handleSuggestionClick}
              suggestion={prompt}
            />
          ))}
      </div>
    </div>
  );
};
