import { Layout } from "components/layout/layout";
import styles from "./chart-view.module.scss";
import { FC, useCallback, useEffect, useState } from "react";
import { PrimaryButton } from "components/primary-button/primary-button";
import { ReactComponent as FilterIcon } from "images/filter_icon.svg";
import { ReactComponent as InfoIcon } from "images/info_icon.svg";
import { ReportWeekSelector } from "components/report-week-selector/report-week-selector";
import { Athlete } from "types";
import {
  getFilteredWeeklyReportData,
  getFullWeeklyReportData,
  getSeletedIds,
  setFilteredWeeklyReportData,
  setSelectedIds,
} from "store/slices/shared";
import { useDispatch, useSelector } from "hooks/app-hooks";
import {
  customMarkArea,
  customMarkLines,
  hhmmssToHours,
  topMenuItems,
} from "../utils";
import { AthletePopup } from "../../../components/athlete-popup/athlete-popup";
import { ReusableChartComp } from "../../../components/charts/reusable-chart/reusable-chart";
import { ToolTipDataType } from "enums";
import Tippy from "@tippyjs/react";
import { roundFloat } from "utils";
import {
  COMPLETED_DURATION_BAR_ONE,
  COMPLETED_DURATION_BAR_TWO,
  CTL_BAR_ONE,
  CTL_BAR_TWO,
  DISTANCE_BAR_ONE,
  DISTANCE_BAR_TWO,
  ELEVATION_GAIN_BAR_ONE,
  ELEVATION_GAIN_BAR_TWO,
  MCP_BAR_ONE,
  MCP_BAR_TWO,
  TSB_BAR_ONE,
  TSB_BAR_TWO,
  TSS_BAR_ONE,
  TSS_BAR_TWO,
} from "components/charts/colors";
import { useAppDispatch } from "../../../store";

type Props = {
  loading: boolean;
};

export const ChartView: FC<Props> = ({ loading }) => {
  const dispatch = useAppDispatch();
  const data = useSelector(getFullWeeklyReportData);
  const filteredData = useSelector(getFilteredWeeklyReportData);
  const selectedIds = useSelector(getSeletedIds);
  const [togglePopup, setTogglePopup] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const checkIfTablet = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1439) {
        setIsTablet(true);
      } else {
        setIsTablet(false);
      }
    };

    checkIfTablet();

    window.addEventListener("resize", checkIfTablet);
    return () => window.removeEventListener("resize", checkIfTablet);
  }, []);

  const handleFilterPopup = useCallback(() => {
    if (selectedIds && selectedIds.length > 0) {
      const filtered = data.filter((report) =>
        selectedIds.includes(report.athleteId),
      );
      dispatch(setFilteredWeeklyReportData(filtered));
    } else {
      dispatch(setFilteredWeeklyReportData(data));
    }
    setTogglePopup(false);
  }, [data, selectedIds, dispatch]);

  const handleClearAll = () => {
    dispatch(setFilteredWeeklyReportData(data));
    dispatch(setSelectedIds(null));
    setTogglePopup(false);
  };

  const topComponent = (
    <div className={styles.topWrapper}>
      <div className={styles.searchFilter}>
        <PrimaryButton
          className={styles.button}
          onClick={() => setTogglePopup(true)}
          title="Filter"
          icon={<FilterIcon />}
        />
      </div>
      <div className={styles.exportDropdown}>
        <ReportWeekSelector />
      </div>
    </div>
  );

  return (
    <>
      <Layout
        topMenuItems={topMenuItems}
        top={topComponent}
        contentClassName={styles.contentWrapper}
        showAdditonalComps={false}
        customHeader={<div className={styles.header}>Weekly Report</div>}
        content={
          <div className={styles.chartViewWrapper}>
            <ReusableChartComp
              isCrossHair
              isTimestamp
              isTablet={isTablet}
              loading={loading}
              barColors={[
                COMPLETED_DURATION_BAR_ONE,
                COMPLETED_DURATION_BAR_TWO,
              ]}
              tooltipTitle="Duration"
              reportDataType={ToolTipDataType.TIMESTAMP}
              weeklyReport={filteredData}
              chartTitle="Completed Duration"
              chartValues={filteredData.map((item) => ({
                value: hhmmssToHours(item.durationStr),
              }))}
            />
            <ReusableChartComp
              isCrossHair
              loading={loading}
              isTablet={isTablet}
              barColors={[DISTANCE_BAR_ONE, DISTANCE_BAR_TWO]}
              tooltipTitle="Distance"
              weeklyReport={filteredData}
              chartTitle="Distance"
              hideDecimalPoints={true}
              chartValues={filteredData.map((item) => ({
                value: item.distanceInKm,
              }))}
            />
            <ReusableChartComp
              isCrossHair
              isTablet={isTablet}
              loading={loading}
              barColors={[ELEVATION_GAIN_BAR_ONE, ELEVATION_GAIN_BAR_TWO]}
              tooltipTitle="Elevation Gain"
              weeklyReport={filteredData}
              hideDecimalPoints={true}
              chartTitle="Elevation Gain"
              chartValues={filteredData.map((item) => ({
                value: item.climbInMeter,
              }))}
            />
            <ReusableChartComp
              isCrossHair
              isTablet={isTablet}
              loading={loading}
              barColors={[TSS_BAR_ONE, TSS_BAR_TWO]}
              tooltipTitle="TSS"
              weeklyReport={filteredData}
              hideDecimalPoints={true}
              chartTitle="TSS"
              chartValues={filteredData.map((item) => ({
                value: item.tss,
              }))}
            />
            <ReusableChartComp
              isCrossHair
              isTablet={isTablet}
              loading={loading}
              barColors={[CTL_BAR_ONE, CTL_BAR_TWO]}
              tooltipTitle="CTL"
              weeklyReport={filteredData}
              hideDecimalPoints={true}
              chartTitle="CTL"
              chartValues={filteredData.map((item) => ({
                value: item.ctl,
              }))}
            />
            <ReusableChartComp
              isCrossHair
              isTablet={isTablet}
              loading={loading}
              barColors={[TSB_BAR_ONE, TSB_BAR_TWO]}
              tooltipTitle="TSB"
              markLineSpace={isTablet ? 5 : 3}
              weeklyReport={filteredData}
              hideDecimalPoints={true}
              customMarkLines={customMarkLines}
              customMarkArea={customMarkArea}
              chartType="scatter"
              chartTitle="TSB"
              chartValues={filteredData.map((item) => ({
                value: roundFloat(item.tsb, 0) || 0,
                itemStyle: { decimalPoint: 0 },
              }))}
            />
            <ReusableChartComp
              isCrossHair
              isTablet={isTablet}
              loading={loading}
              capitalizeFirstLetter={false}
              barColors={[MCP_BAR_ONE, MCP_BAR_TWO]}
              tooltipTitle="mCP (W/kg)"
              weeklyReport={filteredData}
              chartTitle="mCP (W/kg)"
              chartValues={filteredData.map((item) => ({
                value: item.mCPRelative,
              }))}
              additionalComponent={
                <div>
                  <Tippy
                    placement="bottom"
                    content={
                      <div className={styles.tooltipContent}>
                        <span>Last</span> <span>90 Days</span>
                      </div>
                    }
                  >
                    <InfoIcon className={styles.infoIcon} />
                  </Tippy>
                </div>
              }
            />
          </div>
        }
        showCalendarComp={false}
        dataTestId="chart-view-layout"
      />
      {togglePopup && (
        <AthletePopup
          handleClearAll={handleClearAll}
          handleSubmit={handleFilterPopup}
          athleteList={data.map((report) => report.athlete as Athlete)}
          onClose={() => {
            setTogglePopup(false);
          }}
        />
      )}
    </>
  );
};
