import { getCycleCalendar } from "api/menses/get-cycle-calendar";
import { getCycleTrends } from "api/menses/get-cycle-trends";
import { getDonutChart } from "api/menses/get-donut-chart";
import { PickApiMethodResponseType } from "../api/types";

export enum MenstrualWidgetView {
  Display,
  Edit,
  Add,
  Duration,
}

export type CycleTrendsData = PickApiMethodResponseType<
  typeof getCycleTrends
>["data"];

export type CycleChartData = PickApiMethodResponseType<typeof getDonutChart>;

export type CycleCalendarData = PickApiMethodResponseType<
  typeof getCycleCalendar
>["mensesCalendarDates"];

export type CycleTrendsType = "THREE_CYCLES" | "SIX_CYCLES" | "TWELVE_CYCLES";

export type MenstrualCyclePhase =
  | "menses"
  | "follicular"
  | "ovulation"
  | "luteal";

export type MenstrualCycleDonutChartData = {
  id: MenstrualCyclePhase;
  commands: string;
};

export enum MenstrualCycleLegendColor {
  Menses = "var(--primary-red-500)",
  Follicular = "var(--secondary-pink-500)",
  Ovulation = "var(--secondary-blue-light-500)",
  Luteal = "var(--primary-purple-500)",
}
