import { FC } from "react";
import clsx from "clsx";
import styles from "./source.module.scss";
import { ReactComponent as InputIcon } from "images/input.svg";
import { COLOR_BLUE, COLOR_GREEN } from "components/charts/colors";
import { SourceType } from "enums";

type Props = {
  source?: string;
  className?: string;
};

export const Source: FC<Props> = ({ source, className }) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      {source ? <InputIcon className={styles.input} /> : null}
      <span
        className={styles.source}
        style={{
          color:
            source?.toLowerCase() === SourceType.coach
              ? COLOR_BLUE
              : COLOR_GREEN,
        }}
      >
        {source}
      </span>
    </div>
  );
};
