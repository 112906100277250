import { trackError, trackUserInteractionEvent } from "appInsights/logInsights";
import { axiosInstance } from "axios-instance/axios-instance";
import { FlavorType } from "enums";
import { LoginInfo, AuthParameter, APIResponse } from "types";

interface LoginResponse {
  code: number;
  message?: string;
  data?: LoginInfo;
}

export const login = async (
  param: AuthParameter,
): Promise<LoginResponse | null> => {
  try {
    const flavorType = process.env.REACT_APP_FLAVOR;
    const response: APIResponse<LoginResponse> = await axiosInstance.post(
      `/login`,
      { ...param, team: flavorType === FlavorType.adqWomen ? "women" : "men" },
    );
    trackUserInteractionEvent("Login", { username: param.username });
    return response.data || null;
  } catch (err) {
    trackError(err as Error);
    console.error(err);
    return Promise.reject(err);
  }
};
