const IllnessOther = ({ opacity }: { opacity: number }) => {
  return (
    <svg
      width="160"
      height="410"
      viewBox="0 0 160 410"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={opacity}
    >
      <path
        d="M97.6834 14.3764C97.6834 13.7606 97.779 13.2153 97.9702 12.7403C98.1788 12.2653 98.4308 11.8431 98.7263 11.4736C99.0391 11.1042 99.3693 10.7611 99.7169 10.4444C100.065 10.1278 100.386 9.8287 100.682 9.54722C100.994 9.24815 101.246 8.94028 101.438 8.62361C101.646 8.30694 101.75 7.95509 101.75 7.56805C101.75 6.93472 101.49 6.42454 100.968 6.0375C100.464 5.65046 99.7865 5.45694 98.9348 5.45694C98.1179 5.45694 97.388 5.63287 96.7449 5.98472C96.1018 6.31898 95.5717 6.79398 95.1546 7.40972L92 5.53611C92.6952 4.46296 93.6598 3.60972 94.8939 2.97639C96.1279 2.32546 97.6226 2 99.378 2C100.682 2 101.829 2.19352 102.819 2.58055C103.81 2.95 104.583 3.49537 105.14 4.21667C105.713 4.93796 106 5.82639 106 6.88194C106 7.56805 105.896 8.175 105.687 8.70278C105.479 9.23055 105.209 9.68796 104.879 10.075C104.549 10.462 104.192 10.8227 103.81 11.1569C103.445 11.4912 103.097 11.8167 102.767 12.1333C102.437 12.45 102.159 12.7843 101.933 13.1361C101.724 13.488 101.62 13.9014 101.62 14.3764H97.6834ZM99.6648 21C98.9348 21 98.3352 20.7625 97.8659 20.2875C97.3966 19.8125 97.162 19.2495 97.162 18.5986C97.162 17.9301 97.3966 17.3759 97.8659 16.9361C98.3352 16.4787 98.9348 16.25 99.6648 16.25C100.412 16.25 101.012 16.4787 101.464 16.9361C101.933 17.3759 102.168 17.9301 102.168 18.5986C102.168 19.2495 101.933 19.8125 101.464 20.2875C101.012 20.7625 100.412 21 99.6648 21Z"
        fill="#F93939"
      />
    </svg>
  );
};

export default IllnessOther;
