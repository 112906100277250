import { useMemo } from "react";
import { getBodyPart } from "./bodyParts";
import styles from "./body-map.module.scss";
import { FlavorType } from "enums";
import { severityToOpacity } from "utils";
import AnteriorBodyContainer from "./anterior-body-container";
import PosteriorBodyContainer from "./posterior-body-container";
import IllnessBodyContainerAnt from "./illness-body-container-ant";
import IllnessBodyContainerPost from "./illness-body-container-post";
import IllnessSinusInfection from "./illness-representations/front/illness-sinus-infection";
import IllnessOther from "./illness-representations/front/illness-other";
import IllnessChills from "./illness-representations/front/illness-chills";
import IllnessChillsBack from "./illness-representations/back/illness-chills-back";
import IllnessRashFront from "./illness-representations/front/illness-rash-front";
import IllnessRashBack from "./illness-representations/back/illness-rash-back";
import IllnessStomachPain from "./illness-representations/front/illness-stomach-pain";
import IllnessAllergy from "./illness-representations/front/illness-allergy";
import IllnessAllergyBack from "./illness-representations/back/illness-allergy-back";
import IllnessThroatInfection from "./illness-representations/front/illness-throat-infection";
import IllnessHeavyFatigue from "./illness-representations/front/illness-heavy-fatigue";
import IllnessHeavyFatigueBack from "./illness-representations/back/illness-heavy-fatigue-back";
import IllnessChestInfection from "./illness-representations/front/illness-chest-infection";
import IllnessVomiting from "./illness-representations/front/illness-vomiting";
import IllnessOtherBack from "./illness-representations/back/illness-other-back";
import {
  BODY_CONTAINER_FILL_COLOR_MEN,
  BODY_CONTAINER_STROKE_COLOR_MEN,
} from "clients/uaeMen/colors";
import {
  BODY_CONTAINER_FILL_COLOR_WOMEN,
  BODY_CONTAINER_STROKE_COLOR_WOMEN,
} from "clients/adqWomen/colors";
import WheezeFront from "./illness-representations/front/wheeze-front";
import DiarhoaeFront from "./illness-representations/front/diarhoae-front";
import DiarhoeaBack from "./illness-representations/back/diarhoea-back";
import NauseaBack from "./illness-representations/back/nausea-back";
import NauseaFront from "./illness-representations/front/nausea-front";
import PainFront from "./illness-representations/front/pain-front";
import PainBack from "./illness-representations/back/pain-back";
import CoughFront from "./illness-representations/front/cough-front";

export interface BodyMapProps {
  selectedSide: string;
  selectedBodyLocation: string;
  recordType: string;
  selectedArea: string;
  selectedSymptom: string;
  selectedSeverity: string;
  isAthleteCard?: boolean;
}
interface BodyContainerProps {
  children: React.ReactNode;
}

const BodyContainer = ({ children }: BodyContainerProps) => (
  <div
    style={{
      width: "155px",
      // height: "500px",
      // margin: "30px auto",
    }}
  >
    {/* adjust the viewbox so that there's not much distance between two body containers */}
    <svg
      width="164"
      height="420"
      viewBox="0 0 164 395"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>{children}</g>
    </svg>
  </div>
);

const BodyContainerSmall = ({ children }: BodyContainerProps) => (
  <div
    style={{
      width: "120px",
      // height: "500px",
      // margin: "30px auto",
    }}
  >
    {/* adjust the viewbox so that there's not much distance between two body containers */}
    <svg
      width="130"
      height="300"
      viewBox="0 0 164 420"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>{children}</g>
    </svg>
  </div>
);

interface BodyPartProps {
  id: string;
  d: string;
  fill: string;
  opacity?: number;
}

const BodyPart = ({ id, d, fill, opacity }: BodyPartProps) => {
  return (
    <path
      d={d}
      id={id}
      style={Object.assign(
        {},
        {
          WebkitTapHighlightColor: "transparent",
        },
        { fill },
      )}
      opacity={opacity}
    />
  );
};

export const BodyMap = ({
  recordType,
  selectedSide,
  selectedBodyLocation,
  selectedArea,
  selectedSymptom,
  selectedSeverity,
  isAthleteCard,
}: BodyMapProps) => {
  // get anterior body parts
  const antBodyParts = useMemo(() => {
    return getBodyPart().filter(({ face }: { face: string }) => face === "ant");
  }, []);

  // get posterior body parts
  const postBodyPart = useMemo(() => {
    return getBodyPart().filter(
      ({ face }: { face: string }) => face === "post",
    );
  }, []);

  // PHYSIO: BODY LOCATION mapping
  const bodyLocationToIdMapping: { [key: string]: any } = {
    HEAD_NECK: {
      LEFT: [0, 1, 36, 37],
      RIGHT: [0, 1, 36, 37],
      BOTH: [0, 1, 36, 37],
    },
    SHOULDER: {
      LEFT: [3, 38],
      RIGHT: [2, 39],
      BOTH: [2, 3, 38, 39],
    },
    HIP_PELVIS: {
      LEFT: [13, 15, 44, 46],
      RIGHT: [12, 14, 45, 47],
      BOTH: [12, 13, 14, 15, 44, 45, 46, 47],
    },
    HAND_WRIST: {
      LEFT: [11, 25, 42, 56],
      RIGHT: [10, 24, 43, 57],
      BOTH: [56, 24, 42, 10, 57, 43, 25, 11],
    },
    LEG_KNEE: {
      LEFT: [17, 19, 48, 50],
      RIGHT: [16, 18, 51, 49],
      BOTH: [16, 17, 18, 19, 48, 49, 50, 51],
    },
    ARM_ELBOW: {
      LEFT: [5, 6, 8, 25, 40, 52, 54],
      RIGHT: [4, 7, 9, 24, 55, 53, 41],
      BOTH: [4, 5, 6, 7, 8, 9, 54, 55, 52, 53, 40, 41],
    },
    ANKLE_FOOT: {
      LEFT: [21, 23, 34, 30],
      RIGHT: [20, 22, 35, 31],
      BOTH: [20, 21, 22, 23, 30, 31, 34, 35],
    },
    BACK_SPINE: {
      LEFT: [26, 27, 28, 29],
      RIGHT: [26, 27, 28, 29],
      BOTH: [26, 27, 28, 29],
    },
  };

  const bodyLocationToColorMapping: { [key: string]: string } = {
    HEAD_NECK: "#FF6347",
    SHOULDER: "#FFD700",
    HIP_PELVIS: "#FF4500",
    HAND_WRIST: "#20B2AA",
    LEG_KNEE: "#32CD32",
    ARM_ELBOW: "#ADFF2F",
    ANKLE_FOOT: "#FF69B4",
    BACK_SPINE: "#1E90FF",
  };

  // INJURY: BODY AREA mapping
  const bodyAreaToIdMapping: { [key: string]: any } = {
    KNEE: {
      BOTH: [16, 17, 48, 49],
      LEFT: [17, 48],
      RIGHT: [16, 49],
    },
    HEAD: {
      BOTH: [0, 37],
      LEFT: [0, 37],
      RIGHT: [0, 37],
    },
    SHOULDER: {
      BOTH: [2, 3, 38, 39],
      LEFT: [3, 38],
      RIGHT: [2, 39],
    },
    LOWER_BACK: { LEFT: [29], RIGHT: [29], BOTH: [29] },
    FOREARM: {
      BOTH: [8, 9, 52, 53],
      LEFT: [8, 52],
      RIGHT: [9, 53],
    },
    UPPER_ARM: {
      BOTH: [4, 5, 54, 55],
      LEFT: [5, 54],
      RIGHT: [4, 55],
    },
    GROIN_HIP: {
      BOTH: [12, 13, 44, 45],
      LEFT: [13, 44],
      RIGHT: [12, 45],
    },
    LOWER_LEG: {
      BOTH: [18, 19, 50, 51],
      LEFT: [19, 50],
      RIGHT: [18, 51],
    },
    ELBOW: {
      BOTH: [6, 7, 40, 41],
      LEFT: [7, 41],
      RIGHT: [6, 40],
    },
    HAND: {
      BOTH: [10, 11, 56, 57],
      LEFT: [11, 56],
      RIGHT: [10, 57],
    },
    FOOT: {
      BOTH: [20, 21, 22, 23, 30, 31, 34, 35],
      LEFT: [21, 23, 30, 34],
      RIGHT: [20, 22, 31, 35],
    },
    CHEST: { LEFT: [32], RIGHT: [32], BOTH: [32] },
    ABDOMEN: { LEFT: [33], RIGHT: [33], BOTH: [33] },
    LUMBAR_SPINE: { LEFT: [27], RIGHT: [27], BOTH: [27] },
    THIGH: {
      BOTH: [14, 15, 46, 47],
      LEFT: [15, 46],
      RIGHT: [14, 47],
    },
    NECK: {
      BOTH: [1, 36],
      LEFT: [1, 36],
      RIGHT: [1, 36],
    },
    THORACIC_SPINE: { LEFT: [26], RIGHT: [26], BOTH: [26] },
    UPPER_BACK: { LEFT: [28], RIGHT: [28], BOTH: [28] },
    WRIST: {
      BOTH: [24, 25, 42, 43],
      LEFT: [25, 42],
      RIGHT: [24, 43],
    },
  };

  const bodyAreaToColorMapping: { [key: string]: string } = {
    KNEE: "#FF69B4",
    HEAD: "#DAA520",
    SHOULDER: "#6A5ACD",
    LOWER_BACK: "#00CED1",
    FOREARM: "#FF4500",
    UPPER_ARM: "#BA55D3",
    GROIN_HIP: "#FF8C00",
    LOWER_LEG: "#FFD700",
    ELBOW: "#ADFF2F",
    HAND: "#32CD32",
    FOOT: "#20B2AA",
    CHEST: "#DC143C",
    ABDOMEN: "#FF6347",
    LUMBAR_SPINE: "#8B0000",
    THIGH: "#1E90FF",
    NECK: "#7FFF00",
    THORACIC_SPINE: "#A52A2A",
    UPPER_BACK: "#B0C4DE",
    WRIST: "#8A2BE2",
  };

  const getFill = (bodyPartId: number) => {
    // get bodyPartId based on the selectedBodyLocation
    if (recordType === "PHYSIO") {
      if (
        bodyLocationToIdMapping?.[selectedBodyLocation]?.[
          selectedSide
        ]?.includes(bodyPartId)
      )
        return bodyLocationToColorMapping[selectedBodyLocation];
      else return "";
    } else if (recordType === "INJURY") {
      if (
        bodyAreaToIdMapping?.[selectedArea]?.[selectedSide]?.includes(
          bodyPartId,
        )
      )
        return bodyAreaToColorMapping[selectedArea];
      else return "";
    }
    return "";
  };

  // ILLNESS: SYMPTOMS mapping
  const symptomToSVGMapping: { [key: string]: any } = {
    NASAL_CONGESTION: [
      <IllnessSinusInfection
        key={1}
        opacity={severityToOpacity(selectedSeverity)}
      />,
    ],
    OTHER: [
      <IllnessOther key={1} opacity={severityToOpacity(selectedSeverity)} />,
      <IllnessOtherBack
        key={2}
        opacity={severityToOpacity(selectedSeverity)}
      />,
    ],
    CHILLS: [
      <IllnessChills key={1} opacity={severityToOpacity(selectedSeverity)} />,
      <IllnessChillsBack
        key={2}
        opacity={severityToOpacity(selectedSeverity)}
      />,
    ],
    RASH: [
      <IllnessRashFront
        key={1}
        opacity={severityToOpacity(selectedSeverity)}
      />,
      <IllnessRashBack key={2} opacity={severityToOpacity(selectedSeverity)} />,
    ],
    STOMACH_PAIN: [
      <IllnessStomachPain
        key={1}
        opacity={severityToOpacity(selectedSeverity)}
      />,
    ],
    ALLERGY: [
      <IllnessAllergy key={1} opacity={severityToOpacity(selectedSeverity)} />,
      <IllnessAllergyBack
        key={2}
        opacity={severityToOpacity(selectedSeverity)}
      />,
    ],
    THROAT_PAIN: [
      <IllnessThroatInfection
        key={1}
        opacity={severityToOpacity(selectedSeverity)}
      />,
    ],
    HEAVY_FATIGUE: [
      <IllnessHeavyFatigue
        key={1}
        opacity={severityToOpacity(selectedSeverity)}
      />,
      <IllnessHeavyFatigueBack
        key={2}
        opacity={severityToOpacity(selectedSeverity)}
      />,
    ],
    SHORTNESS_OF_BREATH: [
      <IllnessChestInfection
        key={1}
        opacity={severityToOpacity(selectedSeverity)}
      />,
    ],
    VOMITING: [
      <IllnessVomiting key={1} opacity={severityToOpacity(selectedSeverity)} />,
    ],
    WHEEZE: [
      <WheezeFront key={1} opacity={severityToOpacity(selectedSeverity)} />,
    ],
    DIARRHOEA: [
      <DiarhoaeFront key={1} opacity={severityToOpacity(selectedSeverity)} />,
      <DiarhoeaBack key={2} opacity={severityToOpacity(selectedSeverity)} />,
    ],
    NAUSEA: [
      <NauseaFront key={1} opacity={severityToOpacity(selectedSeverity)} />,
      <NauseaBack key={2} opacity={severityToOpacity(selectedSeverity)} />,
    ],
    PAIN: [
      <PainFront key={1} opacity={severityToOpacity(selectedSeverity)} />,
      <PainBack key={2} opacity={severityToOpacity(selectedSeverity)} />,
    ],
    COUGH: [
      <CoughFront key={1} opacity={severityToOpacity(selectedSeverity)} />,
    ],
  };

  const getBodyContainerFillColor = () => {
    const flavorType = process.env.REACT_APP_FLAVOR;
    const team = flavorType === FlavorType.adqWomen ? "women" : "men";
    if (team === "men") return BODY_CONTAINER_FILL_COLOR_MEN;
    else if (team === "women") return BODY_CONTAINER_FILL_COLOR_WOMEN;
    return BODY_CONTAINER_FILL_COLOR_MEN;
  };

  const getBodyContainerStrokeColor = () => {
    const flavorType = process.env.REACT_APP_FLAVOR;
    const team = flavorType === FlavorType.adqWomen ? "women" : "men";
    if (team === "men") return BODY_CONTAINER_STROKE_COLOR_MEN;
    else if (team === "women") return BODY_CONTAINER_STROKE_COLOR_WOMEN;
    return BODY_CONTAINER_STROKE_COLOR_MEN;
  };

  return (
    <>
      <div
        className={styles.bodies}
        style={{ justifyContent: isAthleteCard ? "center" : "" }}
      >
        {/* Anterior */}
        <div
          className={styles.anteriorMap}
          style={{ margin: !isAthleteCard ? "1rem" : "" }}
        >
          {!isAthleteCard ? (
            <BodyContainer>
              {recordType !== "ILLNESS" ? (
                <>
                  <AnteriorBodyContainer
                    fill={getBodyContainerFillColor()}
                    stroke={getBodyContainerStrokeColor()}
                  />{" "}
                  {antBodyParts.map((bodyPart, index) => (
                    <BodyPart
                      key={index}
                      id={bodyPart.id.toString()}
                      d={bodyPart.d}
                      fill={getFill(bodyPart.id)}
                      opacity={severityToOpacity(selectedSeverity)}
                    />
                  ))}
                </>
              ) : (
                <>
                  <IllnessBodyContainerAnt
                    fill={getBodyContainerFillColor()}
                    stroke={getBodyContainerStrokeColor()}
                  />
                  {symptomToSVGMapping[selectedSymptom]?.[0]}
                </>
              )}
            </BodyContainer>
          ) : (
            <BodyContainerSmall>
              {recordType !== "ILLNESS" ? (
                <>
                  <AnteriorBodyContainer
                    fill={getBodyContainerFillColor()}
                    stroke={getBodyContainerStrokeColor()}
                  />{" "}
                  {antBodyParts.map((bodyPart, index) => (
                    <BodyPart
                      key={index}
                      id={bodyPart.id.toString()}
                      d={bodyPart.d}
                      fill={getFill(bodyPart.id)}
                      opacity={severityToOpacity(selectedSeverity)}
                    />
                  ))}
                </>
              ) : (
                <>
                  <IllnessBodyContainerAnt
                    fill={getBodyContainerFillColor()}
                    stroke={getBodyContainerStrokeColor()}
                  />
                  {symptomToSVGMapping[selectedSymptom]?.[0]}
                </>
              )}
            </BodyContainerSmall>
          )}
        </div>
        {/* Posterior */}
        <div>
          {!isAthleteCard ? (
            <BodyContainer>
              {recordType !== "ILLNESS" ? (
                <>
                  <PosteriorBodyContainer
                    fill={getBodyContainerFillColor()}
                    stroke={getBodyContainerStrokeColor()}
                  />{" "}
                  {postBodyPart.map((bodyPart, index) => (
                    <BodyPart
                      key={index}
                      id={bodyPart.id.toString()}
                      d={bodyPart.d}
                      fill={getFill(bodyPart.id)}
                      opacity={
                        recordType === "PHYSIO" &&
                        selectedBodyLocation === "BACK_SPINE"
                          ? 0.65
                          : severityToOpacity(selectedSeverity)
                      }
                    />
                  ))}
                </>
              ) : (
                <>
                  <IllnessBodyContainerPost
                    fill={getBodyContainerFillColor()}
                    stroke={getBodyContainerStrokeColor()}
                  />
                  {symptomToSVGMapping[selectedSymptom]?.length > 0 &&
                    symptomToSVGMapping[selectedSymptom]?.[1]}
                </>
              )}
            </BodyContainer>
          ) : (
            <BodyContainerSmall>
              {recordType !== "ILLNESS" ? (
                <>
                  <PosteriorBodyContainer
                    fill={getBodyContainerFillColor()}
                    stroke={getBodyContainerStrokeColor()}
                  />{" "}
                  {postBodyPart.map((bodyPart, index) => (
                    <BodyPart
                      key={index}
                      id={bodyPart.id.toString()}
                      d={bodyPart.d}
                      fill={getFill(bodyPart.id)}
                      opacity={
                        recordType === "PHYSIO" &&
                        selectedBodyLocation === "BACK_SPINE"
                          ? 0.65
                          : severityToOpacity(selectedSeverity)
                      }
                    />
                  ))}
                </>
              ) : (
                <>
                  <IllnessBodyContainerPost
                    fill={getBodyContainerFillColor()}
                    stroke={getBodyContainerStrokeColor()}
                  />
                  {symptomToSVGMapping[selectedSymptom]?.length > 0 &&
                    symptomToSVGMapping[selectedSymptom]?.[1]}
                </>
              )}
            </BodyContainerSmall>
          )}
        </div>
      </div>
    </>
  );
};
