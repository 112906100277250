import { forwardRef, useCallback, useContext, useMemo } from "react";
import { IMultiSelectOnChange, ISelectOption, ISelectSize } from "./types.tsx";
import { ChevronDown, X } from "lucide-react";
import { IconButton } from "../Button/IconButton";
import { clsx } from "clsx";
import { Spinner } from "../Spinner";
import { TruncatedTags } from "../TruncatedTags";
import { TruncatedText } from "../TruncatedText";
import { IButtonSize } from "../Button/types.ts";
import { ISpinnerSize } from "../Spinner/types";
import { ITagProps, ITagSize } from "../Tag/types";
import { SelectContext } from "./context.tsx";

export const SelectToggle = forwardRef<HTMLDivElement>((_, ref) => {
  const {
    isOpen,
    localValue: value,
    handleClear,
    onChange,
    placeholder,
    invalid,
    disabled,
    loading,
    size = "m",
    showClearIcon,
    ...context
  } = useContext(SelectContext);
  const tags = context.multiple && context.tags;

  const fontSizeMap: Record<ISelectSize, string> = {
    s: "analog-typography--body",
    m: "analog-typography--body",
    l: "analog-typography--body",
  };

  const rootClass: string = clsx(
    "analog-select__toggle",
    disabled && "analog-select__toggle--disabled",
    fontSizeMap[size],
    isOpen && "analog-select__toggle--focused",
    invalid && "analog-select__toggle--invalid",
  );

  const buttonSizeMap: Record<ISelectSize, IButtonSize> = {
    s: "xs",
    m: "s",
    l: "m",
  };

  const spinnerSizeMap: Record<ISelectSize, ISpinnerSize> = {
    s: "xs",
    m: "s",
    l: "m",
  };

  const tagsSizeMap: Record<ISelectSize, ITagSize> = {
    s: "m",
    m: "m",
    l: "m",
  };

  const chevronClass: string = clsx(
    "analog-select__chevron",
    isOpen && "analog-select__chevron--rotate",
  );

  const displayValue: string = useMemo(() => {
    if (value.length === 0) {
      return "";
    }
    return value.map((option: ISelectOption) => option.text).join(", ");
  }, [value]);

  const onTagRemove = useCallback(
    (option: ITagProps) => {
      if (!context.multiple) {
        return;
      }
      const nextOptions: ISelectOption[] = value.filter(
        (value: ISelectOption) => value.id !== option.id,
      );
      if (onChange) {
        (onChange as IMultiSelectOnChange)(nextOptions);
      }
    },
    [context.multiple, onChange, value],
  );

  return (
    <div className={rootClass} tabIndex={0} ref={ref}>
      {placeholder && value.length === 0 && (
        <div className="analog-select__toggle-placeholder">{placeholder}</div>
      )}

      {!tags && displayValue && (
        <TruncatedText
          text={displayValue}
          className="analog-select__toggle-value"
        />
      )}

      {value.length > 0 && tags && (
        <TruncatedTags
          items={value}
          onRemove={onTagRemove}
          disabled={disabled}
          tagProps={{
            size: tagsSizeMap[size],
            variant: "neutral-light",
          }}
        />
      )}

      {!disabled && (
        <div className="analog-select__toggle-actions">
          {(showClearIcon || (value.length > 0 && handleClear)) && (
            <IconButton
              variant="quietNeutral"
              size={buttonSizeMap[size]}
              onClick={handleClear}
              content={<X />}
            />
          )}
        </div>
      )}
      {loading && !disabled ? (
        <div className="analog-select__toggle-spinner">
          <Spinner size={spinnerSizeMap[size]} />
        </div>
      ) : (
        <IconButton
          variant="quietNeutral"
          size={buttonSizeMap[size]}
          disabled={disabled}
          content={<ChevronDown className={chevronClass} />}
        />
      )}
    </div>
  );
});

SelectToggle.displayName = "SelectToggle";
