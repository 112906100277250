import React, { FC } from "react";
import Select from "react-windowed-select";
import clsx from "clsx";

import styles from "./generic-type-selector.module.scss";
import "./generic-type-selector.overwrite.scss";
import { ComparisonTimeOptions } from "enums";

interface SelectorOption {
  label: string;
  value: number;
}

interface Props {
  options: SelectorOption[];
  small?: boolean;
  header?: boolean;
  selectedMetric: number;
  handleOptionChange: (option: ComparisonTimeOptions) => void;
  className?: string;
}

export const GenericTypeSelector: FC<Props> = ({
  small,
  header,
  selectedMetric,
  handleOptionChange,
  className,
  options,
}) => {
  const value: SelectorOption | undefined = options.find(
    (option) => option.value === selectedMetric,
  );

  console.log("[ANKET] value", value);
  console.log("[ANKET] options", options);
  console.log("[ANKET] selectedMetric", selectedMetric);

  const handleChange = (newOption: SelectorOption | null) => {
    if (newOption) {
      handleOptionChange(newOption.value);
    }
  };

  return (
    <div className={clsx(styles.wrapper, className)}>
      {/* @ts-ignore */}
      <Select
        isClearable={false}
        isSearchable={false}
        options={options}
        value={value}
        defaultValue={value}
        onChange={handleChange}
        className={clsx(
          "generic-type-selector__select",
          small && "generic-type-selector__select--small",
          header && "generic-type-selector__select--header",
        )}
        classNamePrefix="react-select"
      />
    </div>
  );
};
