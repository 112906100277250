import { FC } from "react";
import clsx from "clsx";
import styles from "./chat-bubble.module.scss";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // Include if you need GFM features

type Props = {
  message: string;
  isUser: boolean;
  isExpand: boolean | undefined;
  setIsHovered: (value: string | undefined) => void;
  id: string | undefined;
};

// Function to parse the message and separate Markdown, fractions, and LaTeX
const parseMessage = (message: string) => {
  const parts: Array<{ type: string; content: string }> = [];
  const regex =
    /(\$\$.*?\$\$|\$.*?\$|\\\[.*?\\\]|\\\((.*?)\\\)|\\begin\{(.*?)\}|\\frac\{.*?\}\{.*?\})/g;
  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = regex.exec(message)) !== null) {
    if (match.index > lastIndex) {
      parts.push({
        type: "markdown",
        content: message.slice(lastIndex, match.index),
      });
    }

    const latex = match[0];
    if (/\\frac\{.*?\}\{.*?\}/.test(latex)) {
      parts.push({ type: "fraction", content: latex });
    } else {
      parts.push({ type: "latex", content: latex });
    }
    lastIndex = regex.lastIndex;
  }

  if (lastIndex < message.length) {
    parts.push({
      type: "markdown",
      content: message.slice(lastIndex),
    });
  }

  return parts;
};

// Component to render a Markdown part
const MarkdownPart: FC<{ content: string }> = ({ content }) => (
  <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
);

// Component to render a LaTeX part
const LatexPart: FC<{ content: string; isExpanded: boolean | undefined }> = ({
  content,
  isExpanded,
}) => (
  <div
    className={styles.latexPart}
    style={{ width: isExpanded ? "350px" : "250px" }} // Apply dynamic width
  >
    <MathJax inline>{content}</MathJax>
  </div>
);

// Component to render a Fraction part
const FractionPart: FC<{
  content: string;
  isExpanded: boolean | undefined;
}> = ({ content, isExpanded }) => (
  <div
    className={styles.fractionPart}
    style={{ width: isExpanded ? "350px" : "250px" }} // Apply dynamic width
  >
    <MathJax inline>{content}</MathJax>
  </div>
);

export const ChatBubble: FC<Props> = ({
  message,
  isUser,
  isExpand,
  setIsHovered,
  id,
}) => {
  const handleMouseEnter = (messageId: any) => {
    setIsHovered(messageId);
  };
  const handleMouseLeave = () => {
    setIsHovered(undefined);
  };
  const parts = parseMessage(message);
  return (
    <div className={clsx(styles.chatBubble, isUser && styles.chatBubbleUser)}>
      {isUser ? (
        <div className={styles.chatBubbleText}>{message}</div>
      ) : (
        <MathJaxContext>
          <div className={styles.chatBubbleText}>
            {parts.map((part, index) => (
              <div
                key={`wrapper-${index}`}
                className={styles.partWrapper}
                onMouseEnter={() => handleMouseEnter(id)}
              >
                {part.type === "markdown" && (
                  <MarkdownPart key={index} content={part.content} />
                )}
                {part.type === "fraction" && (
                  <FractionPart
                    key={index}
                    content={part.content}
                    isExpanded={isExpand}
                  />
                )}
                {part.type === "latex" && (
                  <LatexPart
                    key={index}
                    content={part.content}
                    isExpanded={isExpand}
                  />
                )}
              </div>
            ))}
          </div>
        </MathJaxContext>
      )}
    </div>
  );
};
