import type { ForwardedRef } from "react";
import type { IIconButtonProps } from "./types.ts";
import { forwardRef } from "react";
import { BaseButton } from "./index";
import { ButtonAdornmentIcon } from "./ButtonAdornmentIcon";

export const IconButton = forwardRef(
  (props: IIconButtonProps, buttonRef: ForwardedRef<HTMLButtonElement>) => {
    return (
      <BaseButton
        {...props}
        ref={buttonRef}
        startIcon={undefined}
        endIcon={undefined}
        spinnerPosition="center"
        isIcon={true}
        aria-label={props["aria-label"] || "Icon button"}
      >
        <ButtonAdornmentIcon size={props.size || "s"} icon={props.content} />
      </BaseButton>
    );
  },
);

IconButton.displayName = "IconButton";
