import s from "./index.module.css";
import { Picture } from "./components/Picture";
import { Stats } from "./features/Stats";
import { Feedback } from "./features/Feedback";
import { AthleteCardContext } from "./context";
import { useViewModel } from "./useViewModel";
import { EmptyState } from "../../storybook";

export const AthleteProfile = () => {
  const context = useViewModel();
  const { athlete, handleSingOut } = context;

  const emptyStateJSX = (
    <div className={s.athleteProfileEmpty}>
      <EmptyState
        title="No data available"
        text="It looks like you are not an athlete. Sign in under athlete account to see your data."
        buttonProps={{
          content: "Sign out",
          onClick: handleSingOut,
        }}
      />
    </div>
  );

  return (
    <AthleteCardContext.Provider value={context}>
      {athlete ? (
        <div className={s.athleteProfile}>
          <Picture />
          <Stats />
          <Feedback />
        </div>
      ) : (
        emptyStateJSX
      )}
    </AthleteCardContext.Provider>
  );
};
