import { axiosInstance } from "axios-instance/axios-instance";
import { Athlete, APIAthlete, APIResponseV2 } from "types";

const getAthletes = async (): Promise<Athlete[]> => {
  const {
    data: { data },
  }: APIResponseV2<APIAthlete[]> = await axiosInstance.get(`/athletes`);

  return (data || []).map((athlete) => ({
    id: athlete.athleteId,
    firstName: athlete.firstName,
    lastName: athlete.lastName,
    fullName: athlete.fullName,
    email: athlete.email,
    country: athlete.country,
    countryCode: athlete.countryCode,
    dateOfBirth: athlete.dateOfBirth,
    picture: athlete.picture,
    weight: athlete.weight,
    height: athlete.height,
    proSince: athlete.proSince,
    group: athlete.group,
    vo2Max: athlete.vo2Max,
    atl: athlete.atl,
    ctl: athlete.ctl,
    tsb: athlete.tsb,
    rhr: athlete.rhr,
    hrv: athlete.hrv,
    rhrVariance: athlete.rhrVariance,
    hrvVariance: athlete.hrvVariance,
    mCPRelative: athlete.mCPRelative,
    mCPRelativeVariancePercentage: athlete.mCPRelativeVariancePercentage,
    mcp: athlete.mcp,
    mCPVariance: athlete.mCPVariance,
    medicalReport: athlete?.medicalReport,
    activeMedicalReports: athlete.activeMedicalReports,
  }));
};

export const athletes = {
  getAthletes,
};
