import React, {
  ChangeEvent,
  KeyboardEvent,
  useState,
  useEffect,
  useRef,
} from "react";
import clsx from "clsx";
import styles from "./chat-input.module.scss";
import { ReactComponent as Send } from "images/send.svg";
import { PrimaryButton } from "components/primary-button/primary-button";

type ChatInputProps = {
  name: string;
  isLoading: boolean;
  onChangeMultiline?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onSend: () => void;
  onKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
};

export const ChatInput: React.FC<ChatInputProps> = ({
  name = "chatInput",
  onChangeMultiline,
  isLoading,
  onSend,
  onKeyDown,
}) => {
  const [value, setValue] = useState<string>("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const maxHeight = 5 * 18 + 20; // 5 lines at 18px each + padding

  const handleChangeMultiline = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (onChangeMultiline) {
      onChangeMultiline(e);
    }
  };

  const handleMessageSend = () => {
    onSend();
    setValue("");
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleMessageSend();
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset the height
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`; // Set height to scrollHeight or maxHeight
      textareaRef.current.style.overflowY =
        scrollHeight > maxHeight ? "scroll" : "hidden"; // Add scroll if needed
    }
  }, [value, maxHeight]);

  return (
    <div className={styles.inputBlock}>
      <label htmlFor={name} className={styles.label}>
        <div className={styles.inputWrapper}>
          <textarea
            id={name}
            name={name}
            value={value}
            ref={textareaRef}
            placeholder="How can I help you?"
            onChange={handleChangeMultiline}
            onKeyDown={handleKeyDown}
            className={clsx(styles.textarea, isLoading && styles.disabled)}
          />
          <PrimaryButton
            onClick={handleMessageSend}
            disabled={isLoading}
            className={styles.sendWrapper}
          >
            <Send />
          </PrimaryButton>
        </div>
      </label>
    </div>
  );
};
