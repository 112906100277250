import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { RoleType } from "enums";

import { RootState } from "store";

export interface AuthState {
  authorised: boolean | null;
  accountType: string | null;
  remember: boolean | null;
  userName: string | null;
}

const initialState: AuthState = {
  authorised: localStorage.getItem("authorised") === "true",
  accountType: localStorage.getItem("accountType") ?? "cyclist",
  remember: localStorage.getItem("remember") === "true",
  userName: localStorage.getItem("userName") ?? "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthorised: (state, action: PayloadAction<boolean | null>) => {
      state.authorised = action.payload;
    },
    setSelectedAccountType: (state, action: PayloadAction<string | null>) => {
      state.accountType = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<string | null>) => {
      state.userName = action.payload;
    },
    setRemember: (state, action: PayloadAction<string | null>) => {
      state.accountType = action.payload;
    },
  },
});

export const { setAuthorised, setSelectedAccountType, setUserInfo } =
  authSlice.actions;

export const setAuthState =
  (authorised: boolean | null, token?: string) =>
  async (dispatch: Dispatch) => {
    localStorage.setItem(
      "authorised",
      authorised ? authorised.toString() : "null",
    );
    if (token) {
      localStorage.setItem("token", token.toString());
    } else {
      localStorage.removeItem("token");
    }

    dispatch(setAuthorised(authorised));
  };

export const setAccount =
  (accountType: string) => async (dispatch: Dispatch) => {
    localStorage.setItem("accountType", accountType.toString());
    dispatch(setSelectedAccountType(accountType));
  };

export const setRemember =
  (accountType: string) => async (dispatch: Dispatch) => {
    localStorage.setItem("remember", accountType.toString());
    dispatch(setSelectedAccountType(accountType));
  };

export const setUserName = (userName: string) => async (dispatch: Dispatch) => {
  localStorage.setItem("userName", userName.toString());
  dispatch(setUserInfo(userName));
};

export const getAuthState = (state: RootState) => state.auth.authorised;

export const getAccount = (state: RootState) => state.auth.accountType;

export const getUserName = (state: RootState) => state.auth.userName;

export const getIsCyclist = (state: RootState) => {
  return state.auth.accountType === RoleType.cyclist;
};

export const auth = authSlice.reducer;
