import { FC, ReactNode } from "react";
import clsx from "clsx";
import { TitleProps, Typography } from "components/typography/typography";

import styles from "./heading.module.scss";

type Props = Partial<TitleProps> & {
  icon?: ReactNode;
};

export const Heading: FC<Props> = ({
  children,
  className,
  tag = "h4",
  size = "xl",
  weight = "bold",
  icon,
}) => (
  <div className={clsx(className, styles.heading)}>
    {icon && (
      <span className={styles.icon} aria-hidden={true}>
        {icon}
      </span>
    )}

    <Typography.Title tag={tag} size={size} weight={weight}>
      {children}
    </Typography.Title>
  </div>
);
