import { Id, toast } from "react-toastify";
import type { ToastOptions, TypeOptions } from "react-toastify/dist/types";
import type { IToastContent, IToastVariant } from "./types.tsx";
import { ToastItem } from "./ToastItem";
import { toastContainerId } from "./ToastContainer.tsx";

export class Toast {
  static info(content: IToastContent, toastOptions?: ToastOptions): Id {
    return createToast(content, {
      ...toastOptions,
      type: "info",
    });
  }

  static success(content: IToastContent, toastOptions?: ToastOptions): Id {
    return createToast(content, {
      ...toastOptions,
      type: "success",
    });
  }

  static warning(content: IToastContent, toastOptions?: ToastOptions): Id {
    return createToast(content, {
      ...toastOptions,
      type: "warning",
    });
  }

  static error(content: IToastContent, toastOptions?: ToastOptions): Id {
    return createToast(content, {
      ...toastOptions,
      type: "error",
    });
  }
}

function getToastVariant(type: TypeOptions | undefined): IToastVariant {
  switch (type) {
    case "info":
      return "info";
    case "success":
      return "success";
    case "warning":
      return "warning";
    case "error":
      return "error";
    default:
      return "info";
  }
}

function createToast(content: IToastContent, toastOptions?: ToastOptions): Id {
  return toast(
    ({ closeToast }: { closeToast: () => void }) => {
      return (
        <ToastItem
          title={content.title}
          message={content.message}
          onDismiss={closeToast}
          variant={getToastVariant(toastOptions?.type)}
        />
      );
    },
    {
      ...toastOptions,
      containerId: toastContainerId,
    },
  );
}
