import type { ICheckboxProps, ICheckboxSize } from "./types.tsx";
import type { ITypographyVariant } from "../Typography/types.ts";
import { forwardRef, useMemo } from "react";
import "./index.css";
import { clsx } from "clsx";
import { Check, Minus } from "lucide-react";
import { Typography } from "../Typography";

/** Extends native checkbox input */
export const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  (props, ref) => {
    const checkboxSize: ICheckboxSize = props.size || "s";
    const rootClass: string = clsx(
      "analog-checkbox",
      `analog-checkbox--${checkboxSize}`,
      props.indeterminate && "analog-checkbox--indeterminate",
      props.disabled && "analog-checkbox--disabled",
      props.className,
    );

    const typographyVariant: Record<ICheckboxSize, ITypographyVariant> = {
      s: "subtitle",
      m: "body",
    };

    const iconSize: Record<ICheckboxSize, number> = {
      s: 14,
      m: 16,
    };

    const iconJSX = props.indeterminate ? (
      <Minus size={iconSize[checkboxSize]} />
    ) : (
      <Check size={iconSize[checkboxSize]} />
    );

    const nativeProps: ICheckboxProps = useMemo(() => {
      const nativeProps: ICheckboxProps = { ...props };
      delete nativeProps.size;
      delete nativeProps.indeterminate;
      delete nativeProps.onRenderLabel;
      return nativeProps;
    }, [props]);

    return (
      <label className={rootClass}>
        <input
          {...nativeProps}
          size={undefined}
          type="checkbox"
          className="analog-checkbox__input"
          ref={ref}
        />

        <span className="analog-checkbox__check">
          <span className="analog-checkbox__mark">{iconJSX}</span>
        </span>

        {(props.onRenderLabel || props.label) && (
          <div className="analog-checkbox__text" tabIndex={-1}>
            {props.onRenderLabel ? (
              props.onRenderLabel()
            ) : (
              <Typography
                variant={typographyVariant[checkboxSize]}
                text={props.label}
              />
            )}
          </div>
        )}
      </label>
    );
  },
);

Checkbox.displayName = "Checkbox";
