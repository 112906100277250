import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";
import { PrimaryButton } from "components/primary-button/primary-button";
import styles from "./notes-popup.module.scss";
import { ReactComponent as Info } from "images/info_icon.svg";
import { useSelector } from "react-redux";
import { getCurrentAthleteName } from "store/slices/shared";
import { createNote } from "api/medical-report";
import { FlavorType } from "enums";

type Props = {
  onClose: () => void;
  setNewNotes: () => void;
  selectedMedicalReportId: number;
};

export const NotesPopup: FC<Props> = ({
  onClose,
  setNewNotes,
  selectedMedicalReportId,
}) => {
  const [error, setError] = useState(false);
  const [noteInput, setNoteInput] = useState("");
  const atheleteName = useSelector(getCurrentAthleteName);
  const flavorType = process.env.REACT_APP_FLAVOR;
  const team = flavorType === FlavorType.adqWomen ? "women" : "men";

  // On change input of textArea type
  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setNoteInput(e.target.value);
  };

  // api call to post note
  const handleSubmitClick = async () => {
    if (noteInput) {
      const data = {
        medicalReportId: selectedMedicalReportId,
        note: noteInput,
        createdBy: atheleteName,
        editable: true,
      };
      await createNote(data);
      setNewNotes();
      onClose();
    }
  };

  return (
    <div className={clsx(styles.popupBox, "comparison-dropdown")}>
      <div
        className={clsx(styles.inputBox, styles.box)}
        data-testid="add-comparison-list"
      >
        <div className={styles.header}>
          <h3 className={styles.title}>
            NEW NOTE
            <div className={styles.buttonContainer}>
              <span className={styles.blockContent}>
                <Info
                  className={styles.icon}
                  fill={team === "men" ? "#fff" : "#000"}
                />{" "}
              </span>
              <div className={styles.hoverText}>
                <p>
                  <span style={{ fontWeight: 600 }}> Subjective: </span>{" "}
                  Document how the patient is currently feeling in their
                  <br />
                  own words
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>Objective:</span> Document
                  your objective observations, which are <br />
                  things you can measure, see, hear, feel or smell.
                </p>

                <p>
                  <span style={{ fontWeight: 600 }}>Assessment: </span>Document
                  your thoughts on the salient issues and the
                  <br />
                  diagnosis (or differential diagnosis), which will be based on
                  the
                  <br />
                  information collected in the previous two sections.
                </p>

                <p>
                  <span style={{ fontWeight: 600 }}>Plan:</span> Document how
                  you are going to address or further investigate
                  <br />
                  any issues raised during the review.
                </p>
              </div>
            </div>
          </h3>

          <Close
            className={styles.closeButton}
            onClick={onClose}
            aria-label="Close"
            title="Close"
          />
        </div>

        <div className={styles.formContainer}>
          <form>
            <div className={styles.formGroup}>
              <textarea
                placeholder="Coach Comment"
                name="note"
                onChange={handleChange}
                className={styles.notes}
              ></textarea>
            </div>
          </form>
        </div>

        <div className={styles.footer}>
          <PrimaryButton
            className={styles.addButton}
            onClick={handleSubmitClick}
            title={"Save"}
          />
        </div>
      </div>
    </div>
  );
};
