export const overallWellnessValues: string[] = [
  "Horrible",
  "Extremely Poor",
  "Poor",
  "Bad",
  "Below Average",
  "Above Average",
  "Good",
  "Superior",
  "Extremely Superior",
];

export const fatigueWellnessValues: string[] = [
  "Extreme",
  "Very High",
  "High",
  "Average",
  "Low",
  "Very Low",
  "None",
];

export const stressWellnessValues: string[] = [
  "Extreme",
  "Very High",
  "High",
  "Average",
  "Low",
  "Very Low",
  "None",
];

export const moodWellnessValues: string[] = [
  "Horrible",
  "Poor",
  "Bad",
  "Normal",
  "Good",
  "Better",
  "Best",
];

export const sleepWellnessValues: string[] = [
  "Horrible",
  "Poor",
  "Bad",
  "Normal",
  "Good",
  "Better",
  "Best",
];
