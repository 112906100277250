import { GetChartMeta, SeriesValue } from "types";
import {
  calculateDateRange,
  formatDate,
  formatDateAPI,
  parseDate,
} from "utils";

import { APIChartResponse } from "api/chart";

import {
  COLOR_BLUE,
  COLOR_GREEN,
  COLOR_LIGHT_BLUE,
  COLOR_LIGHT_GREEN,
} from "components/charts/colors";

export const getChartMeta: GetChartMeta = (name, dateRange, athleteIds) => {
  const { startDate, endDate } = calculateDateRange(dateRange);
  return {
    name: "bodyComposition",
    api: {
      url: "/wellness/bodyComposition/:startDate/:endDate?&athleteId=:athleteId",
      params: {
        ":startDate": formatDateAPI(startDate),
        ":endDate": formatDateAPI(endDate),
        ":athleteId": athleteIds.join("&athleteIds="),
      },
    },
    charts: [
      {
        name: `${name}Points`,
        getLabels: (data: APIChartResponse) =>
          data?.points?.map((point) =>
            formatDate(parseDate(point.startDate)),
          ) ?? [],
        getValues: (data: APIChartResponse) => ({
          bodyMass:
            data?.points?.map((item) => ({ value: item.data.bodyMass })) ?? [],
          skinFold:
            data?.points?.map((item) => ({ value: item.data.skinFold })) ?? [],
          bodyFatPercent:
            data?.points?.map((item) => ({
              value: item.data.bodyFatPercent,
            })) ?? [],
        }),
      },
    ],
  };
};

export const getLineChart1Series = (
  athleteName: string,
  athleteValues: SeriesValue[],
  comparisonName: string,
  comparisonValues?: SeriesValue[],
) => {
  const series = [
    {
      name: athleteName,
      color: COLOR_GREEN,
      shadowColor: COLOR_LIGHT_GREEN,
      shadowBlur: 10,
      shadowOffsetY: 4,
      values: athleteValues,
    },
  ];
  if (comparisonValues) {
    series.push({
      name: comparisonName,
      color: COLOR_BLUE,
      shadowColor: COLOR_LIGHT_BLUE,
      shadowBlur: 14,
      shadowOffsetY: 8,
      values: comparisonValues,
    });
  }
  return series;
};

export const getLineChart2Series = (
  athleteName: string,
  athleteValues: SeriesValue[],
  comparisonName: string,
  comparisonValues?: SeriesValue[],
) => {
  const series = [
    {
      name: athleteName,
      color: "#FF4B55",
      shadowColor: "rgba(255, 75, 85, 0.6)",
      shadowBlur: 10,
      shadowOffsetY: 4,
      values: athleteValues,
    },
  ];
  if (comparisonValues) {
    series.push({
      name: comparisonName,
      color: COLOR_BLUE,
      shadowColor: "rgba(85, 161, 250, 0.6)",
      shadowBlur: 10,
      shadowOffsetY: 4,
      values: comparisonValues,
    });
  }
  return series;
};

export const getLineChart3Series = (
  athleteName: string,
  athleteValues: SeriesValue[],
  comparisonName: string,
  comparisonValues?: SeriesValue[],
) => {
  const series = [
    {
      name: athleteName,
      color: "#F8E084",
      shadowColor: "rgba(255, 227, 127, 0.5)",
      shadowBlur: 10,
      shadowOffsetY: 4,
      values: athleteValues,
    },
  ];
  if (comparisonValues) {
    series.push({
      name: comparisonName,
      color: COLOR_BLUE,
      shadowColor: "rgba(85, 161, 250, 0.6)",
      shadowBlur: 10,
      shadowOffsetY: 4,
      values: comparisonValues,
    });
  }
  return series;
};
