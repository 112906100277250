import s from "./index.module.css";
import { useCallback, useContext, useState } from "react";
import { FeedbackFormSlider } from "../FeedbackFormSlider";
import { X } from "lucide-react";
import { AthleteCardContext } from "../../../../context";
import { axiosInstance } from "../../../../../../axios-instance/axios-instance";
import {
  fatigueWellnessValues,
  moodWellnessValues,
  overallWellnessValues,
  sleepWellnessValues,
  stressWellnessValues,
} from "../../../../constants";
import { IFeedbackForm, IWellnessData } from "../../../../types";
import { Button, IconButton, Typography } from "../../../../../../storybook";
import { getUTCMidnight } from "../../../../../../utils/getUTCMidnight.ts";

type IProps = {
  onDismiss: () => void;
};

// TODO: This is temporary architecture for fast delivery

export type ISliderKeys = keyof Omit<
  IFeedbackForm,
  "athleteId" | "timeSinceEpoch" | "inputBy"
>;

export const FeedbackForm = ({ onDismiss }: IProps) => {
  const { athlete, setWellnessData } = useContext(AthleteCardContext);
  const [loading, setLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState<IFeedbackForm>({
    athleteId: athlete?.athleteId,
    timeSinceEpoch: (() => {
      return getUTCMidnight().getTime();
    })(),
    overall: overallWellnessValues[0],
    fatigue: fatigueWellnessValues[0],
    stress: stressWellnessValues[0],
    mood: moodWellnessValues[0],
    sleep: sleepWellnessValues[0],
    inputBy: 0,
  });

  const onChange = useCallback((name: ISliderKeys, value: string) => {
    setFormData((prev: IFeedbackForm) => {
      const next = { ...prev };
      next[name] = value;
      return next;
    });
  }, []);

  const handleSubmit = useCallback(() => {
    if (loading) {
      return;
    }
    setLoading(true);

    axiosInstance
      .post("/wellness/reportSelfWellness", formData)
      .then(() => {
        return axiosInstance.get<IWellnessData>(
          `/wellness/overall/lastUpdated?athleteId=${athlete?.athleteId}`,
        );
      })
      .then((response) => {
        setWellnessData(response.data);
        setLoading(false);
        onDismiss();
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, [loading, formData, athlete?.athleteId, setWellnessData, onDismiss]);

  return (
    <div className={s.form}>
      <header className={s.formHeader}>
        <Typography
          as="div"
          variant="headline"
          weight="bold"
          className={s.formHeaderTitle}
          text="Subjective Feedback"
        />

        <IconButton
          variant="quietNeutral"
          size="m"
          content={<X />}
          onClick={onDismiss}
        />
      </header>
      <FeedbackFormSlider
        question="How did you feel overall during the last (7 days) of training?"
        options={overallWellnessValues}
        name="overall"
        onChange={onChange}
      />

      <FeedbackFormSlider
        question="How Fatigued are your feeling after the last week?"
        options={fatigueWellnessValues}
        name="fatigue"
        onChange={onChange}
      />

      <FeedbackFormSlider
        question="How Stressed have been the last week?"
        options={stressWellnessValues}
        name="stress"
        onChange={onChange}
      />

      <FeedbackFormSlider
        question="How would you rate your mood the last week?"
        options={moodWellnessValues}
        name="mood"
        onChange={onChange}
      />

      <FeedbackFormSlider
        question="How would you rate your sleep quality and quantity the last week?"
        options={sleepWellnessValues}
        name="sleep"
        onChange={onChange}
      />

      <footer className={s.formFooter}>
        <Button
          content="Submit"
          fullWidth
          size="l"
          onClick={handleSubmit}
          loading={loading}
        />
      </footer>
    </div>
  );
};
