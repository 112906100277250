import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./group-modify.module.scss";
import { GroupSelectionEnum } from "pages/group-selection/enum";
import { useSelector } from "hooks/app-hooks";
import { getAthleteList } from "store/slices/shared";
import { ReactComponent as Checkbox } from "images/checkbox.svg";
import { ReactComponent as SelectedCheckbox } from "images/checkbox_selected.svg";
import clsx from "clsx";
import { Athlete, Group } from "types";
import { AthleteSearch } from "pages/athlete-selection/athlete-search/athlete-search";
import { countryCodesMapping } from "country-codes-mapping";
import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from "const";
import { PrimaryButton } from "components/primary-button/primary-button";
import { addGroup, updateGroup } from "api/groups";

type GroupModifyType = {
  groupSelectionView?: GroupSelectionEnum;
  setGroupSelectionView: (view: GroupSelectionEnum) => void;
  setShouldFetchAgain: (shouldFetchAgain: boolean) => void;
  group?: Group;
};

const GroupModify: React.FC<GroupModifyType> = ({
  setGroupSelectionView,
  setShouldFetchAgain,
  group,
}) => {
  const groupNameRef = useRef<HTMLInputElement>(null);
  const groupDescriptionRef = useRef<HTMLTextAreaElement>(null);
  const athleteList = useSelector(getAthleteList);
  const [selectedIds, setSelectedIds] = useState<number[] | null>(null);
  const [input, setInput] = useState<string>("");
  const [charCount, setCharCount] = useState(0);
  const [filteredAthletes, setFilteredAthletes] = useState(athleteList);

  useEffect(() => {
    if (group && group.athleteIds) {
      groupNameRef.current!.value = group.name;
      groupDescriptionRef.current!.value = group.description ?? "";
      setCharCount((group.description ?? "").length);
      setSelectedIds(group.athleteIds);
    }
  }, [group]);

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setCharCount(event.target.value.length);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const addNewGroup = useCallback(async (data: Group) => {
    return addGroup(data);
  }, []);

  const editGroup = useCallback(async (data: Group) => {
    return updateGroup(data);
  }, []);

  useEffect(() => {
    const regex = new RegExp(input, "i");
    const filtered = athleteList.filter(
      (athlete) =>
        regex.test(athlete.firstName) ||
        regex.test(athlete.lastName) ||
        regex.test(athlete.countryCode),
    );
    setFilteredAthletes(filtered);
  }, [input, athleteList]);

  const manageList = (id: number) => {
    if (selectedIds) {
      if (selectedIds.includes(id)) {
        const arr = selectedIds.filter((item) => item !== id);
        return setSelectedIds(arr);
      }
      return setSelectedIds([...selectedIds, id]);
    }
    return setSelectedIds([id]);
  };

  const handleCancelClick = () => {
    setSelectedIds([]);
    setGroupSelectionView(GroupSelectionEnum.SELECT_GROUP);
  };

  const handleSaveClick = async () => {
    if (selectedIds) {
      const newGroup: Group = {
        id: group?.id ?? 555,
        name: groupNameRef.current?.value || "No Group Name",
        athleteIds: selectedIds,
        description: groupDescriptionRef.current?.value || "No Description",
      };

      if (group) {
        await editGroup(newGroup);
      } else {
        await addNewGroup(newGroup);
      }
    }
    setSelectedIds([]);
    setShouldFetchAgain(true);
    setGroupSelectionView(GroupSelectionEnum.SELECT_GROUP);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.leftPanel}>
          <div className={styles.groupInfo}>
            <h1 className={styles.title}>Group Settings</h1>
          </div>
          <div className={styles.groupForm}>
            <div className={styles.formGroup}>
              <label htmlFor="groupName" className={styles.label}>
                Group Name
              </label>
              <input
                type="text"
                maxLength={20}
                className={styles.input}
                ref={groupNameRef}
                placeholder="Enter Group Name or Title"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="groupDescription" className={styles.label}>
                Description
              </label>
              <textarea
                className={styles.textarea}
                maxLength={999}
                ref={groupDescriptionRef}
                placeholder="Enter group description here"
                onChange={handleDescriptionChange}
              />
            </div>
            <div className={styles.assistiveText}>
              <span>Assistive text</span>
              <span>{`${charCount}/999`}</span>
            </div>
          </div>
        </div>
        <div className={styles.rightPanel}>
          <div className={styles.athleteList}>
            <div className={styles.athleteSearch}>
              <h1>Athlete List</h1>
              <AthleteSearch input={input} handleChange={handleSearchChange} />
            </div>
            <div className={styles.list}>
              {filteredAthletes.length > 0 ? (
                filteredAthletes.map((item: Athlete, i: number) => {
                  const countryAlpha2Code =
                    item?.countryCode && countryCodesMapping[item.countryCode]
                      ? countryCodesMapping[item?.countryCode]
                      : "AE";
                  const flagUrl = `${DEFAULT_CDN_URL}${countryAlpha2Code.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;
                  const ageCalc = (dateOfBirth: string) => {
                    const dob = new Date(dateOfBirth);
                    const diffMs = Date.now() - dob.getTime();
                    const ageDt = new Date(diffMs);
                    return Math.abs(ageDt.getUTCFullYear() - 1970);
                  };
                  return (
                    <div
                      className={clsx(
                        styles.item,
                        !(selectedIds && selectedIds.includes(item.id)) &&
                          styles.itemDisabled,
                      )}
                      key={i.toString()}
                      onClick={() => manageList(item.id)}
                    >
                      <button
                        type="button"
                        className={styles.checkbox}
                        onClick={() => manageList(item.id)}
                      >
                        {selectedIds && selectedIds.includes(item.id) ? (
                          <SelectedCheckbox />
                        ) : (
                          <Checkbox />
                        )}
                      </button>
                      <img
                        className={styles.itemImage}
                        src={item.picture}
                        alt="Profile"
                      />
                      <div className={styles.itemContent}>
                        <span className={styles.itemName}>
                          {item.firstName} {item.lastName}
                        </span>
                      </div>
                      <div className={styles.itemFlag}>
                        <img
                          className={styles.flagIcon}
                          src={flagUrl}
                          alt={item?.countryCode}
                          title={item?.countryCode}
                        />
                      </div>
                      <div className={styles.athleteInfo}>
                        <span>{ageCalc(item.dateOfBirth)} Yrs</span>
                      </div>
                      <div className={styles.athleteInfo}>
                        <span>H: {item.height || "--"}</span>
                      </div>
                      <div className={styles.athleteInfo}>
                        <span>H: {item.weight || "--"}</span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={styles.noData}>No Athletes Found</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <PrimaryButton
          className={styles.cancelButton}
          onClick={handleCancelClick}
          title="Cancel"
        />
        <PrimaryButton
          className={styles.saveButton}
          onClick={handleSaveClick}
          title="Save Changes"
        />
      </div>
    </div>
  );
};

export default GroupModify;
