import { FC, useEffect, useMemo } from "react";
import { DateRange } from "enums";

import { LineChartWithHeader } from "components/charts/line-chart/line-chart";
import { useDispatch, useSelector } from "hooks/app-hooks";
import {
  getCurrentAthlete,
  getCurrentComparison,
  getCurrentDateRange,
} from "store/slices/shared";
import {
  COLOR_BLUE,
  COLOR_BRIGHT_BLUE,
  COLOR_LIGHT_BLUE,
  COLOR_LIGHT_YELLOW,
  GRADIENT_AREA_BLUE,
  GRADIENT_AREA_WHITE,
} from "components/charts/colors";
import {
  calculateDateRange,
  formatDate,
  formatDateAPI,
  parseDate,
  stringifyDateRange,
} from "utils";
import { fetchChartData } from "store/slices/chart";
import { APIChartResponse } from "api/chart";

import { Loading } from "components/loading/loading";
import { ChartSize } from "components/charts/constants";
import { ChartMeta, SeriesValue } from "types";
import styles from "./heart-measurements.module.scss";
import { useAppDispatch } from "../../../store";

const yAxis = [
  { name: "HRV (ms)", yAxisIndex: 0 },
  { name: "RHR (bpm)", yAxisIndex: 1 },
];

const getChartMeta = (
  name: string,
  dateRange: DateRange,
  athleteId: number,
): ChartMeta<APIChartResponse> => {
  const { startDate, endDate } = calculateDateRange(dateRange);
  return {
    api: {
      url: "/wellness/heartRateMeasurement/:fromDate/:toDate?unit=:unit&athleteId=:athleteId",
      params: {
        ":unit": stringifyDateRange(dateRange),
        ":fromDate": formatDateAPI(startDate),
        ":toDate": formatDateAPI(endDate),
        ":athleteId": `${athleteId}`,
      },
    },
    charts: [
      {
        name: `${name}Variables`,
        getLabels: (data: APIChartResponse) =>
          data.points.map((point) => formatDate(parseDate(point.endDate))),
        getValues: (data: APIChartResponse) => ({
          hrv: data.points.map((item) => ({ value: item.data.hrv })),
          rhr: data.points.map((item) => ({ value: item.data.rhr })),
          average: [
            { value: data.average?.hrv || 0 },
            { value: data.average?.rhr || 0 },
          ],
        }),
        getSource: (data: APIChartResponse) => ({
          hrv: data.source?.hrv || "",
          rhr: data.source?.rhr || "",
        }),
      },
    ],
  };
};

const calculateMean = (series: SeriesValue[]): number => {
  const filteredSeries = series.filter((item) => item.value > 0);
  if (filteredSeries.length === 0) {
    return 0; // Handle the case where there are no valid values to calculate the mean
  }
  const sum: number = filteredSeries.reduce(
    (accumulator, currentValue) => accumulator + currentValue.value,
    0,
  );
  const mean: number = sum / filteredSeries.length;

  return mean;
};

export const HeartMeasurements: FC = () => {
  const dispatch = useAppDispatch();
  const currentAthlete = useSelector(getCurrentAthlete);
  const currentDateRange = useSelector(getCurrentDateRange);
  const currentComparison = useSelector(getCurrentComparison);
  const dateRange = useSelector(getCurrentDateRange);

  useEffect(() => {
    const today = new Date();
    if (currentAthlete) {
      dispatch(
        fetchChartData<APIChartResponse>(
          getChartMeta(
            "heartRateMeasurement",
            currentDateRange,
            currentAthlete.id,
          ),
        ),
      );
    }
  }, [dispatch, currentDateRange, currentAthlete]);

  const heartRateMeasurementVariables = useSelector((state) => {
    return state.chart.heartRateMeasurementVariables;
  });
  const heartRateMeasurementComparisonVariables = useSelector((state) => {
    return state.chart.heartRateMeasurementComparisonVariables;
  });

  const series = useMemo(() => {
    const validHRVLength = Math.max(
      (heartRateMeasurementVariables?.values.hrv?.length ?? 0) - 2,
      0,
    );
    const validRHRLength = Math.max(
      (heartRateMeasurementVariables?.values.rhr?.length ?? 0) - 2,
      0,
    );

    return [
      {
        yAxisIndex: 0,
        width: 2,
        name: "HRV",
        color: COLOR_BRIGHT_BLUE,
        areaColor: GRADIENT_AREA_BLUE,
        values: heartRateMeasurementVariables?.values.hrv || [],
      },
      {
        yAxisIndex: 0,
        width: 1,
        z: -1,
        name: "Mean HRV",
        color: COLOR_LIGHT_YELLOW,
        emphasisDisabled: true,
        values:
          heartRateMeasurementVariables?.values?.hrv?.length > 0
            ? [
                {
                  value: calculateMean(
                    heartRateMeasurementVariables?.values.hrv,
                  ),
                },
                ...Array.from(Array(validHRVLength), () => ({
                  value: 0,
                })),
                {
                  value: calculateMean(
                    heartRateMeasurementVariables?.values.hrv,
                  ),
                },
              ]
            : [],
      },
      {
        yAxisIndex: 1,
        name: "RHR",
        width: 2,
        color: COLOR_LIGHT_BLUE,
        areaColor: GRADIENT_AREA_WHITE,
        values: heartRateMeasurementVariables?.values.rhr || [],
      },
      {
        yAxisIndex: 1,
        width: 1,
        z: -1,
        name: "Mean RHR",
        emphasisDisabled: true,
        color: COLOR_BLUE,
        values:
          heartRateMeasurementVariables?.values?.rhr?.length > 0
            ? [
                {
                  value: calculateMean(
                    heartRateMeasurementVariables?.values.rhr,
                  ),
                },
                ...Array.from(Array(validRHRLength), () => ({
                  value: 0,
                })),
                {
                  value: calculateMean(
                    heartRateMeasurementVariables?.values.rhr,
                  ),
                },
              ]
            : [],
      },
    ];
  }, [heartRateMeasurementVariables]);

  if (
    !heartRateMeasurementVariables?.loaded ||
    (currentComparison && !heartRateMeasurementComparisonVariables?.loaded)
  ) {
    return (
      <div className={styles.container}>
        <div className={styles.chart}>
          <Loading />
        </div>
      </div>
    );
  }

  const { labels } = heartRateMeasurementVariables;

  return (
    <div className={styles.container} data-testid="heart-rate-variability">
      <div className={styles.chart}>
        <LineChartWithHeader
          type={ChartSize.Big}
          name="Heart Rate Variability"
          source="TP"
          series={series}
          labels={labels}
          yAxis={yAxis}
          titlePrefix={dateRange >= DateRange.Year ? "Week of" : undefined}
        />
      </div>
    </div>
  );
};
