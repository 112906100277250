import { FC, useState } from "react";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";

import styles from "./input-popup.module.scss";
import { PrimaryButton } from "components/primary-button/primary-button";
import {
  ReusableInputField,
  ReusableInputFieldProps,
} from "../anthropometry-popup/resusable-input-field/resusable-input-field";
import {
  ErrorsState,
  FormState,
} from "../anthropometry-popup/anthropometry-popup";
import { postData } from "./api";
import { useSelector } from "hooks/app-hooks";
import { getCurrentAthlete } from "store/slices/shared";
import { formatDatePower } from "utils";
import { getIsCyclist } from "store/slices/auth";
import CountdownTimer from "../success-countdown";
import { trackError } from "appInsights/logInsights";
import { Athlete } from "types";

type Props = {
  onClose: () => void;
  endpoint: string;
  title: string;
  IconData: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  keyName: string;
  inputLabel: string;
  dataField: string;
  submitDate?: boolean;
  athlete?: Athlete;
};

export const InputPopup: FC<Props> = ({
  onClose,
  endpoint,
  title,
  IconData,
  keyName,
  inputLabel,
  dataField,
  submitDate = false,
  athlete,
}) => {
  const currentAthleteRedux = useSelector(getCurrentAthlete);
  const currentAthlete = athlete || currentAthleteRedux;
  const isCyclist: boolean = useSelector(getIsCyclist);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const fieldProperties: ReusableInputFieldProps[] = [
    { name: keyName, label: inputLabel },
  ];
  const [form, setForm] = useState<FormState>({
    [keyName]: "",
  });
  const [errors, setErrors] = useState<ErrorsState>({
    [keyName]: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errorMessage) {
      setErrorMessage(null);
    }
    const { name, value } = e.target;
    const numericValue = parseFloat(value);
    const isValid = numericValue >= 0;

    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: !isValid });
  };

  const handleSubmitClick = async (): Promise<void> => {
    try {
      const response = await postData(
        currentAthlete?.id || 0,
        { [dataField]: parseFloat(form[keyName]) },
        isCyclist ? 0 : 1,
        submitDate ? formatDatePower(new Date()) : null,
        endpoint,
        athlete
          ? `/athletes/${athlete.id}/weight?weight=${parseFloat(
              form[keyName],
            )}&date=${formatDatePower(new Date())}`
          : undefined,
      );

      if (response === null) {
        console.error("Request failed:", response);
        setErrorMessage(`Failed to save data`);
        return;
      }

      setSuccess(true);
    } catch (error) {
      trackError(error as Error, { location: "InputPopup" });
      setErrorMessage(`Failed to save data`);
    }
  };

  return (
    <div className={clsx(styles.popupBox, "comparison-dropdown")}>
      <div
        className={clsx(styles.inputBox, styles.box)}
        data-testid="add-comparison-list"
      >
        <div>
          <div className={styles.header}>
            <h3 className={styles.title}>{title}</h3>
            <Close
              className={styles.closeButton}
              onClick={onClose}
              aria-label="Close"
              title="Close"
            />
          </div>
          {success ? (
            <div className={styles.countDown}>
              <CountdownTimer duration={3} onComplete={onClose} />
            </div>
          ) : (
            <div className={styles.container}>
              <IconData className={styles.svgIcon} />
              <form className={styles.form} data-testid="change-password-form">
                {fieldProperties.map(({ name, label }) => (
                  <ReusableInputField
                    key={name}
                    name={name}
                    label={label}
                    value={form[name as keyof FormState]}
                    onChange={handleInputChange}
                    error={errors[name as keyof ErrorsState]}
                  />
                ))}
              </form>
            </div>
          )}
          {errorMessage && (
            <div className={styles.errorMessage}>{errorMessage}</div>
          )}
          <div className={styles.footer}>
            <PrimaryButton
              disabled={
                Object.values(errors).some((error) => error) ||
                !form[keyName] ||
                success
              }
              className={styles.addButton}
              onClick={handleSubmitClick}
              title={"Submit"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
