export const isDateInRange = (value: Date, range: Date[]): boolean => {
  const [start, end] = range;

  let from;
  let to;

  if (start < end) {
    from = start;
    to = end;
  } else {
    from = end;
    to = start;
  }

  return value >= from && value <= to;
};
