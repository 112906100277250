import { useEffect, useState } from "react";

import styles from "./health-attachment.module.scss";
import { PrimaryButton } from "components/primary-button/primary-button";
import { DropFilePopup } from "components/drop-file-popup/drop-file-popup";
import { DropFiles } from "components/drop-files/drop-files";
import { ReactComponent as Download } from "images/download.svg";
import { ReactComponent as File } from "images/file.svg";
import { ReactComponent as Delete } from "images/trash.svg";
import {
  deleteFile,
  downloadFile,
  getFiles,
  uploadFile,
} from "api/medical-report";
import { useSelector } from "react-redux";

export enum Tabs {
  OverallWellnessTab,
  BodyCompositionTab,
  HeartMeasurementsTab,
  TrainingTab,
  PowerDerivativesTab,
  FatigueResistanceTab,
  FitnessFatigueTab,
  MenstrualCycleTab,
  TemperatureChartTab,
}

type FileType = {
  name: string;
  size: string;
  file: File;
};

export const HealthAttachment = () => {
  const [selectedFile, setSelectedFile] = useState<FileType[]>([]);
  const [files, setFiles] = useState<FileType[]>([]);
  const [isResponseSuccess, setIsResponseSuccess] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const selectedMedicalReportId = useSelector(
    (state: any) => state.health.selectedMedicalReportId,
  );
  const selectedMedicalReport = useSelector(
    (state: any) => state.health.selectedMedicalReport,
  );

  const handleClick = () => {
    setShowPopup(true);
  };

  // api call to upload files
  useEffect(() => {
    (async () => {
      setIsResponseSuccess(false);
      if (selectedFile?.length > 0) {
        const formData = new FormData();
        selectedFile.forEach((fileObj) => {
          formData.append("multipartFile", fileObj.file);
        });
        formData.append("medicalReportId", selectedMedicalReportId);
        const response = await uploadFile(formData);
        if (response?.id) {
          setIsResponseSuccess(true);
        }
        setSelectedFile([]);
      }
    })();
  }, [selectedFile, selectedMedicalReportId]);

  // api call to get the files
  useEffect(() => {
    (async () => {
      const response = await getFiles(selectedMedicalReportId);
      setFiles(response);
    })();
  }, [selectedMedicalReportId, isResponseSuccess]);

  // api call to delete file
  const onDeleteClick = async (id: number) => {
    setIsResponseSuccess(false);
    await deleteFile(id);
    setIsResponseSuccess(true);
  };

  // api call to download file
  const onDownloadClick = async (id: number, name: string) => {
    const blob = await downloadFile(id);
    const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  };

  return (
    <>
      <div className={styles.healthTab}>
        <div className={styles.header}>
          <div className={styles.title}>Attachments</div>
          {files?.length > 0 && (
            <div className={styles.button}>
              <PrimaryButton
                className={styles.newNote}
                onClick={handleClick}
                title={"New Attachment"}
              />
            </div>
          )}
        </div>
        {showPopup && (
          <DropFilePopup
            onClose={() => setShowPopup(false)}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            showPopup={showPopup}
          />
        )}

        {files?.length <= 0 && (
          <DropFiles
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        )}

        {files?.length > 0 && (
          <>
            <div className={styles.attachmentWrapper}>
              {files?.map((file: any, i: number) => {
                return (
                  <div key={i} className={styles.attachFiles}>
                    <p className={styles.attachFileName}>
                      <File className={styles.iconAttach} />
                      {file.fileName.length > 25
                        ? `${file.fileName.slice(0, 12)}...`
                        : file.fileName}
                    </p>
                    <div className={styles.attachFileSize}>
                      <span className={styles.attachFilesHeading}>Size</span>
                      <p className={styles.attachFilesData}>{file.fileSize}</p>
                    </div>
                    <div className={styles.attachFileByName}>
                      <span className={styles.attachFilesHeading}>By</span>
                      <p className={styles.attachFilesData}>
                        {selectedMedicalReport?.createdBy}
                      </p>
                    </div>
                    <div className={styles.attachFileBtns}>
                      <Delete
                        className={styles.iconAttach}
                        onClick={() => onDeleteClick(file?.id)}
                      />{" "}
                      <Download
                        className={styles.iconAttach}
                        onClick={() =>
                          onDownloadClick(file?.id, file?.fileName)
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};
