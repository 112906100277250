import { FC } from "react";
import styles from "./team-dashboard-header.module.scss";
import { CoachInfo } from "components/coach-info/coach-info";

export const TeamDashboardHeader: FC = () => {
  return (
    <div className={styles.teamSelectionHeader}>
      <div className={styles.teamLeft}>
        <span className={styles.teamText}>Team Dashboard</span>
      </div>
      <CoachInfo className={styles.coachButton} />
    </div>
  );
};
