import React, { FC, useEffect } from "react";

import { DateGroupBy, DateRange } from "enums";
import { Athlete, ChartMeta } from "types";
import {
  calculateDateRange,
  formatDateAPI,
  sortDataBasedOnAthleteOrder,
  stringifyDateRange,
} from "utils";
import { useDispatch, useSelector } from "hooks/app-hooks";
import {
  getAthleteList,
  getCurrentDateRange,
  getCurrentGroupBy,
  getSelectedGroup,
} from "store/slices/shared";
import { fetchChartData } from "store/slices/chart";
import { APIWorkoutDetailsChartResponse } from "api/chart";

import { Measurements } from "components/measurements/measurements";
import { useAppDispatch } from "../../../store";

const getChartMeta = (
  name: string,
  dateRange: DateRange,
  athleteIds: number[],
  athleteList: Athlete[],
  currentDateUnit?: DateGroupBy,
): ChartMeta<APIWorkoutDetailsChartResponse[]> => {
  const { startDate, endDate } = calculateDateRange(dateRange);
  return {
    api: {
      url: "/performance/workoutDetails/:fromDate/:toDate?unit=:unit&athleteIds=:athleteIds&aggregatePerAthlete=true",
      params: {
        ":unit": currentDateUnit ?? stringifyDateRange(dateRange),
        ":fromDate": formatDateAPI(startDate),
        ":toDate": formatDateAPI(endDate),
        ":athleteIds": athleteIds.join("&athleteIds="),
      },
    },
    charts: [
      {
        name: `${name}TrainingVariables`,
        getLabels: (data: APIWorkoutDetailsChartResponse[]) => {
          const sortedData =
            sortDataBasedOnAthleteOrder(data, athleteList) || data;
          return sortedData?.map((item) => {
            const athleteName = athleteList.find(
              (athlete) => athlete.id === item?.athleteId,
            );
            return `${athleteName?.firstName ?? ""} ${athleteName?.lastName ?? ""}`.trim();
          });
        },
        getValues: (data: APIWorkoutDetailsChartResponse[]) => {
          const sortedData =
            sortDataBasedOnAthleteOrder(data, athleteList) || data;
          return {
            climbInMeter: sortedData?.map((item) => ({
              value: item?.climbInMeter,
            })),
            distanceInKm: sortedData?.map((item) => ({
              value: item?.distanceInKm,
            })),
            durationInHour: sortedData?.map((item) => ({
              value: item?.durationInHour,
            })),
            intensityFactor: sortedData?.map((item) => ({
              value: item?.intensityFactor,
            })),
            tss: sortedData?.map((item) => ({ value: item?.tss })),
            workInKj: sortedData?.map((item) => ({ value: item?.workInKj })),
          };
        },
      },
    ],
  };
};

export const TeamWorkoutDetails: FC = () => {
  const dispatch = useAppDispatch();
  const currentDateRange = useSelector(getCurrentDateRange);
  const currentDateUnit = useSelector(getCurrentGroupBy);
  const athleteList = useSelector(getAthleteList);
  const currentGroup = useSelector(getSelectedGroup);

  useEffect(() => {
    const today = new Date();
    if (currentGroup && currentGroup.athleteIds.length > 0) {
      dispatch(
        fetchChartData<APIWorkoutDetailsChartResponse[]>(
          getChartMeta(
            "athlete",
            currentDateRange,
            currentGroup.athleteIds,
            athleteList,
            currentDateUnit,
          ),
        ),
      );
    }
  }, [dispatch, currentDateRange, currentDateUnit, athleteList, currentGroup]);

  const trainingVariables = useSelector(
    (state) => state.chart.athleteTrainingVariables,
  );

  return (
    <Measurements
      addBorder={false}
      data={trainingVariables}
      formatLabels={false}
      showToolTipTitle={false}
      showGroupBySelector={false}
    />
  );
};
