import React, { FC } from "react";
import clsx from "clsx";

import { Tab } from "components/side-tabs/side-tabs";

import { ReactComponent as RemoveIcon } from "images/remove.svg";
import { ReactComponent as AddIcon } from "images/add.svg";

import styles from "./side-tab.module.scss";

type Props = {
  active: boolean;
  tab: Tab;
  onClick: (tabId: number) => void;
};

export const SideTab: FC<Props> = ({ tab, active, onClick }) => {
  return (
    <button
      key={tab.id}
      type="button"
      className={clsx(styles.tab, active && styles.tabActive)}
      onClick={() => onClick(tab.id)}
    >
      {tab.name}
      {active && <RemoveIcon className={styles.icon} />}
      {!active && <AddIcon className={styles.icon} />}
    </button>
  );
};
