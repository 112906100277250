import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as BackNavIcon } from "images/back_nav_icon.svg";
import styles from "./header-with-back-button.module.scss";

interface Props {
  backNavLocation: string;
  title: string;
}

const HeaderWithBackButton: React.FC<Props> = (props) => {
  // Component logic goes here
  const history = useHistory();

  const handleBackNav = async () => {
    history.push(props.backNavLocation);
  };

  return (
    // JSX markup goes here
    <div className={styles.athleteGroup}>
      <BackNavIcon className={styles.backNav} onClick={handleBackNav} />
      <div className={styles.headerTitle}>{props.title}</div>
    </div>
  );
};

export default HeaderWithBackButton;
