import { FC } from "react";
import styles from "./notify-reporting.module.scss";
import { PrimaryButton } from "components/primary-button/primary-button";
import { getCoachProfile } from "store/slices/shared";
import { ReactComponent as Alert } from "images/alert.svg";
import { useSelector } from "react-redux";
import { WeeklyReport } from "types";

type Props = {
  handleStartReporting: () => void;
};

const NotifyReporting: FC<Props> = ({ handleStartReporting }) => {
  const coachProfile = useSelector(getCoachProfile);

  return (
    <div className={styles.topWrapper}>
      <div className={styles.systemalertLineswapParent}>
        <Alert className={styles.alertIcon} />
        <div className={styles.settings}>
          <span
            className={styles.heyKevin}
          >{`Hey ${coachProfile?.firstName}, `}</span>
          <span className={styles.justAReminder}>
            Just a reminder, we need your input for the weekly report on your
            athletes.
          </span>
        </div>
      </div>
      <PrimaryButton
        className={styles.reportButton}
        onClick={handleStartReporting}
        title={"Start Reporting"}
      />
    </div>
  );
};

export default NotifyReporting;
