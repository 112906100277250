import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { setAccount, setAuthState } from "store/slices/auth";

import { ReactComponent as SignOutIcon } from "images/sign-out.svg";

import styles from "./sign-out.module.scss";
import { RoleType } from "enums";
import { useAppDispatch } from "../../store";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

type Props = {
  className?: string;
};

export const SignOut: FC<Props> = ({ className }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { instance } = useMsal();
  const authenticatedMs = useIsAuthenticated();

  const onLogout = () => {
    dispatch(setAuthState(false));
    dispatch(setAccount(RoleType.cyclist));
    history.push("/sign-in");
  };

  const handleClick = async () => {
    if (authenticatedMs) {
      instance.logoutRedirect().then(onLogout);
    } else {
      onLogout();
    }
  };

  return (
    <button
      type="button"
      className={clsx(styles.button, className)}
      onClick={handleClick}
    >
      <SignOutIcon />
      <span className={styles.text}>Sign out</span>
    </button>
  );
};
