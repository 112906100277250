import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
    autoTrackPageVisitTime: true,
    maxBatchInterval: 5000,
    disableCookiesUsage: true,
    enableAutoRouteTracking: true,
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();

export { appInsights };
