import React, { SetStateAction, useMemo, useRef } from "react";
import ReactEcharts from "echarts-for-react";

import { formatTime, pdFormatTime, roundFloat } from "utils";
import { ChartColor, ChartProps, SeriesItem } from "components/charts/types";
import {
  COLOR_AXIS,
  COLOR_TOOLTIP,
  COLOR_WHITE,
  MARKLINE_COLOR,
} from "components/charts/colors";
import {
  ChartSize,
  FONT_SIZE_XSS,
  GradientDirection,
  LineMarkType,
} from "components/charts/constants";
import { ReactComponent as CircleDashedIcon } from "images/circle-dashed.svg";
import {
  DEFAULT_CHART_X_AXIS_PHYS,
  DEFAULT_CHART_Y_AXIS_PHYS,
} from "components/charts/defaults";
import {
  getAxisLabelStyle,
  toEChartsColor,
  toEChartsTextStyle,
} from "components/charts/utils";
import { NoData } from "components/charts/no-data/no-data";

import { withHeader } from "hocs/with-header/with-header";

import styles from "./scatter-chart.module.scss";
import ReactDOMServer from "react-dom/server";

export type LineChartTimeSeries = SeriesItem<{
  areaColor?: ChartColor;
  opacity?: number;
  z?: number;
  emphasisDisabled?: boolean;
  shadowColor?: ChartColor;
  shadowBlur?: number;
  chartType?: string;
  shadowOffsetY?: number;
  width?: number;
  yAxisIndex?: number;
  smooth?: boolean;
  dashed?: boolean;
  seriesName?: string;
  units?: string;
}>;

export type LineChartTimeProps = ChartProps<{
  type?: ChartSize;
  units?: string;
  markLineYAt?: number;
  markLineYLabel?: string;
  yAxis?: { name?: string; type?: string; min?: number }[];
  series: (LineChartTimeSeries | undefined)[];
  titlePrefix?: string;
  isTooltipAxisLogarithmic?: boolean;
  title?: string;
  subtitle?: string;
  rotateXAxisLabels?: boolean;
  bottomMargin?: number;
  rightMargin?: number;
  containLabels?: boolean;
  intervals?: number;
  yLabelMargins?: number;
  colorsList?: string[];
  isTablet?: boolean;
  barMaxWidth?: string;
  showYAxisName?: boolean;
  showNoDataComp?: boolean;
  hideSeries?: { [key: string]: { hidden?: boolean; count?: number } };
  setHideSeries?: React.Dispatch<
    React.SetStateAction<{
      [key: string]: { hidden?: boolean; count?: number };
    }>
  >;
  setMarkLineAt?: React.Dispatch<SetStateAction<string | null>>;
  markLineAt?: string | null;
  setMarkLineText?: React.Dispatch<SetStateAction<LineMarkType | null>>;
  markLineText?: LineMarkType | null;
}>;

interface TooltipValue {
  $vars: string[];
  axisDim: string;
  axisId: string;
  axisIndex: number;
  axisType: string;
  axisValue: string;
  axisValueLabel: string;
  borderColor?: string;
  color: string;
  componentIndex: 0;
  componentSubType: string;
  componentType: string;
  data: {
    value: any[];
    itemStyle: string | null;
    label?: string;
    units?: string;
    fixedNumber?: number;
  };
  dataIndex: 1;
  dataType: undefined;
  dimensionNames: string[];
  encode: { x: number[]; y: number[] };
  marker: string;
  name: string;
  seriesId: string;
  seriesIndex: string;
  seriesName: string;
  seriesType: string;
  value: string[];
}

const getGridRightPadding = (type: ChartSize) =>
  type === ChartSize.Big ? 42 : 32;

const findDashedLegend = (
  name: string,
  filteredSeries: LineChartTimeSeries[],
) => {
  const data = filteredSeries.find((item) => item.name === name);
  if (data) {
    return !!data.dashed;
  }
  return false;
};

export const LineChartTime = React.forwardRef<ReactEcharts, LineChartTimeProps>(
  (
    {
      type = ChartSize.Small,
      series,
      labels,
      yAxis,
      markLineAt,
      setMarkLineAt,
      markLineText,
      setMarkLineText,
      markLineYAt,
      markLineYLabel,
      titlePrefix,
      isTooltipAxisLogarithmic,
      title,
      rotateXAxisLabels,
      bottomMargin = 42,
      rightMargin = 32,
      containLabels = false,
      colorsList,
      isTablet,
      barMaxWidth,
      yLabelMargins,
      subtitle,
      showYAxisName = true,
      showNoDataComp = true,
      hideSeries = {},
      setHideSeries,
      onChartReady,
    },
    ref,
  ) => {
    const filteredSeries: LineChartTimeSeries[] = series
      .filter((el) => el !== undefined)
      .map((el) => el as LineChartTimeSeries);

    const multipleValues = filteredSeries
      .flatMap((el) => el.multipleValues?.values)
      .filter((el) => el);

    const hasData = useMemo(
      () =>
        filteredSeries.some((seriesItem) => {
          return seriesItem.values.filter((item) => item?.value).length > 0;
        }),
      [filteredSeries],
    );

    const axisLabelFormatter = useMemo(() => {
      const pdDisplayValues = [
        1, 2, 5, 10, 20, 40, 60, 120, 300, 600, 1200, 2400, 3600, 7200, 14400,
        21600,
      ];
      const displayedLabels = new Set();

      return (value: number, index: number) => {
        if (index === 1) {
          displayedLabels.add(1);
          return pdFormatTime(1);
        }
        const parsedValue = Math.pow(
          2,
          labels !== null ? value : (labels[index] as number),
        );
        const tolerance = 0.01 * parsedValue;

        for (const val of pdDisplayValues) {
          if (
            Math.abs(parsedValue - val) <= tolerance &&
            !displayedLabels.has(val)
          ) {
            displayedLabels.add(val);
            return pdFormatTime(val);
          }
        }
        return "";
      };
    }, [labels]);

    const renderIcon = (element: TooltipValue) => {
      if (findDashedLegend(element.seriesName, filteredSeries)) {
        return ReactDOMServer.renderToStaticMarkup(
          <span className={styles.tooltipIcon}>
            <CircleDashedIcon style={{ stroke: element.color || "white" }} />
          </span>,
        );
      }
      return element.marker;
    };

    const customTooltipFormatter = (value: TooltipValue[]) => {
      const axisValue = Math.pow(2, Number(value[0].axisValue));
      const parsedAxisValue = Number(axisValue.toFixed(0));

      const pdDisplayValues = [
        1, 2, 5, 10, 20, 40, 60, 120, 300, 600, 1200, 2400, 3600, 7200, 14400,
        21600,
      ];
      const tolerance = 0.004 * parsedAxisValue;

      let formattedTime = formatTime(parsedAxisValue);
      for (const val of pdDisplayValues) {
        if (Math.abs(parsedAxisValue - val) <= tolerance) {
          formattedTime = pdFormatTime(val);
          break;
        }
      }

      return `
        <div class="${styles.singleTooltip}">
          ${"Time"}: ${formattedTime}
          ${value
            .map((element: TooltipValue) => {
              const fixedNumber =
                typeof element.data.fixedNumber === "number"
                  ? element.data.fixedNumber
                  : 0;

              let displayValue =
                element?.data?.value && element.data.value.length > 1
                  ? parseFloat(element.data.value[1])
                  : 0;
              if (
                isTooltipAxisLogarithmic &&
                (element.seriesName === "Fitness" ||
                  element.seriesName === "Fatigue")
              ) {
                displayValue = Math.pow(2, displayValue);
              }

              return `
                <div class="${styles.tooltip}">
                  <div>${renderIcon(element)}${element.seriesName}:</div>
                  <div class="${styles.tooltipValue}">
                    ${displayValue.toFixed(fixedNumber)} ${element.data.units || ""}
                  </div>
                </div>`;
            })
            .join("")}
        </div>`;
    };

    const customMutliValueTooltipFormatter = (value: TooltipValue[]) => {
      return `
      <div class="${styles.tooltipWrapper}">
        <div class="${styles.tooltipTitle}">${titlePrefix || ""} ${value[0].axisValueLabel}</div>
        ${value
          .map((element: TooltipValue) => {
            const multipleValuePairs = multipleValues[element.dataIndex];

            const multipleValuesHtml = multipleValuePairs
              ? multipleValuePairs
                  .map(
                    (pair) => `
              <div class="${styles.tooltipPair}">
              
                <span class="${styles.tooltipPairLine}">${pair.label}: ${pair.value}</span>
              </div>
            `,
                  )
                  .join("")
              : "";

            return `
              <div class="${styles.multiValueTooltip}">
                
                ${multipleValuesHtml} 
              </div>`;
          })
          .join("")}</div>`;
    };

    const onEvents = useMemo(() => {
      return {
        click: (params: any) => {
          if (setMarkLineAt) {
            setMarkLineAt(params.value[0]);
          }
        },
        legendselectchanged: (params: any) => {
          if (setHideSeries) {
            setHideSeries((prev) => {
              const seriesName = params.name;
              const prevCount = prev[seriesName]?.count || 0;
              const newCount = prevCount + 1;
              const isHidden = newCount % 2 !== 0;

              const newState: {
                [key: string]: {
                  hidden: boolean;
                  count: number;
                };
              } = {
                ...prev,
                [seriesName]: { hidden: isHidden, count: newCount },
              };

              const seriesNames = Object.keys(newState);
              const hiddenSeries = seriesNames.filter(
                (itemName) => newState[itemName].hidden,
              );

              if (hiddenSeries.length === seriesNames.length) {
                const otherSeriesName = seriesNames.find(
                  (itemName) => itemName !== seriesName,
                );
                if (otherSeriesName) {
                  newState[otherSeriesName] = {
                    hidden: false,
                    count: newState[otherSeriesName].count + 1,
                  };
                }
              }

              return newState;
            });
          }
        },
      };
    }, [setMarkLineAt, setHideSeries]);

    const createMarkLineConfig = (markLineXAt: any) => {
      if (!markLineXAt && !markLineYAt) return undefined;

      const markLineData = [];

      if (markLineXAt) {
        markLineData.push({
          type: "line",
          xAxis: markLineXAt,
          label: {
            padding: 5,
            position: "end",
            formatter: (params: any) => {
              return markLineText?.value || "";
            },
            align: markLineText?.align || null,
            rich: {
              time: {
                color: "black",
                lineHeight: 20,
              },
              item: {
                color: "black",
                lineHeight: 20,
              },
            },
          },
        });
      }

      if (markLineYAt) {
        markLineData.push({
          type: "line",
          yAxis: markLineYAt,
          symbol: "none",
          label: {
            formatter: `${markLineYLabel}: {c}`,
            position: "insideEndTop",
          },
        });
      }

      return {
        symbol: ["none", "none"],
        silent: true,
        animation: false,
        lineStyle: {
          color: MARKLINE_COLOR,
          type: "dashed",
          symbol: "none",
        },
        label: {
          show: true,
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          borderRadius: 5,
          borderColor: MARKLINE_COLOR,
          padding: 5,
          color: "black",
          borderWidth: 1,
        },
        data: markLineData,
      };
    };

    return (
      <div className={styles.wrapper}>
        {showNoDataComp && !hasData && <NoData chartSize={type} />}
        <ReactEcharts
          key={Object.values(hideSeries)
            .map((hs) => hs.hidden)
            .join(",")}
          ref={ref}
          onChartReady={onChartReady}
          opts={{ renderer: "canvas" }}
          style={{ width: "100%", height: "100%" }}
          onEvents={onEvents}
          notMerge
          option={{
            title: {
              text: title,
              subtext: subtitle,
              left: "center",
              top: "top",
              itemGap: 280,
              padding: [15, 10],
              textStyle: {
                color: "#12BE50",
                fontSize: 12,
              },
              subtextStyle: {
                fontSize: 12,
                color: "#12BE50",
              },
              show: true,
            },
            tooltip: {
              trigger: hasData && "axis",
              backgroundColor: COLOR_TOOLTIP,
              borderColor: COLOR_TOOLTIP,
              textStyle: {
                color: COLOR_WHITE,
                fontSize: 12,
                fontFamily: "Montserrat",
              },
              formatter:
                multipleValues.length <= 0
                  ? customTooltipFormatter
                  : customMutliValueTooltipFormatter,
            },
            legend: {
              show: false,
            },
            grid: {
              top: yAxis ? 27 : markLineAt ? "20%" : 8,
              left: 58,
              bottom: type === ChartSize.Big ? bottomMargin : 25,
              right: yAxis ? getGridRightPadding(type) : rightMargin,
              containLabel: containLabels,
            },
            xAxis: {
              ...DEFAULT_CHART_X_AXIS_PHYS,
              type: "value",
              min: 0,
              max: labels?.length ? labels[labels.length - 1] : 1,
              splitNumber:
                labels?.length && labels?.length < 7200 ? labels?.length : 7200,
              alignTicks: true,
              boundaryGap: false,
              axisLabel: {
                ...toEChartsTextStyle(
                  {},
                  {
                    color: COLOR_AXIS,
                    size: type === ChartSize.Big ? (isTablet ? 8 : 13) : 8,
                    fontFamily: "Montserrat",
                  },
                ),
                hideOverlap: true,
                rotate: rotateXAxisLabels ? 45 : null,
                margin: 13,
                formatter: axisLabelFormatter,
              },
              splitLine: {
                lineStyle: {
                  type: "dashed",
                  color: "rgba(84, 93, 118, 0.8)",
                },
                show: false,
              },
            },
            yAxis: (yAxis || [{}]).map((axis, index) => {
              return {
                ...DEFAULT_CHART_Y_AXIS_PHYS,
                type: "value",
                name: showYAxisName && axis.name,
                boundaryGap: false,
                nameTextStyle: {
                  ...toEChartsTextStyle(
                    {},
                    {
                      color: COLOR_AXIS,
                      fontWeight: "normal",
                      size: FONT_SIZE_XSS,
                      fontFamily: "Montserrat",
                    },
                  ),
                  align: index > 0 ? "right" : "left",
                  margin: -30,
                },
                axisLabel: {
                  fontWeight: 500,
                  size: 10,
                  fontFamily: "Montserrat",
                  formatter(value: string) {
                    if (axis.name === "Percentage" && parseFloat(value) <= 1) {
                      return `${parseFloat(value) * 100} `;
                    }
                    return `${value} `;
                  },
                },
                ...getAxisLabelStyle(type, yLabelMargins),
                splitLine: {
                  ...DEFAULT_CHART_Y_AXIS_PHYS.splitLine,
                  lineStyle: {
                    type: "dashed",
                    color: "rgba(84, 93, 118, 0.3)",
                  },
                  show: hasData && index === 0,
                },
              };
            }),
            series: filteredSeries.map((seriesItem) => {
              const isHidden = hideSeries?.[seriesItem.name]?.hidden;

              return {
                animation: false,
                markLine: createMarkLineConfig(markLineAt),
                yAxisIndex: seriesItem.yAxisIndex,
                triggerLineEvent: true,
                type: seriesItem.chartType,
                smooth: false,
                z: seriesItem.z,
                itemStyle: {
                  color: seriesItem.color,
                },
                tooltip: {
                  show: true,
                },
                name: seriesItem.name,
                areaStyle: seriesItem.areaColor
                  ? {
                      color: toEChartsColor(
                        seriesItem.areaColor,
                        GradientDirection.TopToBottom,
                      ),
                      opacity: seriesItem.opacity,
                    }
                  : null,
                emphasis: {
                  scale: true,
                },
                showSymbol: false,
                step: false,
                smoothMonotone: null,
                symbol: seriesItem.emphasisDisabled ? "none" : "circle",
                symbolSize: 8,
                symbolRotate: null,
                showAllSymbol: "none",
                connectNulls: false,
                sampling: "none",
                animationEasing: "linear",
                progressive: 0,
                hoverLayerThreshold: null,
                lineStyle: {
                  width: seriesItem.width ? seriesItem.width : 1,
                  type: seriesItem.dashed ? "dashed" : "solid",
                  shadowColor: seriesItem.shadowColor || null,
                  shadowBlur: seriesItem.shadowBlur || null,
                  shadowOffsetY: seriesItem.shadowOffsetY || null,
                },
                barMaxWidth: barMaxWidth ? barMaxWidth : "10px",
                data: isHidden
                  ? [[0, 0]]
                  : hasData
                    ? seriesItem.values
                        .map((item, index) => {
                          const value = roundFloat(item.value);
                          const label = labels ? labels[index] : index;
                          const units = item.units || "";
                          const fixedNumber = item.fixedNumber;

                          if (value) {
                            return {
                              value: [label, value],
                              itemStyle:
                                colorsList != null
                                  ? {
                                      color:
                                        index % 2 === 0
                                          ? colorsList[0]
                                          : colorsList[1],
                                    }
                                  : null,
                              units,
                              fixedNumber,
                            };
                          }
                          return null;
                        })
                        .filter((item) => item)
                    : [[0, 0]],
              };
            }),
          }}
        />
      </div>
    );
  },
);

export const LineChartTimeWithHeader = withHeader(LineChartTime);

LineChartTime.displayName = "LineChartTime";
