import { Text } from "./text/text";
import { Title } from "./title/title";

export type { TextProps } from "./text/text";
export type { TitleProps } from "./title/title";

export const Typography = {
  Text,
  Title,
};
