import { FC, useState, useEffect } from "react";
import { Menubar } from "components/menubar/menubar";
import styles from "./athlete-selection.module.scss";
import { AthleteSelectionHeader } from "components/athlete-selection-header/athlete-selection-header";
import {
  AthleteCard,
  AthletePopupState,
} from "components/athlete-card/athlete-card";
import { AthleteSearch } from "./athlete-search/athlete-search";
import { useDispatch, useSelector } from "hooks/app-hooks";
import {
  getAthleteList,
  setCurrentAthlete,
  setCurrentComparison,
  setCurrentComparisonData,
  setCurrentPerformanceComparison,
  setCurrentPerformanceComparisonData,
} from "store/slices/shared";
import { updateProfile } from "api/profile";
import { Athlete } from "types";
import { useHistory } from "react-router-dom";
import { AnthropometryPopup } from "components/add-data/anthropometry-popup/anthropometry-popup";
import { InputPopup } from "components/add-data/input-popup/input-popup";
import { ReactComponent as WeightIcon } from "images/weight_icon.svg";
import { Illnesspopup } from "components/illness-popup/illness-popup";
import { PhysiotherapyPopup } from "components/physiotherapy-popup/physiotherapy-popup";
import { Injurypopup } from "components/injury-popup/injury-popup";
import { fetchHealthEnums } from "store/slices/health";
import { useAppDispatch } from "../../store";

export const AthleteSelection: FC = () => {
  const athleteList = useSelector(getAthleteList);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [input, setInput] = useState<string>("");
  const [filteredAthletes, setFilteredAthletes] = useState(athleteList);
  const [selectedAthlete, setSelectedAthlete] = useState<Athlete | undefined>(
    undefined,
  );
  const [popups, setPopups] = useState<AthletePopupState>({
    anthropometry: false,
    weight: false,
    illness: false,
    physiotherapy: false,
    injury: false,
  });
  const [hoveredAthleteId, setHoveredAthleteId] = useState<number | null>(null);

  const togglePopup = (popup: keyof AthletePopupState) => {
    setPopups((prev) => ({ ...prev, [popup]: !prev[popup] }));
  };

  const renderPopup = (key: keyof AthletePopupState, athlete?: Athlete) => {
    switch (key) {
      case "anthropometry":
        return (
          <AnthropometryPopup
            onClose={() => togglePopup("anthropometry")}
            selectedAthlete={athlete}
          />
        );
      case "weight":
        return (
          <InputPopup
            dataField="weight"
            onClose={() => togglePopup("weight")}
            title="Weight"
            IconData={WeightIcon}
            submitDate={true}
            endpoint="weight"
            keyName="weight"
            inputLabel="Weight (kg)"
            athlete={athlete}
          />
        );
      case "illness":
        return <Illnesspopup onClose={() => togglePopup("illness")} />;
      case "physiotherapy":
        return (
          <PhysiotherapyPopup onClose={() => togglePopup("physiotherapy")} />
        );
      case "injury":
        return <Injurypopup onClose={() => togglePopup("injury")} />;
      default:
        return null;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  useEffect(() => {
    const regex = new RegExp(input, "i");
    const filtered = athleteList.filter(
      (athlete) =>
        regex.test(athlete.firstName) ||
        regex.test(athlete.lastName) ||
        regex.test(athlete.countryCode),
    );
    setFilteredAthletes(filtered);
    dispatch(fetchHealthEnums);
  }, [input, athleteList, dispatch]);

  const handleOnClick = async (
    athlete: Athlete,
    activeMedicalRecord: boolean,
  ) => {
    setInput("");
    if (athlete && athlete.id) {
      dispatch(setCurrentAthlete(athlete.id));
      dispatch(setCurrentComparisonData(null));
      dispatch(setCurrentComparison(undefined));
      dispatch(setCurrentPerformanceComparisonData(null));
      dispatch(setCurrentPerformanceComparison(undefined));
      await updateProfile({
        globalComparison: {
          id: 0,
          type: "athlete",
        },
        searchAthleteId: athlete.id,
      });
      if (activeMedicalRecord && hoveredAthleteId) {
        history.push("/health");
      } else {
        history.push("/performance");
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <Menubar />
      <div className={styles.page}>
        <AthleteSelectionHeader
          hideBottom={false}
          athleteCount={athleteList.length}
          handleChange={handleChange}
          input={input}
          hideMenuItems={true}
        />
        <div className={styles.search}>
          <AthleteSearch input={input} handleChange={handleChange} />
        </div>
        <div className={styles.athleteCardWrapper}>
          {filteredAthletes.map((athlete) => (
            <AthleteCard
              setSelectedAthlete={setSelectedAthlete}
              togglePopup={togglePopup}
              key={athlete.id}
              athlete={athlete}
              onClick={() =>
                handleOnClick(
                  athlete,
                  athlete?.activeMedicalReports &&
                    athlete?.activeMedicalReports > 0
                    ? true
                    : false,
                )
              }
              activeMedicalReport={
                athlete?.activeMedicalReports &&
                athlete?.activeMedicalReports > 0
                  ? true
                  : false
              }
              hoveredAthleteId={hoveredAthleteId}
              setHoveredAthleteId={setHoveredAthleteId}
            />
          ))}
        </div>
      </div>
      {Object.keys(popups).map(
        (key) =>
          popups[key as keyof AthletePopupState] &&
          renderPopup(key as keyof AthletePopupState, selectedAthlete),
      )}
    </div>
  );
};
