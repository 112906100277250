import s from "./index.module.css";
import {
  COLOR_WHITE,
  HRV_RHR_DARK_GREEN,
  HRV_RHR_DARK_ORANGE,
  HRV_RHR_LIGHT_GREEN,
  HRV_RHR_LIGHT_YELLOW,
} from "../../../../../../components/charts/colors";
import { useContext } from "react";
import { AthleteCardContext } from "../../../../context";
import { Skeleton } from "../../../../../../storybook";

export const HRV = () => {
  const { athlete, athleteLoading } = useContext(AthleteCardContext);

  const getHRVVariance = (value: number | undefined) => {
    if (!value) {
      return null;
    }
    let color = COLOR_WHITE;
    if (value > 5 && value < 10) {
      color = HRV_RHR_LIGHT_GREEN;
    } else if (value >= 10) {
      color = HRV_RHR_DARK_GREEN;
    }

    if (value > -10 && value < -5) {
      color = HRV_RHR_LIGHT_YELLOW;
    } else if (value < -10) {
      color = HRV_RHR_DARK_ORANGE;
    }

    const formattedHRVVariance = `${value > 0 ? "+" : ""}${value.toFixed(0)}`;
    return (
      <div
        className={`${s.tokenPrimary} analog-typography--subtitle`}
        style={{ color }}
      >
        ({formattedHRVVariance})
      </div>
    );
  };

  {
    getHRVVariance(athlete?.hrvVariance);
  }

  if (athleteLoading) {
    return <Skeleton width="100px" height="24px" />;
  }

  return (
    <div className={s.statValue}>
      <div className={`${s.tokenSecondary} analog-typography--subtitle`}>
        HRV
      </div>
      {getHRVVariance(athlete?.hrvVariance)}
      <div className={`${s.tokenPrimary} analog-typography--headline`}>
        {athlete?.hrv?.toFixed(0) || "--"}
      </div>
    </div>
  );
};
