import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import { ReactComponent as SettingsIcon } from "images/settings.svg";

import styles from "./settings-btn.module.scss";

type Props = {
  className?: string;
};

export const SettingsBtn: FC<Props> = ({ className }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/settings");
  };

  return (
    <button
      type="button"
      className={clsx(styles.button, className)}
      onClick={handleClick}
    >
      <SettingsIcon />
    </button>
  );
};
