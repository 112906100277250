interface BodyPart {
  face: string;
  name: string;
  id: number;
  d: string;
}

// Body parts for the front of the body (Anterior)
const bpa = {
  headAnt: "Head (ant.)",
  neckAnt: "Neck (ant.)",
  chestAnt: "Chest",
  shoulderLeftAnt: "Left shoulder (ant.)",
  shoulderRightAnt: "Left shoulder (ant.)",
  leftBicepsAnt: "Left Biceps (ant.)",
  rightBicepsAnt: "Right Biceps  (any.)",
  elbowLeftAnt: "Left elbow (ant.)",
  elbowRightAnt: "Right elbow (ant.)",
  forearmLeftAnt: "Left forearm (ant.)",
  forearmRightAnt: "Right forearm (ant.)",
  abdomenAnt: "Abdominal area",
  hipLeftAnt: "Hip/Groin Left",
  hipRightAnt: "Hip/Groin Right",
  thighLeftAnt: "Left thigh (ant.)",
  thighRightAnt: "Right thigh (ant.)",
  kneeLeftAnt: "Left knee (ant.)",
  kneeRightAnt: "Right knee (ant.)",
  lowerLegLeftAnt: "Left lower leg (ant.)",
  lowerLegRightAnt: "Right lower leg (ant.)",
  ankleLeftAnt: "Left ankle (ant.)",
  ankleRightAnt: "Right ankle (ant.)",
  footLeftAnt: "Left foot (ant.)",
  footRightAnt: "Right foot (ant.)",
  leftHandAnt: "Left hand",
  rightHandAnt: "Right hand",
  wristLeftAnt: "Wrist Left (ant.)",
  wristRightAnt: "Wrist Right (ant.)",
};

// Body parts for the back of the body (Posterior)
const bpp = {
  armLeftPost: "Arm (post.)",
  thoraicSpinePost: "Thoracic spine",
  lumbarSpinePost: "Lumbar spine",
  upperBackPost: "Upper back",
  lowerBackPost: "Lower back",
  footLeftPost: "Foot (post.)",
  footRightPost: "Foot (post.)",
  handRightPost: "Hand (post.)",
  handLeftPost: "Hand (post.)",
  ankleLeftPost: "Ankle (post.)",
  ankleRightPost: "Ankle (post.)",
  neckPost: "Neck (post.)",
  headPost: "Head (post.)",
  shoulderLeftPost: "Left shoulder (post.)",
  shoulderRightPost: "Right shoulder (post.)",
  elbowLeftPost: "Left elbow (post.)",
  elbowRightPost: "Right elbow (post.)",
  forearmLeftPost: "Left forearm (post.)",
  forearmRightPost: "Right forearm (post.)",
  hipLeftPost: "Hip/Groin Left",
  hipRightPost: "Hip/Groin Right",
  thighLeftPost: "Left thigh (post.)",
  thighRightPost: "Right thigh (post.)",
  kneeLeftPost: "Left knee (post.)",
  kneeRightPost: "Right knee (post.)",
  lowerLegLeftPost: "Left lower leg (post.)",
  lowerLegRightPost: "Right lower leg (post.)",
  wristLeftPost: "Wrist Left (post.)",
  wristRightPost: "Wrist Right (post.)",
  upperArmLeftPost: "Left upper arm (post.)",
  upperArmRightPost: "Right upper arm (post.)",
};

// Get body part dimentions according to the selected body part
export const getBodyPart = (): BodyPart[] => [
  {
    id: 0,
    face: "ant",
    name: bpa.headAnt,
    d: "M81.0459 3C91.8923 3 99.9651 10.4916 102.068 20.9064C103.422 27.6117 102.602 34.3853 101.61 41.0607C98.7685 60.1466 81.392 61.8518 81.392 61.8518H81.3493C81.3493 61.8518 64.007 60.1466 61.1694 41.0607C60.1779 34.3896 59.3574 27.6117 60.7121 20.9064C62.8147 10.4959 70.8875 3 81.7339 3",
  },
  {
    id: 1,
    face: "ant",
    name: bpa.neckAnt,
    d: "M70.478 58.4872V66.6028C70.478 66.6028 70.3968 68.3977 69.2429 70.1456C68.6104 71.1029 69.2857 72.3764 70.431 72.3764L75.2217 73.5944C79.6107 74.7098 84.2091 74.6841 88.5852 73.5174L92.8759 72.3721C94.0213 72.3721 94.705 71.0986 94.0683 70.1456C93.3503 69.0644 92.7349 67.6498 92.8075 66.013L92.7563 58C92.7563 58 87.2476 62.1112 81.2346 62.7351C81.2346 62.7394 76.4397 62.4104 70.478 58.4872Z",
  },
  {
    id: 2,
    face: "ant",
    name: bpa.shoulderLeftAnt,
    d: "M37.1849 95.6654L44.5616 98.968C45.6348 99.4484 46.8701 99.4008 47.8995 98.8381L56.2968 94.2544C60.1866 92.1291 63.8136 89.5667 67.1077 86.6191L72.1014 82.1435C73.7397 80.6762 73.7309 78.1311 72.0795 76.6724L68.9869 73.9369C68.0101 73.0755 66.6521 72.7812 65.4037 73.1664C62.9288 73.9282 58.5001 75.3653 53.9489 77.2135C46.7255 80.1481 38.9152 84.325 35.4678 90.519C34.4165 92.4061 35.2006 94.7738 37.1849 95.6654Z",
  },
  {
    id: 3,
    face: "ant",
    name: bpa.shoulderRightAnt,
    d: "M127.258 95.3785L120.061 98.6393C119.014 99.1136 117.809 99.0666 116.805 98.5111L108.612 93.9853C104.817 91.887 101.279 89.357 98.0648 86.4467L93.1929 82.0278C91.5946 80.579 91.6031 78.0662 93.2143 76.626L96.2314 73.925C97.1844 73.0746 98.5093 72.784 99.7272 73.1643C102.142 73.9165 106.462 75.3353 110.903 77.1602C117.95 80.0577 125.57 84.1817 128.933 90.2972C129.959 92.1605 129.198 94.4981 127.258 95.3785Z",
  },
  {
    id: 32,
    face: "ant",
    name: bpa.chestAnt,
    d: "M46.0685 97.8285C46.436 99.6187 46.7949 101.417 47.1409 103.208C47.2264 103.656 47.4101 104.071 47.6664 104.447C47.6707 104.455 47.6792 104.464 47.6835 104.477C48.9952 107.202 51.5459 113.607 52.8917 124.31C53.131 126.215 54.9553 127.51 56.8352 127.112C71.1823 124.07 88.3407 122.831 107.097 126.266C108.951 126.604 110.729 125.348 110.976 123.476C112.344 113.274 114.796 107.134 116.073 104.477V104.472C116.279 104.066 116.505 103.673 116.59 103.225C116.979 101.157 117.381 99.0547 117.795 96.9527C118.133 95.2479 117.09 93.5646 115.407 93.1245C89.3618 86.2586 66.0895 88.9631 48.4056 93.9918C46.7436 94.4661 45.7182 96.1323 46.0685 97.8285Z",
  },
  {
    id: 33,
    face: "ant",
    name: bpa.abdomenAnt,
    d: "M81.9301 189.249L82.1266 189.347L112.235 174.453C113.683 173.735 114.469 172.125 114.141 170.544C112.743 163.828 111.496 159.376 111.163 158.222C111.103 158.021 111.064 157.812 111.043 157.602C109.911 146.99 109.898 138.128 110.458 130.874C110.599 129.071 109.351 127.447 107.574 127.118C88.5311 123.589 71.1292 124.879 56.6326 127.994C54.8937 128.366 53.6931 129.947 53.817 131.72C54.3126 138.816 54.2571 147.4 53.1676 157.602C53.1462 157.812 53.1035 158.017 53.048 158.222C52.7147 159.371 51.4714 163.819 50.0743 170.523C49.7453 172.108 50.5315 173.714 51.9799 174.432L81.5499 189.057L81.9301 189.249Z",
  },
  {
    id: 4,
    face: "ant",
    name: bpa.leftBicepsAnt,
    d: "M43.2042 143.969C44.324 133.451 49.521 124.275 47.9482 113.052C43.8496 103.299 35.0882 101.338 30.5281 101C29.1989 106.522 27.8997 114.031 27.0235 124.134C27.0235 124.134 25.8269 136.742 24.0532 146.379L42.7854 147.328C42.9521 146.132 43.0931 145.003 43.2042 143.969Z",
  },
  {
    id: 5,
    face: "ant",
    name: bpa.rightBicepsAnt,
    d: "M121.038 143.969C119.918 133.451 114.721 124.275 116.294 113.052C120.393 103.299 129.154 101.338 133.714 101C135.043 106.522 136.343 114.031 137.219 124.134C137.219 124.134 138.415 136.742 140.189 146.379L121.457 147.328C121.29 146.132 121.149 145.003 121.038 143.969Z",
  },
  {
    id: 6,
    face: "ant",
    name: bpa.elbowRightAnt,
    d: "M140.189 147C140.569 149.077 140.98 151.013 141.407 152.659C141.407 152.659 141.668 153.231 142.074 154.398L123.705 161.125C122.799 156.484 121.995 151.842 121.457 147.945L140.189 147Z",
  },
  {
    id: 7,
    face: "ant",
    name: bpa.elbowLeftAnt,
    d: "M24.0532 147C23.6728 149.077 23.2626 151.013 22.8352 152.659C22.8352 152.659 22.5745 153.231 22.1685 154.398L40.5374 161.125C41.4434 156.484 42.2469 151.842 42.7854 147.945L24.0532 147Z",
  },
  {
    id: 8,
    face: "ant",
    name: bpa.forearmRightAnt,
    d: "M146.005 174.225L145.963 174.268C146.223 176.785 146.437 179.525 146.582 182.508C146.582 182.508 146.557 183.777 146.732 185.589L132.295 194.009C131.966 192.389 131.53 190.423 131.034 188.594L131.038 188.589C130.547 186.816 130 185.183 129.431 184.153C128.235 181.978 125.718 171.665 123.705 161.348L142.073 154.621C143.061 157.463 144.916 163.848 146.005 174.225Z",
  },
  {
    id: 9,
    face: "ant",
    name: bpa.forearmLeftAnt,
    d: "M18.2368 174.225L18.2795 174.268C18.0188 176.785 17.8051 179.525 17.6598 182.508C17.6598 182.508 17.6855 183.777 17.5103 185.589L31.9472 194.009C32.2763 192.389 32.7123 190.423 33.208 188.594L33.2037 188.589C33.6952 186.816 34.2423 185.183 34.8107 184.153C36.0074 181.978 38.5247 171.665 40.5376 161.348L22.1687 154.621C21.1815 157.463 19.3266 163.848 18.2368 174.225Z",
  },
  {
    id: 10,
    face: "ant",
    name: bpa.leftHandAnt,
    d: "M13.5226 196.971C13.5226 196.971 7.32983 201.057 2.62862 211.366C2.20978 212.285 1.83796 213.524 2.07302 214.528C2.25679 215.306 2.91923 215.648 3.68425 215.618C4.84673 215.571 6.38104 215.063 7.18879 214.199C7.71875 213.635 8.03073 212.913 8.41111 212.25C8.83421 211.511 9.31716 210.806 9.77018 210.084C10.1078 209.545 10.4412 209.007 10.7788 208.472C10.9027 208.276 11.1293 208.024 11.1976 207.797C9.59495 213.075 7.99654 218.358 6.38958 223.636C6.26564 224.042 6.1417 224.448 6.01776 224.858C5.84253 225.431 5.66731 226.012 5.65876 226.61C5.64166 228.068 6.78705 229.2 8.21878 228.593C10.1164 227.79 12.0011 222.811 12.467 221.003C13.1935 218.182 13.1337 217.896 14.0825 215.225C14.2791 214.674 11.4071 225.85 10.0693 231.162L10.0394 231.273C9.84711 232.034 10.0822 232.872 10.7061 233.346C11.2746 233.773 12.1806 233.944 13.5995 233.085C14.0397 232.82 14.3645 232.397 14.5141 231.906L18.8563 217.272C18.4503 219.353 18.04 221.435 17.634 223.516C17.3306 225.063 17.0271 226.61 16.7237 228.158C16.6083 228.752 16.4929 229.346 16.3732 229.94C16.2963 230.333 16.0613 230.953 16.1339 231.342C16.1681 231.538 16.3049 231.718 16.4587 231.837C16.9032 232.175 17.6725 232.299 18.2153 232.333C19.7068 232.431 20.3479 230.846 20.8565 229.688C22.5446 225.845 23.502 221.687 24.2969 217.58C23.6516 220.999 23.0062 224.414 22.3609 227.833C22.1557 228.91 23.3225 228.999 24.1388 229.132C24.5576 229.2 24.9893 229.2 25.3996 229.102C26.5706 228.829 27.3185 227.82 27.7587 226.76C27.7587 226.76 31.2034 211.28 31.4 201.758L14.5013 195.839C14.2064 196.249 13.8816 196.63 13.5226 196.971Z",
  },
  {
    id: 11,
    face: "ant",
    name: bpa.rightHandAnt,
    d: "M150.72 196.971C150.72 196.971 156.912 201.057 161.614 211.366C162.032 212.285 162.404 213.524 162.169 214.528C161.985 215.306 161.323 215.648 160.558 215.618C159.395 215.571 157.861 215.063 157.053 214.199C156.523 213.635 156.211 212.913 155.831 212.25C155.408 211.511 154.925 210.806 154.472 210.084C154.134 209.545 153.801 209.007 153.463 208.472C153.339 208.276 153.113 208.024 153.045 207.797C154.647 213.075 156.246 218.358 157.853 223.636C157.977 224.042 158.1 224.448 158.224 224.858C158.4 225.431 158.575 226.012 158.583 226.61C158.601 228.068 157.455 229.2 156.023 228.593C154.126 227.79 152.241 222.811 151.775 221.003C151.049 218.182 151.109 217.896 150.16 215.225C149.963 214.674 152.835 225.85 154.173 231.162L154.203 231.273C154.395 232.034 154.16 232.872 153.536 233.346C152.968 233.773 152.062 233.944 150.643 233.085C150.202 232.82 149.878 232.397 149.728 231.906L145.386 217.272C145.792 219.353 146.202 221.435 146.608 223.516C146.912 225.063 147.215 226.61 147.518 228.158C147.634 228.752 147.749 229.346 147.869 229.94C147.946 230.333 148.181 230.953 148.108 231.342C148.074 231.538 147.937 231.718 147.783 231.837C147.339 232.175 146.57 232.299 146.027 232.333C144.535 232.431 143.894 230.846 143.386 229.688C141.698 225.845 140.74 221.687 139.945 217.58C140.591 220.999 141.236 224.414 141.881 227.833C142.086 228.91 140.92 228.999 140.103 229.132C139.685 229.2 139.253 229.2 138.843 229.102C137.672 228.829 136.924 227.82 136.483 226.76C136.483 226.76 133.039 211.28 132.842 201.758L149.741 195.839C150.036 196.249 150.361 196.63 150.72 196.971Z",
  },
  {
    id: 12,
    face: "ant",
    name: bpa.hipLeftAnt,
    d: "M81.99 190.215C81.9729 192.254 81.9002 195.916 81.8062 200.011H81.6823C81.6823 200.075 81.6865 200.139 81.6865 200.199C81.6993 200.579 81.7079 200.959 81.7207 201.344L45 208.921C45.53 195.245 47.4275 182.928 49.2054 174L81.99 190.215Z",
  },
  {
    id: 13,
    face: "ant",
    name: bpa.hipRightAnt,
    d: "M82.302 190.215C82.3191 192.254 82.3918 195.916 82.4858 200.011H82.6097C82.6097 200.075 82.6055 200.139 82.6055 200.199C82.5926 200.579 82.5841 200.959 82.5713 201.344L119.292 208.921C118.762 195.245 116.864 182.928 115.087 174L82.302 190.215Z",
  },
  {
    id: 14,
    face: "ant",
    name: bpa.thighLeftAnt,
    d: "M81.8708 202C81.8794 202.342 81.8922 202.684 81.9008 203.021C81.8281 206.197 81.7469 209.483 81.6742 212.377C81.6486 213.18 81.6272 213.984 81.6016 214.787C81.4392 220.301 81.2725 225.818 81.1101 231.331C80.905 238.169 80.7041 245.008 80.4989 251.841C80.3023 258.419 79.5886 265.018 79.3023 271.625C79.2467 272.869 79.2125 274.112 79.1954 275.356L54.1722 276.377C53.9243 275.45 53.6465 274.514 53.3259 273.561C52.0994 269.907 51.1463 266.142 50.2616 262.398C47.1845 249.38 45.5177 236.024 45.0903 222.66C44.945 218.206 44.9834 213.826 45.1458 209.577L81.8708 202Z",
  },

  {
    id: 15,
    face: "ant",
    name: bpa.thighRightAnt,
    d: "M82.4212 202C82.4126 202.342 82.3998 202.684 82.3912 203.021C82.4639 206.197 82.5451 209.483 82.6177 212.377C82.6434 213.18 82.6648 213.984 82.6904 214.787C82.8528 220.301 83.0195 225.818 83.1819 231.331C83.387 238.169 83.5879 245.008 83.7931 251.841C83.9896 258.419 84.7034 265.018 84.9897 271.625C85.0453 272.869 85.0795 274.112 85.0966 275.356L110.12 276.377C110.368 275.45 110.646 274.514 110.966 273.561C112.193 269.907 113.146 266.142 114.03 262.398C117.108 249.38 118.774 236.024 119.202 222.66C119.347 218.206 119.309 213.826 119.146 209.577L82.4212 202Z",
  },
  {
    id: 16,
    face: "ant",
    name: bpa.kneeLeftAnt,
    d: "M55.1967 292.578L79.4891 286.646C79.2925 284.603 79.1429 282.56 79.066 280.509C79.0104 279.004 79.0019 277.504 79.0233 276L54 277.021C55.3804 282.201 55.654 287.095 55.1967 292.578Z",
  },
  {
    id: 17,
    face: "ant",
    name: bpa.kneeRightAnt,
    d: "M109.095 292.578L84.8029 286.646C84.9995 284.603 85.1491 282.56 85.226 280.509C85.2816 279.004 85.2901 277.504 85.2687 276L110.292 277.021C108.912 282.201 108.638 287.095 109.095 292.578Z",
  },
  {
    id: 18,
    face: "ant",
    name: bpa.lowerLegLeftAnt,
    d: "M82.1147 319.349C81.9993 316.284 81.9609 313.207 82.0506 310.121C82.0677 309.613 82.0891 309.1 82.1147 308.591C81.7685 301.369 80.5377 294.201 79.8496 287L55.5572 292.932C55.5187 293.389 55.4759 293.847 55.4289 294.308C54.3092 305.155 55.5572 315.259 57.1556 325.977C58.4933 334.965 59.6857 344.492 62.9765 353.022C62.9765 353.022 63.4851 354.552 63.8527 356.958H79.9351C79.8197 344.355 81.636 331.931 82.1147 319.349Z",
  },
  {
    id: 19,
    face: "ant",
    name: bpa.lowerLegRightAnt,
    d: "M82.1773 319.349C82.2927 316.284 82.3311 313.207 82.2414 310.121C82.2243 309.613 82.2029 309.1 82.1773 308.591C82.5235 301.369 83.7543 294.201 84.4424 287L108.735 292.932C108.773 293.389 108.816 293.847 108.863 294.308C109.983 305.155 108.735 315.259 107.136 325.977C105.799 334.965 104.606 344.492 101.315 353.022C101.315 353.022 100.807 354.552 100.439 356.958H84.3569C84.4723 344.355 82.6559 331.931 82.1773 319.349Z",
  },
  {
    id: 20,
    face: "ant",
    name: bpa.ankleLeftAnt,
    d: "M80.5055 359.654L80.4585 359.671C80.4457 359.115 80.4414 358.556 80.4371 358H64.3547C64.6539 359.945 64.8633 362.466 64.6368 365.219L64.6795 365.206C64.5471 366.509 64.312 367.804 64 369.074L82.292 380.767C82.0997 376.882 81.6424 373.001 81.1722 369.159C80.7833 365.971 80.5782 362.808 80.5055 359.654Z",
  },
  {
    id: 21,
    face: "ant",
    name: bpa.ankleRightAnt,
    d: "M83.7865 359.654L83.8335 359.671C83.8463 359.115 83.8506 358.556 83.8548 358H99.9373C99.6381 359.945 99.4287 362.466 99.6552 365.219L99.6124 365.206C99.7449 366.509 99.98 367.804 100.292 369.074L82 380.767C82.1923 376.882 82.6496 373.001 83.1198 369.159C83.5087 365.971 83.7138 362.808 83.7865 359.654Z",
  },
  {
    id: 22,
    face: "ant",
    name: bpa.footLeftAnt,
    d: "M58.2689 390.282C58.2689 390.282 79.5844 393.977 81.2849 390.525C81.2849 390.525 82.4256 390.273 81.9642 381.672L63.6992 370C63.1524 372.183 62.268 374.405 60.8922 376.52C60.8965 376.516 49.6085 388.115 58.2689 390.282Z",
  },
  {
    id: 23,
    face: "ant",
    name: bpa.footRightAnt,
    d: "M106.023 390.282C106.023 390.282 84.7076 393.977 83.0071 390.525C83.0071 390.525 81.8664 390.273 82.3278 381.672L100.593 370C101.14 372.183 102.024 374.405 103.4 376.52C103.395 376.516 114.683 388.115 106.023 390.282Z",
  },
  {
    id: 24,
    face: "ant",
    name: bpa.wristLeftAnt,
    d: "M14.5056 195.344L31.4043 201.263C31.43 199.977 31.4001 198.797 31.2889 197.78C31.2889 197.78 31.5368 196.288 31.9514 194.258L17.5144 185.839C17.2451 188.6 16.51 192.613 14.5056 195.344Z",
  },
  {
    id: 25,
    face: "ant",
    name: bpa.wristRightAnt,
    d: "M149.737 195.344L132.838 201.263C132.812 199.977 132.842 198.797 132.953 197.78C132.953 197.78 132.705 196.288 132.291 194.258L146.728 185.839C146.997 188.6 147.732 192.613 149.737 195.344Z",
  },
  // Posterior Body Parts
  {
    id: 26,
    face: "post",
    name: bpp.thoraicSpinePost,
    d: "M82.2866 142.616C80.1951 142.616 78.5 140.921 78.5 138.83V77.2866C78.5 75.1951 80.1951 73.5 82.2866 73.5C84.3782 73.5 86.0733 75.1951 86.0733 77.2866V138.825C86.0733 140.922 84.3779 142.616 82.2866 142.616Z",
  },
  {
    id: 27,
    face: "post",
    name: bpp.lumbarSpinePost,
    d: "M82.5 181.5C80.1922 181.5 78.5 179.986 78.5 178.317V149.683C78.5 148.014 80.1922 146.5 82.5 146.5C84.8078 146.5 86.5 148.014 86.5 149.683V178.317C86.5 179.986 84.8078 181.5 82.5 181.5Z",
  },
  {
    id: 28,
    face: "post",
    name: bpp.upperBackPost,
    d: "M119.688 95.5417C119.653 95.1371 119.449 92.357 119.122 92.2395C118.944 92.1743 118.77 92.1003 118.605 92.0089L109.825 87.1579C106.148 85.1261 102.72 82.6767 99.6093 79.853L91.5039 72.5003C91.356 72.3655 91.356 72.1349 91.4996 72C88.9892 73.6794 85.9742 74.6583 82.7285 74.6583C79.6091 74.6583 76.7028 73.749 74.2534 72.1914C74.2708 72.3002 74.2403 72.4177 74.1533 72.5003L66.0479 79.853C62.9371 82.6767 59.5088 85.1261 55.8324 87.1579L47.0527 92.0089C46.9831 92.0481 46.9091 92.0785 46.8352 92.1134C45.8823 92.5702 45.9824 96.8861 46.0259 97.7432C46.1521 100.306 46.8352 102.655 47.7445 105.026C47.7575 104.809 48.0795 104.735 48.2535 104.87C49.1062 105.522 49.6022 107.489 49.9198 108.463C50.3984 109.943 50.7247 111.461 51.138 112.958C52.5476 118.061 53.3873 123.317 53.8659 128.585C69.5067 125.066 91.0123 123.882 111.944 127.885C112.413 123.06 113.192 118.248 114.445 113.558C114.872 111.966 115.35 110.382 115.903 108.829C116.333 107.615 116.808 105.557 117.743 104.674C117.904 104.522 118.1 104.4 118.248 104.234C118.47 103.986 118.574 103.66 118.665 103.343C119.37 100.889 119.91 98.1043 119.688 95.5417Z",
  },
  {
    id: 29,
    face: "post",
    name: bpp.lowerBackPost,
    d: "M82.5366 189.586L115.338 173.362C113.471 163.755 111.676 157.78 111.676 157.78C110.389 145.894 110.5 136.193 111.252 128.47C90.6911 124.538 69.5656 125.7 54.2012 129.158C54.8935 136.765 54.9619 146.245 53.7097 157.78C53.7097 157.78 51.8848 163.861 50 173.623L82.4255 189.586H82.5366Z",
  },
  {
    id: 30,
    face: "post",
    name: bpp.footLeftPost,
    d: "M54.2952 380.022C55.5218 378.454 57.3424 377.616 58.9494 376.518C60.9154 375.176 61.7744 373.62 62.5522 371.398C62.7146 370.936 62.8599 370.47 62.9967 370L81.6092 381.894C81.6391 382.77 81.6605 383.642 81.6605 384.518C81.6562 385.976 81.8015 387.937 81.1348 389.301C80.5878 390.425 79.4039 391.309 78.2329 391.48C76.2627 391.767 74.2582 391.66 72.2752 391.519C69.4587 391.318 66.6978 390.702 63.8942 390.403C61.1632 390.113 58.2442 389.604 55.8936 388.095C54.3679 387.117 53.1156 385.527 53.0088 383.715C52.9233 382.39 53.4703 381.073 54.2952 380.022Z",
  },
  {
    id: 31,
    face: "post",
    name: bpp.footRightPost,
    d: "M109.695 380.202C108.468 378.633 106.648 377.796 105.041 376.697C103.075 375.355 102.216 373.8 101.438 371.577C101.275 371.116 101.13 370.65 100.993 370.18L82.3808 382.074C82.3509 382.95 82.3295 383.822 82.3295 384.698C82.3338 386.155 82.1884 388.117 82.8552 389.48C83.4022 390.604 84.5861 391.489 85.7571 391.66C87.7273 391.946 89.7318 391.839 91.7148 391.698C94.5313 391.498 97.2922 390.882 100.096 390.583C102.827 390.292 105.746 389.784 108.096 388.275C109.622 387.296 110.874 385.706 110.981 383.894C111.067 382.569 110.52 381.253 109.695 380.202Z",
  },
  {
    id: 34,
    face: "post",
    name: bpp.ankleLeftPost,
    d: "M80.5055 359.654L80.4585 359.671C80.4457 359.115 80.4414 358.556 80.4371 358H64.3547C64.6539 359.945 64.8633 362.466 64.6368 365.219L64.6795 365.206C64.5471 366.509 64.312 367.804 64 369.074L82.292 380.767C82.0997 376.882 81.6424 373.001 81.1722 369.159C80.7833 365.971 80.5782 362.808 80.5055 359.654Z",
  },
  {
    id: 35,
    face: "post",
    name: bpp.ankleRightPost,
    d: "M83.7865 359.654L83.8335 359.671C83.8463 359.115 83.8506 358.556 83.8548 358H99.9373C99.6381 359.945 99.4287 362.466 99.6552 365.219L99.6124 365.206C99.7449 366.509 99.98 367.804 100.292 369.074L82 380.767C82.1923 376.882 82.6496 373.001 83.1198 369.159C83.5087 365.971 83.7138 362.808 83.7865 359.654Z",
  },
  {
    id: 36,
    face: "post",
    name: bpp.neckPost,
    d: "M70.478 58.4872V66.6028C70.478 66.6028 70.3968 68.3977 69.2429 70.1456C68.6104 71.1029 69.2857 72.3764 70.431 72.3764L75.2217 73.5944C79.6107 74.7098 84.2091 74.6841 88.5852 73.5174L92.8759 72.3721C94.0213 72.3721 94.705 71.0986 94.0683 70.1456C93.3503 69.0644 92.7349 67.6498 92.8075 66.013L92.7563 58C92.7563 58 87.2476 62.1112 81.2346 62.7351C81.2346 62.7394 76.4397 62.4104 70.478 58.4872Z",
  },
  {
    id: 37,
    face: "post",
    name: bpp.headPost,
    d: "M81.0459 3C91.8923 3 99.9651 10.4916 102.068 20.9064C103.422 27.6117 102.602 34.3853 101.61 41.0607C98.7685 60.1466 81.392 61.8518 81.392 61.8518H81.3493C81.3493 61.8518 64.007 60.1466 61.1694 41.0607C60.1779 34.3896 59.3574 27.6117 60.7121 20.9064C62.8147 10.4959 70.8875 3 81.7339 3",
  },
  {
    id: 38,
    face: "post",
    name: bpp.shoulderRightPost,
    d: "M37.1849 95.6654L44.5616 98.968C45.6348 99.4484 46.8701 99.4008 47.8995 98.8381L56.2968 94.2544C60.1866 92.1291 63.8136 89.5667 67.1077 86.6191L72.1014 82.1435C73.7397 80.6762 73.7309 78.1311 72.0795 76.6724L68.9869 73.9369C68.0101 73.0755 66.6521 72.7812 65.4037 73.1664C62.9288 73.9282 58.5001 75.3653 53.9489 77.2135C46.7255 80.1481 38.9152 84.325 35.4678 90.519C34.4165 92.4061 35.2006 94.7738 37.1849 95.6654Z",
  },
  {
    id: 39,
    face: "post",
    name: bpp.shoulderLeftPost,
    d: "M127.258 95.3785L120.061 98.6393C119.014 99.1136 117.809 99.0666 116.805 98.5111L108.612 93.9853C104.817 91.887 101.279 89.357 98.0648 86.4467L93.1929 82.0278C91.5946 80.579 91.6031 78.0662 93.2143 76.626L96.2314 73.925C97.1844 73.0746 98.5093 72.784 99.7272 73.1643C102.142 73.9165 106.462 75.3353 110.903 77.1602C117.95 80.0577 125.57 84.1817 128.933 90.2972C129.959 92.1605 129.198 94.4981 127.258 95.3785Z",
  },
  {
    id: 40,
    face: "post",
    name: bpp.elbowLeftPost,
    d: "M24.0532 147C23.6728 149.077 23.2626 151.013 22.8352 152.659C22.8352 152.659 22.5745 153.231 22.1685 154.398L40.5374 161.125C41.4434 156.484 42.2469 151.842 42.7854 147.945L24.0532 147Z",
  },
  {
    id: 41,
    face: "post",
    name: bpp.elbowRightPost,
    d: "M140.189 147C140.569 149.077 140.98 151.013 141.407 152.659C141.407 152.659 141.668 153.231 142.074 154.398L123.705 161.125C122.799 156.484 121.995 151.842 121.457 147.945L140.189 147Z",
  },
  {
    id: 42,
    face: "post",
    name: bpp.wristLeftPost,
    d: "M14.5056 195.344L31.4043 201.263C31.43 199.977 31.4001 198.797 31.2889 197.78C31.2889 197.78 31.5368 196.288 31.9514 194.258L17.5144 185.839C17.2451 188.6 16.51 192.613 14.5056 195.344Z",
  },
  {
    id: 43,
    face: "post",
    name: bpp.wristRightPost,
    d: "M149.737 195.344L132.838 201.263C132.812 199.977 132.842 198.797 132.953 197.78C132.953 197.78 132.705 196.288 132.291 194.258L146.728 185.839C146.997 188.6 147.732 192.613 149.737 195.344Z",
  },
  {
    id: 44,
    face: "post",
    name: bpp.hipLeftPost,
    d: "M81.99 190.215C81.9729 192.254 81.9002 195.916 81.8062 200.011H81.6823C81.6823 200.075 81.6865 200.139 81.6865 200.199C81.6993 200.579 81.7079 200.959 81.7207 201.344L45 208.921C45.53 195.245 47.4275 182.928 49.2054 174L81.99 190.215Z",
  },
  {
    id: 45,
    face: "post",
    name: bpp.hipRightPost,
    d: "M82.302 190.215C82.3191 192.254 82.3918 195.916 82.4858 200.011H82.6097C82.6097 200.075 82.6055 200.139 82.6055 200.199C82.5926 200.579 82.5841 200.959 82.5713 201.344L119.292 208.921C118.762 195.245 116.864 182.928 115.087 174L82.302 190.215Z",
  },
  {
    id: 46,
    face: "post",
    name: bpp.thighLeftPost,
    d: "M81.8708 202C81.8794 202.342 81.8922 202.684 81.9008 203.021C81.8281 206.197 81.7469 209.483 81.6742 212.377C81.6486 213.18 81.6272 213.984 81.6016 214.787C81.4392 220.301 81.2725 225.818 81.1101 231.331C80.905 238.169 80.7041 245.008 80.4989 251.841C80.3023 258.419 79.5886 265.018 79.3023 271.625C79.2467 272.869 79.2125 274.112 79.1954 275.356L54.1722 276.377C53.9243 275.45 53.6465 274.514 53.3259 273.561C52.0994 269.907 51.1463 266.142 50.2616 262.398C47.1845 249.38 45.5177 236.024 45.0903 222.66C44.945 218.206 44.9834 213.826 45.1458 209.577L81.8708 202Z",
  },
  {
    id: 47,
    face: "post",
    name: bpp.thighRightPost,
    d: "M82.4212 202C82.4126 202.342 82.3998 202.684 82.3912 203.021C82.4639 206.197 82.5451 209.483 82.6177 212.377C82.6434 213.18 82.6648 213.984 82.6904 214.787C82.8528 220.301 83.0195 225.818 83.1819 231.331C83.387 238.169 83.5879 245.008 83.7931 251.841C83.9896 258.419 84.7034 265.018 84.9897 271.625C85.0453 272.869 85.0795 274.112 85.0966 275.356L110.12 276.377C110.368 275.45 110.646 274.514 110.966 273.561C112.193 269.907 113.146 266.142 114.03 262.398C117.108 249.38 118.774 236.024 119.202 222.66C119.347 218.206 119.309 213.826 119.146 209.577L82.4212 202Z",
  },
  {
    id: 48,
    face: "post",
    name: bpp.kneeLeftPost,
    d: "M55.1967 292.578L79.4891 286.646C79.2925 284.603 79.1429 282.56 79.066 280.509C79.0104 279.004 79.0019 277.504 79.0233 276L54 277.021C55.3804 282.201 55.654 287.095 55.1967 292.578Z",
  },
  {
    id: 49,
    face: "post",
    name: bpp.kneeRightPost,
    d: "M109.095 292.578L84.8029 286.646C84.9995 284.603 85.1491 282.56 85.226 280.509C85.2816 279.004 85.2901 277.504 85.2687 276L110.292 277.021C108.912 282.201 108.638 287.095 109.095 292.578Z",
  },
  {
    id: 50,
    face: "post",
    name: bpp.lowerLegLeftPost,
    d: "M82.1147 319.349C81.9993 316.284 81.9609 313.207 82.0506 310.121C82.0677 309.613 82.0891 309.1 82.1147 308.591C81.7685 301.369 80.5377 294.201 79.8496 287L55.5572 292.932C55.5187 293.389 55.4759 293.847 55.4289 294.308C54.3092 305.155 55.5572 315.259 57.1556 325.977C58.4933 334.965 59.6857 344.492 62.9765 353.022C62.9765 353.022 63.4851 354.552 63.8527 356.958H79.9351C79.8197 344.355 81.636 331.931 82.1147 319.349Z",
  },
  {
    id: 51,
    face: "post",
    name: bpp.lowerLegRightPost,
    d: "M82.1773 319.349C82.2927 316.284 82.3311 313.207 82.2414 310.121C82.2243 309.613 82.2029 309.1 82.1773 308.591C82.5235 301.369 83.7543 294.201 84.4424 287L108.735 292.932C108.773 293.389 108.816 293.847 108.863 294.308C109.983 305.155 108.735 315.259 107.136 325.977C105.799 334.965 104.606 344.492 101.315 353.022C101.315 353.022 100.807 354.552 100.439 356.958H84.3569C84.4723 344.355 82.6559 331.931 82.1773 319.349Z",
  },
  {
    id: 52,
    face: "post",
    name: bpp.forearmLeftPost,
    d: "M18.2368 174.225L18.2795 174.268C18.0188 176.785 17.8051 179.525 17.6598 182.508C17.6598 182.508 17.6855 183.777 17.5103 185.589L31.9472 194.009C32.2763 192.389 32.7123 190.423 33.208 188.594L33.2037 188.589C33.6952 186.816 34.2423 185.183 34.8107 184.153C36.0074 181.978 38.5247 171.665 40.5376 161.348L22.1687 154.621C21.1815 157.463 19.3266 163.848 18.2368 174.225Z",
  },
  {
    id: 53,
    face: "post",
    name: bpp.forearmRightPost,
    d: "M146.005 174.225L145.963 174.268C146.223 176.785 146.437 179.525 146.582 182.508C146.582 182.508 146.557 183.777 146.732 185.589L132.295 194.009C131.966 192.389 131.53 190.423 131.034 188.594L131.038 188.589C130.547 186.816 130 185.183 129.431 184.153C128.235 181.978 125.718 171.665 123.705 161.348L142.073 154.621C143.061 157.463 144.916 163.848 146.005 174.225Z",
  },
  {
    id: 54,
    face: "post",
    name: bpp.upperArmLeftPost,
    d: "M43.2042 143.969C44.324 133.451 49.521 124.275 47.9482 113.052C43.8496 103.299 35.0882 101.338 30.5281 101C29.1989 106.522 27.8997 114.031 27.0235 124.134C27.0235 124.134 25.8269 136.742 24.0532 146.379L42.7854 147.328C42.9521 146.132 43.0931 145.003 43.2042 143.969Z",
  },
  {
    id: 55,
    face: "post",
    name: bpp.upperArmRightPost,
    d: "M121.038 143.969C119.918 133.451 114.721 124.275 116.294 113.052C120.393 103.299 129.154 101.338 133.714 101C135.043 106.522 136.343 114.031 137.219 124.134C137.219 124.134 138.415 136.742 140.189 146.379L121.457 147.328C121.29 146.132 121.149 145.003 121.038 143.969Z",
  },
  {
    id: 56,
    face: "post",
    name: bpp.handLeftPost,
    d: "M13.5226 196.971C13.5226 196.971 7.32983 201.057 2.62862 211.366C2.20978 212.285 1.83796 213.524 2.07302 214.528C2.25679 215.306 2.91923 215.648 3.68425 215.618C4.84673 215.571 6.38104 215.063 7.18879 214.199C7.71875 213.635 8.03073 212.913 8.41111 212.25C8.83421 211.511 9.31716 210.806 9.77018 210.084C10.1078 209.545 10.4412 209.007 10.7788 208.472C10.9027 208.276 11.1293 208.024 11.1976 207.797C9.59495 213.075 7.99654 218.358 6.38958 223.636C6.26564 224.042 6.1417 224.448 6.01776 224.858C5.84253 225.431 5.66731 226.012 5.65876 226.61C5.64166 228.068 6.78705 229.2 8.21878 228.593C10.1164 227.79 12.0011 222.811 12.467 221.003C13.1935 218.182 13.1337 217.896 14.0825 215.225C14.2791 214.674 11.4071 225.85 10.0693 231.162L10.0394 231.273C9.84711 232.034 10.0822 232.872 10.7061 233.346C11.2746 233.773 12.1806 233.944 13.5995 233.085C14.0397 232.82 14.3645 232.397 14.5141 231.906L18.8563 217.272C18.4503 219.353 18.04 221.435 17.634 223.516C17.3306 225.063 17.0271 226.61 16.7237 228.158C16.6083 228.752 16.4929 229.346 16.3732 229.94C16.2963 230.333 16.0613 230.953 16.1339 231.342C16.1681 231.538 16.3049 231.718 16.4587 231.837C16.9032 232.175 17.6725 232.299 18.2153 232.333C19.7068 232.431 20.3479 230.846 20.8565 229.688C22.5446 225.845 23.502 221.687 24.2969 217.58C23.6516 220.999 23.0062 224.414 22.3609 227.833C22.1557 228.91 23.3225 228.999 24.1388 229.132C24.5576 229.2 24.9893 229.2 25.3996 229.102C26.5706 228.829 27.3185 227.82 27.7587 226.76C27.7587 226.76 31.2034 211.28 31.4 201.758L14.5013 195.839C14.2064 196.249 13.8816 196.63 13.5226 196.971Z",
  },
  {
    id: 57,
    face: "post",
    name: bpp.handRightPost,
    d: "M150.72 196.971C150.72 196.971 156.912 201.057 161.614 211.366C162.032 212.285 162.404 213.524 162.169 214.528C161.985 215.306 161.323 215.648 160.558 215.618C159.395 215.571 157.861 215.063 157.053 214.199C156.523 213.635 156.211 212.913 155.831 212.25C155.408 211.511 154.925 210.806 154.472 210.084C154.134 209.545 153.801 209.007 153.463 208.472C153.339 208.276 153.113 208.024 153.045 207.797C154.647 213.075 156.246 218.358 157.853 223.636C157.977 224.042 158.1 224.448 158.224 224.858C158.4 225.431 158.575 226.012 158.583 226.61C158.601 228.068 157.455 229.2 156.023 228.593C154.126 227.79 152.241 222.811 151.775 221.003C151.049 218.182 151.109 217.896 150.16 215.225C149.963 214.674 152.835 225.85 154.173 231.162L154.203 231.273C154.395 232.034 154.16 232.872 153.536 233.346C152.968 233.773 152.062 233.944 150.643 233.085C150.202 232.82 149.878 232.397 149.728 231.906L145.386 217.272C145.792 219.353 146.202 221.435 146.608 223.516C146.912 225.063 147.215 226.61 147.518 228.158C147.634 228.752 147.749 229.346 147.869 229.94C147.946 230.333 148.181 230.953 148.108 231.342C148.074 231.538 147.937 231.718 147.783 231.837C147.339 232.175 146.57 232.299 146.027 232.333C144.535 232.431 143.894 230.846 143.386 229.688C141.698 225.845 140.74 221.687 139.945 217.58C140.591 220.999 141.236 224.414 141.881 227.833C142.086 228.91 140.92 228.999 140.103 229.132C139.685 229.2 139.253 229.2 138.843 229.102C137.672 228.829 136.924 227.82 136.483 226.76C136.483 226.76 133.039 211.28 132.842 201.758L149.741 195.839C150.036 196.249 150.361 196.63 150.72 196.971Z",
  },
];
