import React, { FC } from "react";

import { SideTab } from "components/side-tabs/side-tab/side-tab";
import styles from "./side-tabs.module.scss";

type Props = {
  tabs: Tab[];
  activeTabs: {
    [id: number]: boolean;
  };
  onClick: (tabId: number) => void;
};

export interface Tab {
  id: number;
  name: string;
}

export const SideTabs: FC<Props> = ({ tabs, activeTabs, onClick }) => {
  return (
    <div className={styles.wrapper} data-testid="side-tabs">
      {tabs.map((tab) => (
        <SideTab
          key={tab.id}
          tab={tab}
          active={activeTabs[tab.id]}
          onClick={onClick}
        />
      ))}
    </div>
  );
};
