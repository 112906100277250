import { Layout } from "components/layout/layout";
import styles from "./group-chart-view.module.scss";
import { FC } from "react";
import { getSelectedGroup } from "store/slices/shared";
import { useSelector } from "hooks/app-hooks";
import { GroupMenuItems } from "../const";
import { DateSelector } from "components/date-selector/date-selector";
import { TeamCriticalPowerW } from "pages/team-dashboard/team-critical-power-w/team-critical-power-w";
import { TeamPerformanceBarChart } from "pages/team-dashboard/team-performance-chart/team-performance-chart";
import { TeamWorkoutDetails } from "pages/team-dashboard/team-workout-details/team-workout-details";
import HeaderWithBackButton from "../header-with-back-button/header-with-back-button";

type Props = {
  loading: boolean;
};

export const GroupChartView: FC<Props> = ({ loading }) => {
  const selectedGroup = useSelector(getSelectedGroup);

  return (
    <Layout
      topMenuItems={GroupMenuItems}
      contentClassName={styles.contentWrapper}
      top={null}
      showAdditonalComps={false}
      customHeader={
        <HeaderWithBackButton
          backNavLocation={"/group-selection"}
          title={selectedGroup?.name || ""}
        />
      }
      content={
        <div className={styles.chartViewWrapper}>
          <TeamPerformanceBarChart />
          <TeamWorkoutDetails />
          <TeamCriticalPowerW />
        </div>
      }
      showCalendarComp={false}
      dataTestId="chart-view-layout"
    />
  );
};
