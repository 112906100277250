import React, { StrictMode } from "react";
import { Provider } from "react-redux";
import "./locales";
import "styles/fonts.css";
import "styles/css-variables.css";

import { msalConfig } from "./api/auth/authConfig.ts";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { persistor, store } from "store";

import { App } from "app";

import "styles/global.scss";
import { PersistGate } from "redux-persist/integration/react";
import { createRoot } from "react-dom/client";

const msalInstance = new PublicClientApplication(msalConfig);
const root = createRoot(document.getElementById("root")!);

msalInstance
  .initialize()
  .then(() => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
      }
    });

    root.render(
      <StrictMode>
        <MsalProvider instance={msalInstance}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </MsalProvider>
      </StrictMode>,
    );
  })
  .catch(() => {
    root.render(<div>Error Page</div>);
  });
