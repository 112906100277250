import { FC } from "react";
import clsx from "clsx";

import styles from "./group-selection-header.module.scss";

import { CoachInfo } from "components/coach-info/coach-info";

type Props = {
  hideBottom?: boolean;
  athleteCount: number;
};

export const GroupSelectionHeader: FC<Props> = ({
  hideBottom,
  athleteCount,
}) => {
  return (
    <div
      className={clsx(
        styles.athleteSelectionHeader,
        hideBottom && styles.hideBottom,
      )}
    >
      <div className={styles.athleteLeft}>
        <span className={styles.athleteText}>Groups</span>
        <span className={styles.athleteCount}>{athleteCount}</span>
      </div>
      <CoachInfo className={styles.coachButton} />
    </div>
  );
};
