import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from "const";
import { ReactComponent as VerticalDots } from "images/vertical_dots.svg";

import styles from "./athlete-card.module.scss";
import { countryCodesMapping } from "country-codes-mapping";

import { getAge, parseDate } from "utils";
import { Athlete } from "types";
import { DropdownMenuItemComponent } from "components/dropdown-profile-btns/dropdown-profile";
import {
  HRV_RHR_LIGHT_GREEN,
  HRV_RHR_DARK_GREEN,
  HRV_RHR_LIGHT_YELLOW,
  HRV_RHR_DARK_ORANGE,
  COLOR_WHITE,
  HRV_RHR_YELLOW,
  HRV_RHR_ORANGE,
  HRV_RHR_RED,
  MCP_LIGHT_GREEN,
  MCP_MEDIUM_GREEN,
  MCP_DARK_GREEN,
  MCP_LIGHT_ORANGE,
  MCP_MEDIUM_ORANGE,
  MCP_DARK_RED,
} from "components/charts/colors";
import { ReactComponent as ActiveMedicalReportIcon } from "clients/uaeMen/images/ActiveMedicalReportIcon.svg";
import { BodyMap } from "components/body-map/body-map";

type Props = {
  athlete?: Athlete;
  onClick?: () => void;
  togglePopup: (popup: keyof AthletePopupState) => void;
  setSelectedAthlete: Dispatch<SetStateAction<Athlete | undefined>>;
  activeMedicalReport?: boolean;
  hoveredAthleteId?: number | null;
  setHoveredAthleteId?: (id: number | null) => void;
};

export type AthletePopupState = {
  anthropometry: boolean;
  weight: boolean;
  illness: boolean;
  physiotherapy: boolean;
  injury: boolean;
};

export const AthleteCard: FC<Props> = ({
  athlete,
  onClick,
  togglePopup,
  setSelectedAthlete,
  activeMedicalReport,
  hoveredAthleteId,
  setHoveredAthleteId,
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };

    const handleEscapePress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapePress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapePress);
    };
  }, []);

  const toggleDropdown = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    setDropdownVisible(!isDropdownVisible);
  };

  const age = athlete && getAge(parseDate(athlete.dateOfBirth));
  const groupName = athlete?.group?.map((item) => item?.name).join(", ");
  const countryAlpha2Code =
    athlete?.countryCode && countryCodesMapping[athlete.countryCode]
      ? countryCodesMapping[athlete?.countryCode]
      : "AE";
  const flagUrl = `${DEFAULT_CDN_URL}${countryAlpha2Code.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;

  const getRHRVariance = (value: number | undefined) => {
    if (!value) {
      return null;
    }
    let color = COLOR_WHITE;
    if (value > 2 && value < 4) {
      color = HRV_RHR_LIGHT_YELLOW;
    } else if (value >= 4) {
      color = HRV_RHR_DARK_ORANGE;
    } else if (value < -2 && value > -4) {
      color = HRV_RHR_LIGHT_GREEN;
    } else if (value <= -4) {
      color = HRV_RHR_DARK_GREEN;
    }
    const sign = value > 0 ? "+" : "";
    const signValue = `${sign}${value.toFixed(0)}`;
    return (
      <div className={styles.units} style={{ color }}>
        ({signValue})
      </div>
    );
  };

  const getHRVVariance = (value: number | undefined) => {
    if (!value) {
      return null;
    }
    let color = COLOR_WHITE;
    if (value > 5 && value < 10) {
      color = HRV_RHR_LIGHT_GREEN;
    } else if (value >= 10) {
      color = HRV_RHR_DARK_GREEN;
    }

    if (value > -10 && value < -5) {
      color = HRV_RHR_LIGHT_YELLOW;
    } else if (value < -10) {
      color = HRV_RHR_DARK_ORANGE;
    }

    const formattedHRVVariance = `${value > 0 ? "+" : ""}${value.toFixed(0)}`;
    return (
      <div className={styles.units} style={{ color }}>
        ({formattedHRVVariance})
      </div>
    );
  };

  function getHRVRHRFeedback(
    rhrVariance: number | undefined,
    hrvVariance: number | undefined,
  ) {
    if (!rhrVariance || !hrvVariance) {
      return null;
    }

    if (hrvVariance >= 10 && rhrVariance <= -4) {
      return (
        <div
          className={styles.athleteBottomStrip}
          style={{ background: HRV_RHR_DARK_GREEN }}
          data-tooltip={
            "Your HRV is significantly higher and your RHR is moderately lower than your 7-day baseline, indicating excellent recovery. You are well-prepared for high-intensity training today."
          }
        ></div>
      );
    }

    if (hrvVariance > 5 && rhrVariance < -2) {
      return (
        <div
          className={styles.athleteBottomStrip}
          style={{ background: HRV_RHR_LIGHT_GREEN }}
          data-tooltip={
            "Your HRV is moderately higher and your RHR is slightly lower than your 7-day baseline, suggesting good recovery. Proceed with planned training and consider adding some intensity."
          }
        ></div>
      );
    }

    if (hrvVariance < -5 && rhrVariance > 2) {
      return (
        <div
          className={styles.athleteBottomStrip}
          style={{ background: HRV_RHR_LIGHT_YELLOW }}
          data-tooltip={
            "Your HRV is moderately lower and your RHR is slightly higher than your 7-day baseline, suggesting some fatigue or stress. Consider a lighter training session today."
          }
        ></div>
      );
    }

    if (hrvVariance <= -10 && rhrVariance >= 4) {
      return (
        <div
          className={styles.athleteBottomStrip}
          style={{ background: HRV_RHR_RED }}
          data-tooltip={
            "Both your HRV and RHR indicate significant stress and insufficient recovery. This combination strongly suggests reducing training load, focusing on recovery activities, and ensuring good sleep and nutrition."
          }
        ></div>
      );
    }

    if (hrvVariance > 5 && rhrVariance > 2) {
      return (
        <div
          className={styles.athleteBottomStrip}
          style={{ background: HRV_RHR_YELLOW }}
          data-tooltip={
            "While your HRV is moderately higher, indicating good recovery, your RHR is slightly elevated. Consider a moderate-intensity workout and monitor how you feel throughout the day."
          }
        ></div>
      );
    }

    if (hrvVariance < -5 && rhrVariance < -2) {
      return (
        <div
          className={styles.athleteBottomStrip}
          style={{ background: HRV_RHR_ORANGE }}
          data-tooltip={
            "Your HRV is moderately lower, and your RHR is slightly lower, indicating possible overreaching or fatigue despite autonomic recovery. Consider a light to moderate session focused on technique or low-intensity endurance today."
          }
        ></div>
      );
    }

    return null;
  }

  const getMcpVariance = (value: number | undefined) => {
    if (!value) {
      return null;
    }

    let color = COLOR_WHITE;

    if (value === 0) {
      color = COLOR_WHITE;
    } else if (value > 0 && value <= 10) {
      color = MCP_LIGHT_GREEN; // #A4D7A7
    } else if (value >= 11 && value <= 20) {
      color = MCP_MEDIUM_GREEN; // #4CAF50
    } else if (value >= 21) {
      color = MCP_DARK_GREEN; // #2E7D32
    } else if (value < 0 && value >= -10) {
      color = MCP_LIGHT_ORANGE; // #FFCC80
    } else if (value <= -11 && value >= -20) {
      color = MCP_MEDIUM_ORANGE; // #FF9800
    } else if (value <= -21) {
      color = MCP_DARK_RED; // #B71C1C
    }

    const sign = value > 0 ? "+" : "";
    const signValue = `${sign}${value.toFixed(0)}`;
    return (
      <span className={styles.units} style={{ color }}>
        ({signValue})
      </span>
    );
  };

  const getMcpVariancePercentage = (value: number | undefined) => {
    if (!value) {
      return null;
    }
    let color = COLOR_WHITE;

    if (value === 0) {
      color = COLOR_WHITE;
    } else if (value > 0 && value <= 5) {
      color = MCP_LIGHT_GREEN; // #A4D7A7
    } else if (value >= 6 && value <= 10) {
      color = MCP_MEDIUM_GREEN; // #4CAF50
    } else if (value >= 11) {
      color = MCP_DARK_GREEN; // #2E7D32
    } else if (value < 0 && value >= -5) {
      color = MCP_LIGHT_ORANGE; // #FFCC80
    } else if (value <= -6 && value >= -10) {
      color = MCP_MEDIUM_ORANGE; // #FF9800
    } else if (value <= -11) {
      color = MCP_DARK_RED; // #B71C1C
    }

    const sign = value > 0 ? "+" : "";
    const signValue = `${sign}${value.toFixed(0)}%`;
    return (
      <span className={styles.units} style={{ color }}>
        ({signValue})
      </span>
    );
  };

  const handleMouseEnter = (athleteDetails: any) => {
    setHoveredAthleteId?.(athleteDetails?.id);
  };

  const handleMouseLeave = () => {
    setHoveredAthleteId?.(null);
  };
  return (
    <div
      className={styles.atheteCard}
      onClick={onClick}
      onMouseLeave={handleMouseLeave}
    >
      {hoveredAthleteId === athlete?.id ? (
        <div className={styles.bodyMapMain}>
          <BodyMap
            recordType={athlete?.medicalReport?.recordType || ""}
            selectedSide={athlete?.medicalReport?.side || "BOTH"}
            selectedBodyLocation={athlete?.medicalReport?.bodyLocation || ""}
            selectedArea={athlete?.medicalReport?.area || ""}
            selectedSymptom={athlete?.medicalReport?.symptom || ""}
            selectedSeverity={athlete?.medicalReport?.severity || ""}
            isAthleteCard={true}
          />
        </div>
      ) : (
        <>
          <div className={styles.athleteMain}>
            <div className={styles.athleteImage}>
              <img src={athlete?.picture} alt={athlete?.firstName} />
              <div className={styles.athleteName}>
                <strong>{athlete?.lastName} </strong>
                <span>{athlete?.firstName}</span>
              </div>
              <div className={styles.athleteFlag}>
                <img src={flagUrl} alt={athlete?.countryCode} />
              </div>
              <div className={styles.athleteOptions} onClick={toggleDropdown}>
                <VerticalDots className={styles.verticalDots} />
                {isDropdownVisible && (
                  <div className={styles.dropdown}>
                    <ul className={styles.dropdownMenu}>
                      <li>
                        <DropdownMenuItemComponent
                          text="Anthropometry"
                          className={styles.dropdownMenu}
                          handleClick={async (event) => {
                            event.stopPropagation();
                            setSelectedAthlete(athlete);
                            setDropdownVisible(false);
                            return togglePopup("anthropometry");
                          }}
                        />
                        <DropdownMenuItemComponent
                          text="Weight"
                          className={styles.dropdownMenu}
                          handleClick={async (event) => {
                            event.stopPropagation();
                            setSelectedAthlete(athlete);
                            setDropdownVisible(false);
                            return togglePopup("weight");
                          }}
                        />
                        <DropdownMenuItemComponent
                          text="Report Illness"
                          className={styles.dropdownMenu}
                          handleClick={async (event) => {
                            event.stopPropagation();
                            setSelectedAthlete(athlete);
                            setDropdownVisible(false);
                            return togglePopup("illness");
                          }}
                        />
                        <DropdownMenuItemComponent
                          text="Report Rehabilitation"
                          className={styles.dropdownMenu}
                          handleClick={async (event) => {
                            event.stopPropagation();
                            setSelectedAthlete(athlete);
                            setDropdownVisible(false);
                            return togglePopup("physiotherapy");
                          }}
                        />
                        <DropdownMenuItemComponent
                          text="Report Injury"
                          className={styles.dropdownMenu}
                          handleClick={async (event) => {
                            event.stopPropagation();
                            setSelectedAthlete(athlete);
                            setDropdownVisible(false);
                            return togglePopup("injury");
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.athleteDetails}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={styles.groupName}>{groupName}</div>
              {activeMedicalReport && (
                <ActiveMedicalReportIcon
                  className={styles.activeMedicalReportIcon}
                  onMouseEnter={() => handleMouseEnter(athlete)}
                />
              )}
            </div>
            <div className={styles.athleteAdditionalDetails}>
              <div className={styles.xAvatarComponents3}>
                <div className={styles.yrs}>{age} Yrs</div>
              </div>
              <div className={styles.frameChild} />
              <div className={styles.xAvatarComponents3}>
                <div className={styles.yrs}>H: {athlete?.height || "--"}</div>
                <div className={styles.yrs}>W: {athlete?.weight || "--"}</div>
              </div>
            </div>
          </div>
          <div className={styles.athleteMisc}>
            <div className={styles.performanceScores}>
              <div className={styles.performanceScore}>
                <div className={styles.performanceScoreTitle}>CTL</div>
                <div className={styles.performanceScoreValue}>
                  {athlete?.ctl?.toFixed(0) || "--"}
                </div>
              </div>
              <div className={styles.performanceScore}>
                <div className={styles.performanceScoreTitle}>ATL</div>
                <div className={styles.performanceScoreValue}>
                  {athlete?.atl?.toFixed(0) || "--"}
                </div>
              </div>
              <div className={styles.performanceScore}>
                <div className={styles.performanceScoreTitle}>TSB</div>
                <div
                  className={styles.performanceScoreValue}
                  style={
                    athlete?.tsb === undefined
                      ? undefined
                      : {
                          color:
                            athlete?.tsb > 25
                              ? "#DEA754"
                              : athlete?.tsb <= -30
                                ? "#F93939"
                                : undefined,
                        }
                  }
                >
                  {athlete?.tsb?.toFixed(0) || "--"}
                </div>
              </div>
            </div>
            <div className={styles.relativeScores}>
              <div className={styles.leftSection}>
                <div className={styles.relativeScoreTitle}>mCP</div>
                <div className={styles.relativeScoreValues}>
                  <span className={styles.unitsValue}>
                    {getMcpVariancePercentage(
                      athlete?.mCPRelativeVariancePercentage,
                    )}
                    {athlete?.mCPRelative?.toFixed(2) || "--"}
                    <span className={styles.units}>W/kg</span>
                  </span>
                  <span className={styles.unitsValue}>
                    {getMcpVariance(athlete?.mCPVariance)}
                    {athlete?.mcp?.toFixed(0) || "--"}
                    <span className={styles.units}>W</span>
                  </span>
                </div>
              </div>
              <div className={styles.rightSection}>
                <div className={styles.relativeScoreTitle}>VO2Max</div>
                <div className={styles.relativeScoreValues}>
                  <span>
                    {athlete?.vo2Max?.toFixed(1) || "--"}{" "}
                    <span className={styles.units}>ml/kg</span>
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.heartRateScores}>
              <div className={styles.heartRateScore}>
                <div className={styles.heartRateScoreTitle}>RHR</div>
                {getRHRVariance(athlete?.rhrVariance)}
                <div className={styles.heartRateScoreValue}>
                  {athlete?.rhr?.toFixed(0) || "--"}
                </div>
              </div>
              <div className={styles.heartRateScore}>
                <div className={styles.heartRateScoreTitle}>HRV</div>
                {getHRVVariance(athlete?.hrvVariance)}
                <div className={styles.heartRateScoreValue}>
                  {athlete?.hrv?.toFixed(0) || "--"}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {getHRVRHRFeedback(athlete?.rhrVariance, athlete?.hrvVariance)}
    </div>
  );
};
