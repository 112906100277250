import {
  TypedUseSelectorHook,
  useDispatch as useReactDispatch,
  useSelector as useReactSelector,
} from "react-redux";
import type { RootState, AppDispatch } from "store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch = () => useReactDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useReactSelector;
