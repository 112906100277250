import type { ITagProps, ITagSize } from "./types.ts";
import type { MouseEvent } from "react";
import { useCallback } from "react";
import "./index.css";
import { X } from "lucide-react";
import { clsx } from "clsx";
import { ITypographyVariant } from "../Typography/types.ts";
import { Typography } from "../Typography";

export const Tag = ({
  variant = "neutral",
  size = "s",
  shape = "rounded",
  text,
  startIcon,
  onRemove,
  onClick,
  disabled,
  className,
}: ITagProps) => {
  const rootClass: string = clsx(
    "analog-tag",
    `analog-tag--${variant}`,
    `analog-tag--${size}`,
    `analog-tag--${shape}`,
    !disabled && onClick && "analog-tag--clickable",
    className,
  );

  const typographyMap: Record<ITagSize, ITypographyVariant> = {
    s: "subtitle",
    m: "body",
  };

  const handleClick = useCallback(
    (e: MouseEvent): void => {
      e.stopPropagation();
      if (disabled) {
        return;
      }
      if (onClick) {
        onClick(e);
      }
    },
    [onClick, disabled],
  );

  const handleRemove = useCallback(
    (e: MouseEvent): void => {
      e.stopPropagation();
      if (disabled) {
        return;
      }
      if (onRemove) {
        onRemove(e);
      }
    },
    [onRemove, disabled],
  );

  return (
    <div className={rootClass} onClick={handleClick}>
      {startIcon && <div className="analog-tag__icon">{startIcon}</div>}
      <Typography
        as="div"
        className="analog-tag__text"
        variant={typographyMap[size]}
        text={text}
      />
      {!disabled && onRemove && (
        <button className="analog-tag__remove" onClick={handleRemove}>
          <X size={16} />
        </button>
      )}
    </div>
  );
};
