import { useForm } from "react-hook-form";
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AuthParameter } from "../../types";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../store";
import {
  setAccount,
  setAuthState,
  setUserName,
} from "../../store/slices/auth.ts";
import { RoleType } from "../../enums.ts";
import { setCurrentComparison } from "../../store/slices/shared.ts";
import { login } from "../../api/auth.ts";
import { AxiosError } from "axios";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { intercept } from "../../axios-instance/axios-instance.ts";

export const useViewModel = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    let rememberURL = localStorage.getItem("rememberURL");

    if (rememberURL === "undefined") {
      rememberURL = null;
      localStorage.removeItem("rememberURL");
    }

    if (!rememberURL) {
      // @ts-ignore
      localStorage.setItem("rememberURL", location.state?.from?.pathname);
    }
  }, [location]);

  const { instance, inProgress } = useMsal();
  const stayIdle =
    inProgress === InteractionStatus.Startup ||
    inProgress === InteractionStatus.HandleRedirect;

  const schema = useMemo(() => {
    return z
      .object({
        username: z.string().min(1, { message: t("validation.required") }),
        password: z.string().min(1, { message: t("validation.required") }),
        remember: z.boolean(),
      })
      .passthrough();
  }, [t]);

  const form = useForm<AuthParameter>({
    defaultValues: {
      username: "",
      password: "",
      remember: false,
    },
    resolver: zodResolver(schema),
  });
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = form;
  const [remember] = watch(["remember"]);

  const handleSignIn = useCallback(
    async (formData: AuthParameter) => {
      setLoading(true);
      setErrorMessage("");

      return login(formData)
        .then((response) => {
          if (response?.data) {
            if (response?.data?.role === RoleType.cyclist) {
              dispatch(setCurrentComparison(undefined));
            }

            const accessToken = response?.data?.accessToken;
            dispatch(setAuthState(true, accessToken));
            if (accessToken) {
              intercept();
            }

            dispatch(setUserName(formData.username));
            dispatch(setAccount(response?.data?.role ?? RoleType.coach));

            if (response?.data?.role === RoleType.coach) {
              let rememberURL = localStorage.getItem("rememberURL");

              if (rememberURL === "undefined") {
                rememberURL = null;
                localStorage.removeItem("rememberURL");
              }

              if (rememberURL) {
                history.push(rememberURL);
                localStorage.removeItem("rememberURL");
              } else {
                history.push("/wellness");
              }
            }
          } else {
          }
          setLoading(false);
        })
        .catch((e: AxiosError) => {
          if (e.status === 403) {
            setErrorMessage(e.message ?? "");
          } else {
            setErrorMessage(t("error.credentials_do_not_match"));
          }
          setLoading(false);
        });
    },
    [dispatch, history, t],
  );

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      handleSubmit((formData: AuthParameter) => {
        setLoading(true);
        handleSignIn(formData);
      })();
    },
    [handleSignIn, handleSubmit],
  );

  const handleRememberChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue("remember", e.target.checked);
    },
    [setValue],
  );

  const signInWithMicrosoft = useCallback(() => {
    const loginRequest = {
      scopes: ["User.Read"],
    };

    instance.loginRedirect(loginRequest).catch((error) => {
      console.log(error);
    });
  }, [instance]);

  return {
    form,
    onSubmit,
    remember,
    handleRememberChange,
    errorMessage,
    errors,
    loading,
    signInWithMicrosoft,
    stayIdle,
  };
};
