import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { SideTabs } from "components/side-tabs/side-tabs";

import styles from "./tabs-layout.module.scss";
import { FlavorType } from "enums";

export enum Tabs {
  OverallWellnessTab,
  BodyCompositionTab,
  HeartMeasurementsTab,
  TrainingTab,
  PowerDerivativesTab,
  FatigueResistanceTab,
  FitnessFatigueTab,
  MenstrualCycleTab,
  TemperatureChartTab,
}

type ActiveTabsMap = {
  [key: number]: boolean;
};

type Props = {
  tabs: {
    id: Tabs;
    name: string;
  }[];
  componentMap: { [key: number]: ReactNode };
};

export const TabsLayout: FC<Props> = ({ tabs, componentMap }) => {
  const clientType = process.env.REACT_APP_FLAVOR as FlavorType;
  const tabsToRenderRef = useRef(tabs);
  const componentsRef = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [activeTabs, setActiveTabs] = useState<ActiveTabsMap>({});

  useEffect(() => {
    const tabsToFilterOut = [Tabs.MenstrualCycleTab, Tabs.TemperatureChartTab];
    if (clientType === FlavorType.uaeMen) {
      tabsToRenderRef.current = tabs.filter(
        (tab) => !tabsToFilterOut.includes(tab.id),
      );
    } else {
      tabsToRenderRef.current = tabs;
    }
  }, [clientType, tabs]);

  useEffect(() => {
    const newTabs: ActiveTabsMap = {};
    tabsToRenderRef.current.forEach((tab, index) => {
      newTabs[tab.id] = index === 0;
    });
    setActiveTabs(newTabs);
  }, [tabs]);

  const tabClickHandler = useCallback(
    (tabId: Tabs) => {
      const newTabs = { ...activeTabs, [tabId]: !activeTabs[tabId] };
      setActiveTabs(newTabs);
      setTimeout(() => {
        componentsRef.current[`${tabId}`]?.scrollIntoView({
          behavior: "smooth",
        });
      }, 0);
    },
    [activeTabs],
  );

  return (
    <>
      <SideTabs
        tabs={tabsToRenderRef.current}
        activeTabs={activeTabs}
        onClick={tabClickHandler}
      />
      <div className={styles.charts}>
        {Object.keys(componentMap)
          .map(Number)
          .filter((key: Tabs) => activeTabs[key])
          .map((key: Tabs) => (
            <div
              key={key}
              ref={(el) => {
                componentsRef.current[key] = el;
              }}
              className={styles.component}
            >
              {componentMap[key]}
            </div>
          ))}
      </div>
    </>
  );
};
