import React, { FC } from "react";
import Select from "react-windowed-select";
import clsx from "clsx";

import { DateRange } from "enums";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { getCurrentDateRange, setCurrentDateRange } from "store/slices/shared";

import { ReactComponent as CalendarPrimary } from "images/calendar-primary.svg";

import styles from "./date-selector.module.scss";
import "./date-selector.overwrite.scss";
import { useAppDispatch } from "../../store";

interface SelectorOption {
  label: string;
  value: DateRange;
}

const options: SelectorOption[] = [
  {
    label: "Last week",
    value: DateRange.Week,
  },
  {
    label: "Last 30 days",
    value: DateRange.Month,
  },
  {
    label: "Last 60 days",
    value: DateRange.TwoMonth,
  },
  {
    label: "Last 90 days",
    value: DateRange.ThreeMonth,
  },
  {
    label: "Last 365 days",
    value: DateRange.Year,
  },
];

interface Props {
  small?: boolean;
  header?: boolean;
}

export const DateSelector: FC<Props> = ({ small, header }) => {
  const dispatch = useAppDispatch();
  const currentDateRange = useSelector(getCurrentDateRange);

  const value: SelectorOption | undefined = options.find(
    (option) => option.value === currentDateRange,
  );
  const handleChange = (newOption: SelectorOption) => {
    dispatch(setCurrentDateRange(newOption.value));
  };

  return (
    <div className={styles.wrapper}>
      {!small && <CalendarPrimary className={styles.calendar} />}
      {/* @ts-ignore */}
      <Select
        isClearable={false}
        isSearchable={false}
        options={options}
        value={value}
        defaultValue={value}
        onChange={handleChange}
        className={clsx(
          "date-selector__select",
          small && "date-selector__select--small",
          header && "date-selector__select--header",
        )}
        classNamePrefix="react-select"
      />
    </div>
  );
};
