import { ChatHistoryResponse, ChatMessage } from "types";
import { axiosInstance } from "axios-instance/axios-instance";
import { FlavorType } from "enums";
import { trackError } from "appInsights/logInsights";

export const getChatMessage = async (
  athleteId: number,
  message: string,
): Promise<string> => {
  const resp = await axiosInstance.get(
    `/ai/chat/${athleteId}?message=${message}`,
  );
  return resp.data;
};

export const getChatHistory = async (
  athleteId: number,
): Promise<ChatHistoryResponse | null> => {
  try {
    const resp = await axiosInstance.get(
      `/ai/v1/chat/athlete/${athleteId}/history`,
    );
    // Check if the response status is 200 (OK)
    if (resp.status === 200) {
      return resp.data;
    }

    // If you encounter other success codes you want to handle, you can add them here
    console.warn(`Unexpected response status: ${resp.status}`);
    return null;
  } catch (error: any) {
    return null;
  }
};

export const getChatQuery = async (
  athleteId: number,
  query: string,
  threadID: string | null | undefined,
  assistantID: string | undefined | null,
): Promise<ChatMessage | null> => {
  const flavorType = process.env.REACT_APP_FLAVOR;
  const team = flavorType === FlavorType.adqWomen ? "women" : "men";
  const subsequentQuery = threadID
    ? `&thread_id=${threadID}&assistant_id=${assistantID}`
    : "";
  try {
    const requestBody = {
      query: query,
      thread_id: threadID,
      assistant_id: assistantID,
    };

    const resp = await axiosInstance.post(
      `/ai/v1/chat/athlete/${athleteId}/ask`,
      requestBody,
    );
    return resp.data?.length > 0 ? resp.data[0] : [];
  } catch (err) {
    console.error("Failed to get chat query:", err);
    return null;
  }
};

// export const getChatMessage = async (
//   athleteId: number,
//   message: string,
//   onMessage: (message: string) => void
// ) => {
//   const token = localStorage.getItem("token");
//   const endpoint = `${API_ENDPOINT}/ai/chat/${athleteId}?message=${message}`;
//   try {
//     await fetchEventSource(endpoint, {
//       headers: token ? { Authorization: `${token}` } : {},
//       method: "GET", // Optional: Specify the request method (default is GET)
//       onmessage: (event) => onMessage(event.data),
//       onerror: (err) => {
//         console.error("SSE Error:", err);
//         // Optional: Add reconnection logic here
//       },
//       // Optional: Add other Fetch API options (e.g., signal, mode)
//     });
//   } catch (error) {
//     console.error("SSE connection failed:", error);
//   }
// };

// export const getChatMessage = (
//   athleteId: number,
//   message: string,
//   onMessage: (message: string) => void
// ) => {
//   const token = localStorage.getItem("token");
//   const eventSourceInitDict = {
//     headers: {
//       Authorization: token,
//     },
//   } as EventSourceInit;

//   const eventSource = new EventSource(
//     `${API_ENDPOINT}/ai/chat/${athleteId}?message=${message}`,
//     eventSourceInitDict
//   );

//   eventSource.onmessage = (event) => {
//     onMessage(event.data);
//   };

//   eventSource.onerror = (error) => {
//     console.error("SSE Error:", error);

//     // Optional: Add reconnection logic here
//     // setTimeout(() => getChatMessage(message, athleteId, onMessage), 5000); // Retry after 5 seconds
//   };

//   return eventSource;
// };

export const getUserChatFeedback = async (
  athleteId: number | undefined,
  threadId: string | null,
  messageId: string | undefined,
  selectedIcon: string,
  tag: string,
  userMessage: string,
) => {
  try {
    const data = {
      message_id: messageId,
      thread_id: threadId,
      feedback_type: selectedIcon,
      tags: [tag],
      user_message: userMessage,
    };
    const resp = await axiosInstance.post(
      `/ai/v1/chat/athlete/${athleteId}/feedback`,
      data,
    );

    if (resp.status === 200) {
      return resp.data;
    }

    return null;
  } catch (error: any) {
    trackError(error as Error, { location: "getUserChatFeedback" });
    return null;
  }
};

export const clearConversation = async (athleteId: number | undefined) => {
  try {
    const resp = await axiosInstance.post(
      `/ai/v1/chat/athlete/${athleteId}/close_conversation`,
      {
        reason: "by user from chat window",
      },
    );

    if (resp.status === 200) {
      return resp.data;
    }

    return null;
  } catch (error: any) {
    trackError(error as Error, { location: "clearConversation" });
    return null;
  }
};
