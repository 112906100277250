import { formatDateAPI } from "../utils";
import { AnthropometryMeasurements, APIResponse } from "types";
import { FormState } from "../components/add-data/anthropometry-popup/anthropometry-popup";
import { axiosInstance } from "axios-instance/axios-instance";
import { trackError } from "appInsights/logInsights";
import { calculateDateRange } from "utils";
import { DateRange } from "enums";

export const submitAnthropometry = async (
  athleteId: number,
  form: FormState,
  dateOfAnthro: string,
): Promise<APIResponse<any> | null> => {
  const requestBody: AnthropometryMeasurements = {
    athleteId,
    height: parseFloat(form.height),
    weight: parseFloat(form.weight),
    biceps: parseFloat(form.biceps),
    triceps: parseFloat(form.triceps),
    subscapula: parseFloat(form.subscapula),
    iliacCrest: parseFloat(form.iliacCrest),
    abdominal: parseFloat(form.abdominal),
    thigh: parseFloat(form.thigh),
    calf: parseFloat(form.calf),
    supraspinale: parseFloat(form.supraspinale),
    date: dateOfAnthro,
  };
  try {
    const response: APIResponse<any> = await axiosInstance.post(
      "/coach/input/anthro",
      requestBody,
    );
    return response.data || null;
  } catch (err: any) {
    return err.response ? err.response.data : null;
  }
};

interface LastAnthroDataResponse {
  source: string;
  filled: boolean;
  date: string;
  data: AnthropometryMeasurements;
}

interface ApiResponse {
  code: number;
  message: string;
  data: LastAnthroDataResponse;
}

interface AnthrosApiResponse {
  code: number;
  message: string;
  data: AnthropometryMeasurements[];
}

export const getLastAnthro = async (
  athleteId: number,
): Promise<AnthropometryMeasurements | null> => {
  try {
    const response = await axiosInstance.get<ApiResponse>(
      `/coach/lastAnthro?athleteId=${athleteId}`,
    );

    if (response.data && response.data.data) {
      return response.data.data.data;
    } else {
      return null;
    }
  } catch (err: any) {
    trackError(err as Error, { location: "getLastAnthro" });
    console.error(err);
    return null;
  }
};

export const getAllAnthros = async (
  athleteId: number,
): Promise<AnthropometryMeasurements[] | null> => {
  const { startDate, endDate } = calculateDateRange(DateRange.Year);
  try {
    const response = await axiosInstance.get<AnthrosApiResponse>(
      `/coach/anthros?athleteId=${athleteId}&startDate=${formatDateAPI(
        startDate,
      )}&endDate=${formatDateAPI(endDate)}`,
    );

    if (response.data && response.data.data?.length > 0) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (err: any) {
    trackError(err as Error, { location: "getAllAnthros" });
    console.error(err);
    return null;
  }
};
