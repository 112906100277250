import { useCallback, useState } from "react";

export type IUseControlledValueProps<T> = {
  controlledValue?: T;
  controlledOnChange?: (value: T) => void;
  defaultValue?: T;
};

export const useControlledValue = <T>({
  controlledValue,
  controlledOnChange,
  defaultValue,
}: IUseControlledValueProps<T>) => {
  const [uncontrolledValue, setUncontrolledValue] = useState<T | undefined>(
    defaultValue,
  );

  const uncontrolledOnChange = useCallback((value: T) => {
    setUncontrolledValue(value);
  }, []);

  const value =
    controlledValue !== undefined ? controlledValue : uncontrolledValue;

  const onChange =
    controlledOnChange !== undefined
      ? controlledOnChange
      : uncontrolledOnChange;

  return {
    value,
    onChange,
  };
};
