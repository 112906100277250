export const GroupMenuItems = [
  {
    to: "/group-profiles",
    text: "Profiles",
  },
  {
    to: "/group-overview",
    text: "Overview",
  },
  {
    to: "/group-chart-view",
    text: "Charts",
  },
];

export const AtheleteGroupItems = [
  {
    to: "/athlete-selection",
    text: "Profiles",
  },
  {
    to: "/group-overview",
    text: "Overview",
  },
  {
    to: "/group-chart-view",
    text: "Charts",
  },
];
