import React, { FC } from "react";

import { roundFloat } from "utils";
import { BarColors } from "enums";

import { Bar } from "components/score-bars/bar/bar";

import styles from "./score-bars.module.scss";

export type BarItem = {
  name: string;
  athleteValue?: number;
  comparisonValue?: number;
};

type Props = {
  items: BarItem[];
  color: string;
  multiplier?: number;
};

export const ScoreBars: FC<Props> = ({ items, color, multiplier = 1 }) => {
  return (
    <div className={styles.container}>
      {items.map((item) => (
        <div className={styles.row} key={item.name}>
          <div className={styles.name}>{item.name}</div>
          <div className={styles.bars}>
            <Bar value={(item.athleteValue || 0) * multiplier} color={color} />
            {item.comparisonValue !== undefined && (
              <Bar
                value={(item.comparisonValue || 0) * multiplier}
                color={BarColors.Default}
              />
            )}
          </div>
          <div className={styles.values}>
            <div className={styles.value}>
              {roundFloat(item.athleteValue, 1)}
            </div>
            {item.comparisonValue !== undefined && (
              <div className={styles.value}>
                {roundFloat(item.comparisonValue, 1)}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
