import React, { FC, useState, useEffect } from "react";
import clsx from "clsx";
import { ReactComponent as CircleIcon } from "images/circle.svg";
import { ReactComponent as CircleDashedIcon } from "images/circle-dashed.svg";

import { ChartColor } from "components/charts/types";
import { COLOR_GRAY } from "components/charts/colors";
import { ChartSize } from "components/charts/constants";

import { EChartAction } from "hocs/with-header/with-header";

import styles from "./legend-button.module.scss";

type Range = {
  color: string;
  min: number | null;
  max: number | null;
  title: string;
};

type Props = {
  name: string;
  type: ChartSize;
  color?: ChartColor;
  onEChartAction?: (event: EChartAction) => void;
  dashed?: boolean;
  className?: string;
  showMainCircle?: boolean;
  showSubCircles?: boolean;
  ranges?: Range[];
  savedLabels?: () => Promise<void>;
  active?: boolean;
};

const isString = (color: ChartColor): color is string =>
  typeof color === "string";

const LegendButton: FC<Props> = ({
  name,
  color = COLOR_GRAY,
  type = ChartSize.Small,
  onEChartAction,
  dashed,
  className,
  showMainCircle = true,
  showSubCircles = true,
  ranges,
  savedLabels,
  active: initialActive = true,
}) => {
  const [active, setActive] = useState(initialActive);

  useEffect(() => {
    setActive(initialActive);
  }, [initialActive]);

  const handleClick = async () => {
    if (onEChartAction) {
      onEChartAction({
        type: "legendToggleSelect",
        name,
      });
      setActive(!active);
      await savedLabels?.();
    }
  };

  const handleMouseEnter = () => {
    if (onEChartAction) {
      onEChartAction({
        type: "highlight",
        seriesName: name,
      });
    }
  };

  const handleMouseLeave = () => {
    if (onEChartAction) {
      onEChartAction({
        type: "downplay",
        seriesName: name,
      });
    }
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={clsx(
        type === ChartSize.Big
          ? styles.bigChartLegend
          : styles.smallChartLegend,
        !active && styles.disabled,
        onEChartAction && styles.clickable,
        className,
      )}
    >
      {dashed ? (
        <CircleDashedIcon
          className={styles.icon}
          style={{ stroke: isString(color) ? color : "white" }}
        />
      ) : (
        showMainCircle && (
          <CircleIcon
            className={styles.icon}
            style={{ fill: isString(color) ? color : "white" }}
          />
        )
      )}
      {name}
      <div className={clsx(styles.ranges, !ranges && styles.noRanges)}>
        {ranges?.map((range) => (
          <div key={range.color}>
            {showSubCircles && (
              <CircleIcon
                className={styles.icon}
                style={{ fill: range.color }}
              />
            )}
            {range.title}{" "}
            <span
              style={{
                color: showSubCircles === false ? range.color : undefined,
              }}
            >
              {range.max === Infinity
                ? `>${range.min}`
                : range.min === -Infinity
                  ? `<=${range.max}`
                  : `>${range.min}`}
            </span>
          </div>
        ))}
      </div>
    </button>
  );
};

export const MemoizedLegendButton = React.memo(LegendButton);
