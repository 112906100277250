import s from "./index.module.css";
import { useContext } from "react";
import { AthleteCardContext } from "../../../../context";
import { Skeleton } from "../../../../../../storybook";

export const VO2Max = () => {
  const { athlete, athleteLoading } = useContext(AthleteCardContext);

  if (athleteLoading) {
    return <Skeleton width="140px" height="48px" />;
  }

  return (
    <div className={s.cardItemVertical}>
      <div className={`${s.tokenSecondary} analog-typography--headline`}>
        VO2max
      </div>
      <div className={`${s.statValue} analog-typography--headline`}>
        <span className={s.tokenPrimary}>
          {" "}
          {athlete?.vo2Max?.toFixed(1) || "--"}
        </span>
        <span className={`${s.tokenPlaceholder} analog-typography--body`}>
          ml/kg
        </span>
      </div>
    </div>
  );
};
