import { useContext, useEffect, useRef, useState } from "react";
import { Input } from "../Input";
import { Search } from "lucide-react";
import { Spinner } from "../Spinner";
import throttle from "lodash/throttle";
import { SelectContext } from "./context.tsx";
import { useViewModelContent } from "./useViewModelContent.tsx";

export const SelectContent = () => {
  const { getContent, searchValue, handleSearch, handleClearSearch } =
    useViewModelContent();
  const { onLazyLoad, search, footer } = useContext(SelectContext);
  const scrollViewRef = useRef<HTMLDivElement | null>(null);
  const prevScrollTop = useRef<number>(0);
  const [lazyLoading, setLazyLoading] = useState<boolean>(false);
  const syncLazyLoading = useRef<boolean>(false);
  const LAZY_LOAD_OFFSET = 360;
  const LAZY_LOAD_THROTTLE = 350;

  useEffect(() => {
    return () => {
      handleClearSearch();
    };
  }, [handleClearSearch]);

  const handleScroll = throttle(() => {
    const scrollableElement = scrollViewRef.current;

    if (!onLazyLoad || !scrollableElement || syncLazyLoading.current) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = scrollableElement;
    const direction = scrollTop > prevScrollTop.current ? "down" : "up";
    if (
      scrollTop + clientHeight + LAZY_LOAD_OFFSET >= scrollHeight &&
      direction === "down"
    ) {
      setLazyLoading(true);
      syncLazyLoading.current = true;
      onLazyLoad().finally(() => {
        setLazyLoading(false);
        syncLazyLoading.current = false;
      });
    }
    prevScrollTop.current = scrollTop;
  }, LAZY_LOAD_THROTTLE);

  useEffect(() => {
    const scrollableElement = scrollViewRef.current;

    if (!scrollableElement) {
      return;
    }

    scrollableElement.addEventListener("scroll", handleScroll);
    return () => {
      scrollableElement.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, onLazyLoad]);

  return (
    <div className="analog-select__content">
      {search && (
        <div className="analog-select__content-search">
          <Input
            value={searchValue}
            startAdornment={<Search size={16} />}
            placeholder="Search"
            onChange={handleSearch}
            onClear={handleClearSearch}
          />
        </div>
      )}
      <div className="analog-select__content-main" ref={scrollViewRef}>
        {getContent()}
        {lazyLoading && (
          <div className="analog-select__content-spinner">
            <Spinner size="s" />
          </div>
        )}
      </div>
      {footer && (
        <footer className="analog-select__content-footer">{footer}</footer>
      )}
    </div>
  );
};
