import s from "./index.module.css";
import { Parameter } from "../Parameter";
import { useContext } from "react";
import { AthleteCardContext } from "../../../../context";
import { format } from "date-fns";
import { Skeleton, Typography } from "../../../../../../storybook";

export const HealthMetricsPanel = () => {
  const { athleteLoading, wellnessData } = useContext(AthleteCardContext);

  if (athleteLoading) {
    return (
      <div className={s.panel}>
        <header className={s.panelHeader}>
          <Skeleton width="140px" height="46px" />
        </header>

        <div className={s.stats}>
          <Skeleton width="100%" height="31px" />
          <Skeleton width="100%" height="31px" />
          <Skeleton width="100%" height="31px" />
          <Skeleton width="100%" height="31px" />
          <Skeleton width="100%" height="31px" />
        </div>
      </div>
    );
  }

  return (
    <div className={s.panel}>
      <header className={s.panelHeader}>
        <div className={s.panelHeaderLeft}>
          <span className={s.totalScoreValue}>
            {wellnessData?.wellness?.data.totalScore || 0}
          </span>
          <span className={s.totalScoreMax}>/</span>
          <span className={s.totalScoreMax}>100</span>
        </div>
        {wellnessData?.wellness?.timeSinceEpoch && (
          <div className={s.panelHeaderRight}>
            <Typography variant="caption" text="last update" />
            <Typography
              variant="caption"
              text={format(
                new Date(wellnessData.wellness.timeSinceEpoch),
                "d/M/yyyy",
              )}
            />
          </div>
        )}
      </header>

      <div className={s.stats}>
        <Parameter
          name="General"
          value={wellnessData?.wellness?.data.generalScore || 0}
          total={20}
        />
        <Parameter
          name="Fatigue"
          value={wellnessData?.wellness?.data.fatigueScore || 0}
          total={20}
        />
        <Parameter
          name="Stress"
          value={wellnessData?.wellness?.data.stressScore || 0}
          total={20}
        />
        <Parameter
          name="Mood"
          value={wellnessData?.wellness?.data.moodScore || 0}
          total={20}
        />
        <Parameter
          name="Sleep"
          value={wellnessData?.wellness?.data.sleepScore || 0}
          total={20}
        />
      </div>
    </div>
  );
};
