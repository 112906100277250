import React, { FC } from "react";
import Select from "react-windowed-select";
import clsx from "clsx";

import { MMPType } from "enums";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { getCurrentMMPType, setCurrentMMPType } from "store/slices/shared";

import styles from "./mmp-type-selector.module.scss";
import "./mmp-type-selector.overwrite.scss";
import { useAppDispatch } from "../../store";

interface SelectorOption {
  label: string;
  value: MMPType;
}

const options: SelectorOption[] = [
  {
    label: "Absolute",
    value: MMPType.Absolute,
  },
  {
    label: "Relative",
    value: MMPType.Relative,
  },
];

interface Props {
  small?: boolean;
}

export const MMPTypeSelector: FC<Props> = ({ small }) => {
  const dispatch = useAppDispatch();
  const currentMMPType = useSelector(getCurrentMMPType);

  const value: SelectorOption | undefined = options.find(
    (option) => option.value === currentMMPType,
  );
  const handleChange = (newOption: SelectorOption) => {
    dispatch(setCurrentMMPType(newOption.value));
  };

  return (
    <div className={clsx(styles.wrapper, "mmp-type-selector")}>
      {/* @ts-ignore */}
      <Select
        isClearable={false}
        isSearchable={false}
        options={options}
        value={value}
        defaultValue={value}
        onChange={handleChange}
        className={clsx("mmp-type__select", small && "mmp-type__select--small")}
        classNamePrefix="react-select"
      />
    </div>
  );
};
