import { Controller, useFormContext } from "react-hook-form";
import { IInputPasswordProps } from "../../../storybook/stories/components/Input/types.tsx";
import { InputPassword } from "../../../storybook/stories/components/Input/InputPassword.tsx";

type IProps = IInputPasswordProps & {
  name: string;
};

export const InputPasswordControl = (props: IProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, onBlur, value } }) => (
        <InputPassword
          {...props}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
      )}
    />
  );
};
