const IllnessOtherBack = ({ opacity }: { opacity: number }) => {
  return (
    <svg
      width="160"
      height="410"
      viewBox="0 0 160 410"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={opacity}
    >
      <path
        d="M100.683 13.3764C100.683 12.7606 100.779 12.2153 100.97 11.7403C101.179 11.2653 101.431 10.8431 101.726 10.4736C102.039 10.1042 102.369 9.76111 102.717 9.44444C103.065 9.12778 103.386 8.8287 103.682 8.54722C103.994 8.24815 104.246 7.94028 104.438 7.62361C104.646 7.30694 104.75 6.95509 104.75 6.56805C104.75 5.93472 104.49 5.42454 103.968 5.0375C103.464 4.65046 102.786 4.45694 101.935 4.45694C101.118 4.45694 100.388 4.63287 99.7449 4.98472C99.1018 5.31898 98.5717 5.79398 98.1546 6.40972L95 4.53611C95.6952 3.46296 96.6598 2.60972 97.8939 1.97639C99.1279 1.32546 100.623 1 102.378 1C103.682 1 104.829 1.19352 105.819 1.58055C106.81 1.95 107.583 2.49537 108.14 3.21667C108.713 3.93796 109 4.82639 109 5.88194C109 6.56805 108.896 7.175 108.687 7.70278C108.479 8.23055 108.209 8.68796 107.879 9.075C107.549 9.46204 107.192 9.82268 106.81 10.1569C106.445 10.4912 106.097 10.8167 105.767 11.1333C105.437 11.45 105.159 11.7843 104.933 12.1361C104.724 12.488 104.62 12.9014 104.62 13.3764H100.683ZM102.665 20C101.935 20 101.335 19.7625 100.866 19.2875C100.397 18.8125 100.162 18.2495 100.162 17.5986C100.162 16.9301 100.397 16.3759 100.866 15.9361C101.335 15.4787 101.935 15.25 102.665 15.25C103.412 15.25 104.012 15.4787 104.464 15.9361C104.933 16.3759 105.168 16.9301 105.168 17.5986C105.168 18.2495 104.933 18.8125 104.464 19.2875C104.012 19.7625 103.412 20 102.665 20Z"
        fill="#F93939"
      />
    </svg>
  );
};

export default IllnessOtherBack;
