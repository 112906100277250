import React, { FC, useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "hooks/app-hooks";
import { useBoundedCharts } from "hooks/use-bounded-charts";
import { getCurrentAthlete, getCurrentDateRange } from "store/slices/shared";

import { getChartMeta } from "pages/wellness/overall-wellness/overall-wellness.settings";

import { Loading } from "components/loading/loading";

import styles from "./overall.wellness.module.scss";
import { ChartSize } from "components/charts/constants";

import {
  COLOR_CHART_LINE_TWO,
  COLOR_CHART_LINE_THREE,
  COLOR_CHART_LINE_FOUR,
  COLOR_CHART_LINE_FIVE,
  COLOR_CHART_LINE_SIX,
  COLOR_CHART_LINE_ONE,
} from "components/charts/colors";
import { ScatterChartWithHeader } from "components/charts/scatter-chart/scatter-chart";
import { DateSelector } from "components/date-selector/date-selector";
import { useAppDispatch } from "../../../store";

export const OverallWellness: FC = () => {
  const dispatch = useAppDispatch();
  const currentAthlete = useSelector(getCurrentAthlete);
  const currentDateRange = useSelector(getCurrentDateRange);

  const wellnessChartYAxis = [
    { name: "Index", max: 100, gridIndex: 0, yAxisIndex: 0 },
    { name: "SubCategories", max: 20, gridIndex: 1, yAxisIndex: 0 },
  ];
  const wellnessChartXAxis = [{ gridIndex: 0 }, { gridIndex: 1 }];

  const [athletePoints] = useBoundedCharts(getChartMeta, "fatigueTest");

  const categoriesSeries = useMemo(
    () => [
      {
        name: `Overall Score`,
        suffix: "%",
        color: COLOR_CHART_LINE_ONE,
        chartType: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        values: athletePoints?.values?.totalScore,
      },
      {
        name: `General`,
        suffix: " out of 20",
        color: COLOR_CHART_LINE_SIX,
        chartType: "line",
        xAxisIndex: 1,
        yAxisIndex: 1,
        values: athletePoints?.values?.overallScore,
      },
      {
        name: `Fatigue`,
        suffix: " out of 20",
        color: COLOR_CHART_LINE_FIVE,
        chartType: "line",
        xAxisIndex: 1,
        yAxisIndex: 1,
        values: athletePoints?.values?.fatigueScore,
      },
      {
        name: `Stress`,
        suffix: " out of 20",
        color: COLOR_CHART_LINE_THREE,
        chartType: "line",
        xAxisIndex: 1,
        yAxisIndex: 1,
        values: athletePoints?.values?.stressScore,
      },
      {
        name: `Mood`,
        suffix: " out of 20",
        color: COLOR_CHART_LINE_TWO,
        chartType: "line",
        xAxisIndex: 1,
        yAxisIndex: 1,
        values: athletePoints?.values?.moodScore,
      },
      {
        name: `Sleep`,
        suffix: " out of 20",
        color: COLOR_CHART_LINE_FOUR,
        chartType: "line",
        xAxisIndex: 1,
        yAxisIndex: 1,
        values: athletePoints?.values?.moodScore,
      },
    ],
    [athletePoints],
  );

  if (!athletePoints?.loaded) {
    return (
      <div>
        <div className={styles.content}>
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div data-testid="overall-wellness">
      <div className={styles.content}>
        <ScatterChartWithHeader
          name="Wellness"
          source="Coach, Athlete"
          type={ChartSize.Big}
          yAxis={wellnessChartYAxis}
          xAxis={wellnessChartXAxis}
          hideDecimalPoints={false}
          useRawValues={true}
          showLegendInSourceBar={true}
          headerComponent={
            <div className={styles.headerContainer}>
              <div className={styles.bigTitle}>
                <span className={styles.bold}>{"Wellness"}</span>
              </div>
              <div className={styles.selectors}>
                <DateSelector header={true} />
              </div>
            </div>
          }
          series={categoriesSeries}
          labels={athletePoints.labels}
        />
      </div>
    </div>
  );
};
