import React, { createContext, useContext, useState, ReactNode } from "react";

interface UploadContextType {
  updated: boolean;
  setUpdated: (updated: boolean) => void;
}

const UploadContext = createContext<UploadContextType | undefined>(undefined);

export const useUploadContext = () => {
  const context = useContext(UploadContext);
  if (context === undefined) {
    throw new Error("useUploadContext must be used within a UploadProvider");
  }
  return context;
};

interface UploadProviderProps {
  children: ReactNode;
}

export const UploadProvider: React.FC<UploadProviderProps> = ({ children }) => {
  const [updated, setUpdated] = useState(false);

  return (
    <UploadContext.Provider value={{ updated, setUpdated }}>
      {children}
    </UploadContext.Provider>
  );
};
