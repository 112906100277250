import { useForm } from "react-hook-form";
import { FormEvent, useCallback, useMemo, useState } from "react";
import { AxiosError } from "axios";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { sendOTP } from "../../api/profile.ts";
import { Toast } from "../../storybook";
import { useTranslation } from "react-i18next";

export const useViewModel = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [sentToEmail, setSentToEmail] = useState<string>("");

  const schema = useMemo(() => {
    return z
      .object({
        email: z
          .string()
          .min(1, { message: t("validation.required") })
          .email({ message: t("validation.invalid_email") }),
      })
      .passthrough();
  }, [t]);

  const form = useForm<{ email: string }>({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const handleSendOtp = useCallback(
    async (formData: { email: string }) => {
      setLoading(true);

      return sendOTP(formData.email)
        .then(() => {
          setSentToEmail(formData.email);
          setLoading(false);
        })
        .catch((e: AxiosError) => {
          Toast.error({
            title: t("error.send_otp_title"),
            message: e.message,
          });
          setLoading(false);
        });
    },
    [t],
  );

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      handleSubmit((formData: { email: string }) => {
        setLoading(true);
        handleSendOtp(formData);
      })();
    },
    [handleSendOtp, handleSubmit],
  );

  return {
    form,
    onSubmit,
    errors,
    loading,
    sentToEmail,
  };
};
