import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import styles from "./drop-files.module.scss";
import { useDropzone } from "react-dropzone";

type FileType = {
  name: string;
  size: string;
  file: File;
};

type DropFiletype = {
  selectedFile: FileType[];
  setSelectedFile: React.Dispatch<React.SetStateAction<FileType[]>>;
  showPopup?: boolean;
  onClose?: () => void;
};

export const DropFiles = ({
  selectedFile,
  setSelectedFile,
  showPopup = false,
  onClose,
}: DropFiletype) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file) => ({
        name: file.name,
        size: file.size.toString(),
        file: file,
      }));
      setSelectedFile(newFiles);
      // setSelectedFile((prevFiles) => [...prevFiles, ...newFiles]);
      console.log(newFiles);
      if (showPopup && onClose) {
        onClose();
      }
    },
    [setSelectedFile, showPopup, onClose],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <>
      <div {...getRootProps()} className={styles.dropzoneStyle}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p className={styles.dragFilesBox}>
            Drop files here or <span className={styles.file}>browse files</span>
          </p>
        )}
      </div>
    </>
  );
};
