import "./index.css";
import {
  HRV_RHR_DARK_GREEN,
  HRV_RHR_LIGHT_GREEN,
  HRV_RHR_LIGHT_YELLOW,
  HRV_RHR_ORANGE,
  HRV_RHR_RED,
  HRV_RHR_YELLOW,
} from "../../../../../../components/charts/colors.ts";
import { useContext, useMemo } from "react";
import { AthleteCardContext } from "../../../../context.tsx";
import { Tooltip, Typography } from "../../../../../../storybook";

export const HRVRHRFeedback = () => {
  const { athlete } = useContext(AthleteCardContext);
  const hrvVariance = athlete?.hrvVariance || 0;
  const rhrVariance = athlete?.rhrVariance || 0;

  const tooltipText = useMemo(() => {
    if (hrvVariance >= 10 && rhrVariance <= -4) {
      return {
        background: HRV_RHR_DARK_GREEN,
        text: "Your HRV is significantly higher and your RHR is moderately lower than your 7-day baseline, indicating excellent recovery. You are well-prepared for high-intensity training today.",
      };
    } else if (hrvVariance > 5 && rhrVariance <= -2) {
      return {
        background: HRV_RHR_LIGHT_GREEN,
        text: "Your HRV is moderately higher and your RHR is slightly lower than your 7-day baseline, suggesting good recovery. Proceed with planned training and consider adding some intensity.",
      };
    } else if (hrvVariance <= -10 && rhrVariance >= 4) {
      return {
        background: HRV_RHR_RED,
        text: "Both your HRV and RHR indicate significant stress and insufficient recovery. This combination strongly suggests reducing training load, focusing on recovery activities, and ensuring good sleep and nutrition.",
      };
    } else if (hrvVariance < -5 && rhrVariance >= 2) {
      return {
        background: HRV_RHR_LIGHT_YELLOW,
        text: "Your HRV is moderately lower and your RHR is slightly higher than your 7-day baseline, suggesting some fatigue or stress. Consider a lighter training session today.",
      };
    } else if (hrvVariance > 5 && rhrVariance > 2) {
      return {
        background: HRV_RHR_YELLOW,
        text: "While your HRV is moderately higher, indicating good recovery, your RHR is slightly elevated. Consider a moderate-intensity workout and monitor how you feel throughout the day.",
      };
    } else if (hrvVariance <= -5 && rhrVariance <= -2) {
      return {
        background: HRV_RHR_ORANGE,
        text: "Your HRV is moderately lower, and your RHR is slightly lower, indicating possible overreaching or fatigue despite autonomic recovery. Consider a light to moderate session focused on technique or low-intensity endurance today.",
      };
    }

    return null;
  }, [rhrVariance, hrvVariance]);

  if (!tooltipText) {
    return null;
  }

  return (
    <Tooltip
      className="hrv-rhr-feedback__toggle"
      anchor={
        <div
          className="hrv-rhr-feedback__toggle-bar"
          style={{ background: tooltipText.background }}
        ></div>
      }
      content={
        <Typography
          className="hrv-rhr-feedback__content"
          text={tooltipText.text}
        />
      }
      maxWidth="400px"
      placement="top"
    />
  );
};
