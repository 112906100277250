import { FC, useMemo } from "react";

import { ChartData } from "types";

import { Loading } from "components/loading/loading";
import {
  BORDER_SCATTER_DOT,
  GRADIENT_AREA_RED,
  TEAM_PERFORMANCE_BAR_COLOR_TWO,
  TEAM_PERFORMANCE_BAR_COLOR_ONE,
} from "components/charts/colors";

import styles from "./critical-power-w.module.scss";
import { BarScatterChart } from "../bar-scatter-chart/bar-scatter-chart";
import { BarScatterModel, ChartValue } from "api/chart";
import { DataTypeSelector, UnitsType } from "enums";
import { getCurrentDataType } from "store/slices/shared";
import { useSelector } from "hooks/app-hooks";

type Props = {
  data?: ChartData;
};

export const CriticalPowerW: FC<Props> = ({ data }) => {
  const currentDataType = useSelector(getCurrentDataType);

  const criticalPowerSeries = useMemo(() => {
    const series: BarScatterModel[] = [];
    const cpData =
      currentDataType === DataTypeSelector.ABSOLUTE
        ? data?.values.cp
        : data?.values.cpRelative;
    const fixedNumber = currentDataType === DataTypeSelector.ABSOLUTE ? 0 : 2;
    const cpValue: ChartValue[] =
      cpData?.map((e) => {
        return {
          value: Number(e.value.toFixed(fixedNumber)),
          itemStyle: { decimalPoint: fixedNumber },
        };
      }) || [];
    const wValue: ChartValue[] =
      data?.values.w?.map((e) => {
        return {
          value: e.value / 1000,
          itemStyle: { decimalPoint: 1 },
        };
      }) || [];

    if (cpValue && cpValue.length > 0) {
      series.push({
        name: `mCP`,
        color: TEAM_PERFORMANCE_BAR_COLOR_ONE,
        areaColor: GRADIENT_AREA_RED,
        chartType: "bar",
        hideInLegend: false,
        yAxisIndex: 0,
        values: cpValue,
      });
    }

    if (wValue && wValue.length > 0) {
      series.push({
        name: `W'`,
        units: {
          type: UnitsType.STRING,
          value: "kJ",
        },
        color: TEAM_PERFORMANCE_BAR_COLOR_TWO,
        areaColor: GRADIENT_AREA_RED,
        chartType: "scatter",
        hideInLegend: false,
        yAxisIndex: 1,
        values: wValue,
        min: 0,
        borderColor: BORDER_SCATTER_DOT,
      });
    }

    return series;
  }, [data?.values, currentDataType]);

  if (!data?.loaded) {
    return (
      <div className={styles.content}>
        <div className={styles.chart}>
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.mainWrapper}>
      <BarScatterChart
        addBorder={false}
        chartTitle="mCP & W'"
        data={data}
        dataArray={criticalPowerSeries}
        formatLabels={false}
        showToolTipTitle={false}
        showGroupBySelector={false}
        showTypeSelector={true}
      />
    </div>
  );
};
