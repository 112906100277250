import axios from "axios";
import { API_ENDPOINT } from "const";
import { store } from "store";
import { setAuthState } from "store/slices/auth";

const axiosInstance = axios.create({
  baseURL: API_ENDPOINT,
});

function intercept() {
  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token;

    // TODO: This needs to be changed
    config.headers["X-team-name"] = "uae_dev_team";

    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      // if (error.response.status === 401) {
      //   store.dispatch(setAuthState(null));
      // }
      if (error.response.status === 400) {
        // Handle 400 Bad Request error here
        return Promise.reject(error.response); // Return the response object
      }
      return Promise.reject(error);
    },
  );
}

intercept();

export { axiosInstance, intercept };
