const DiarhoeaBack = ({ opacity }: { opacity: number }) => {
  return (
    <svg
      width="160"
      height="410"
      viewBox="0 0 160 410"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={opacity}
    >
      <path
        d="M78.5034 204.472C80.5033 222.972 76.4547 223.908 69.0033 225.972C61.552 228.037 50.1018 223.045 48.0033 215.472C45.9048 207.898 53.8547 202.179 61.306 200.115C68.7574 198.05 76.4049 196.899 78.5034 204.472Z"
        fill="#FFE37F"
        fillOpacity="0.9"
      />
      <path
        d="M81.3054 204.472C79.3054 222.972 83.3541 223.908 90.8054 225.972C98.2568 228.037 109.707 223.045 111.806 215.472C113.904 207.898 105.954 202.179 98.5027 200.115C91.0514 198.05 83.4039 196.899 81.3054 204.472Z"
        fill="#FFE37F"
        fillOpacity="0.9"
      />
    </svg>
  );
};

export default DiarhoeaBack;
