const DiarhoaeFront = ({ opacity }: { opacity: number }) => {
  return (
    <svg
      width="160"
      height="410"
      viewBox="0 0 160 410"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={opacity}
    >
      <path
        d="M106 189C106 200.046 94.3594 212 80 212C65.6406 212 54 200.046 54 189C54 177.954 65.6406 175 80 175C94.3594 175 106 177.954 106 189Z"
        fill="#FFE37F"
      />
    </svg>
  );
};

export default DiarhoaeFront;
