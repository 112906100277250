import { ITypographyProps } from "./types.ts";
import { ElementType } from "react";
import { clsx } from "clsx";

export const Typography = <T extends ElementType = "span">({
  as: Component = "span",
  variant = "body",
  weight = "regular",
  text,
  ...props
}: ITypographyProps<T>) => {
  const rootClass = clsx(
    `analog-typography--${variant}`,
    weight,
    props.className,
  );
  return (
    <Component {...props} className={rootClass}>
      {text}
    </Component>
  );
};
