import React from "react";
import clsx from "clsx";

import { Layout } from "components/layout/layout";
import { Tabs, TabsLayout } from "components/tabs-layout/tabs-layout";

import { PowerDerivatives } from "pages/performance/power-derivatives/power-derivatives";
import { Training } from "pages/wellness/training/training";
import { useSelector } from "hooks/app-hooks";
import { getIsCyclist } from "store/slices/auth";

import styles from "./performance.module.scss";
import { AddData } from "components/add-data/add-data";
import { AthletePerformance } from "./athlete-performace/athlete-performance";
import { NewFatigueResistanceCharts } from "./new-fatigue-resistance-charts/new-fatigue-resistance-charts";
import { ChatBot } from "components/chat-bot/chat-bot";

const tabs = [
  {
    id: Tabs.TrainingTab,
    name: "Training Load",
  },
  {
    id: Tabs.PowerDerivativesTab,
    name: "Power Derivatives",
  },
  {
    id: Tabs.FatigueResistanceTab,
    name: "Durability",
  },
];

const componentMap = {
  [Tabs.TrainingTab]: <Training />,
  [Tabs.PowerDerivativesTab]: <PowerDerivatives />,
  [Tabs.FatigueResistanceTab]: <NewFatigueResistanceCharts />,
};

export const Performance = () => {
  const isCyclist: boolean = useSelector(getIsCyclist);

  const topComponent = (
    <>
      <div className={clsx(styles.topHeaderCls, !isCyclist && styles.hidden)}>
        {isCyclist && <AddData />}
      </div>
      <div
        className={clsx(
          styles.topContainer,
          isCyclist && styles.topContainerCyclist,
        )}
      >
        <AthletePerformance />
      </div>
    </>
  );

  return (
    <>
      <Layout
        top={topComponent}
        contentHeader="Performance Analytics"
        content={<TabsLayout tabs={tabs} componentMap={componentMap} />}
        isCyclist={isCyclist}
        dataTestId="performance-analytics"
      />
    </>
  );
};
