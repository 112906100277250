import type { ICalendarContext, IMonthDay, IMonthDays } from "./types";
import { useCallback, useContext, useMemo, useState } from "react";
import { CalendarContext } from "./context.tsx";
import { getDaysInMonth } from "./utils/getDaysForMonth";
import { clsx } from "clsx";
import { weekDaysLabels } from "./utils/constants";
import { isToday } from "./utils/isToday";
import { isValidDate } from "./utils/isValidDate";
import { isDateEqual } from "./utils/isDateEqual";
import { isDateInRange } from "./utils/isDateInRange";

export const Days = () => {
  const {
    dateForPeriod,
    min,
    max,
    innerRange,
    currentInnerRangePointer,
    range,
    onOpenChange,
    onChange,
    value,
    translations,
    innerValueInit,
  }: ICalendarContext = useContext(CalendarContext);

  const [localRange, setLocalRange] = useState<Date[]>([]);

  const daysInMonth: IMonthDays = useMemo(() => {
    return getDaysInMonth(dateForPeriod);
  }, [dateForPeriod]);

  const onDayClick = useCallback(
    (day: IMonthDay) => (): void => {
      if (range) {
        innerRange.current[currentInnerRangePointer.current] = day.date;

        if (innerRange.current.length === 1) {
          setLocalRange([...innerRange.current]);
        }

        currentInnerRangePointer.current += 1;

        if (innerRange.current.length === 2) {
          if (innerRange.current[0] > innerRange.current[1]) {
            onChange([innerRange.current[1], innerRange.current[0]]);
          } else {
            onChange(innerRange.current);
          }
          innerRange.current = [];
          currentInnerRangePointer.current = 0;
          innerValueInit.current = false;
          setLocalRange([]);
          onOpenChange(false);
        }
      } else {
        onChange(day.date);
        innerValueInit.current = false;
        onOpenChange(false);
      }
    },
    [
      range,
      innerRange,
      currentInnerRangePointer,
      innerValueInit,
      onOpenChange,
      onChange,
    ],
  );

  const onDayHover = useCallback(
    (day: IMonthDay) => (): void => {
      if (!range || localRange.length === 0) {
        return;
      }
      setLocalRange((localRange: Date[]) => [localRange[0], day.date]);
    },
    [range, localRange],
  );

  const daysJSX = daysInMonth.days.map((day: IMonthDay) => {
    const isSelected = range
      ? localRange.length > 0
        ? isDateInRange(day.date, localRange)
        : isDateInRange(day.date, value)
      : isDateEqual(day.date, value);
    const isRangeStart =
      localRange.length > 0
        ? (localRange.length === 1 || localRange[1] >= localRange[0]) &&
          isDateEqual(day.date, localRange[0])
        : range && isDateEqual(day.date, value[0]);
    const isRangeEnd =
      localRange.length > 0
        ? localRange[1] < localRange[0] && isDateEqual(day.date, localRange[0])
        : range && isDateEqual(day.date, value[1]);

    const rootClass: string = clsx(
      "analog-calendar__dateButton analog-calendar__day",
      `analog-calendar__dateButton--${day.period}`,
      isToday(day.date) && "analog-calendar__dateButton--today",
      isSelected && "analog-calendar__dateButton--selected",
      range && isSelected && "analog-calendar__dateButton--range",
      isRangeStart && "analog-calendar__dateButton--rangeStart",
      isRangeEnd && "analog-calendar__dateButton--rangeEnd",
      !isValidDate(day.date, min, max) &&
        "analog-calendar__dateButton--disabled",
    );

    return (
      <button
        key={day.date.getTime()}
        className={rootClass}
        onClick={onDayClick(day)}
        onMouseEnter={onDayHover(day)}
      >
        {day.date.getDate()}
      </button>
    );
  });

  const weekDaysJSX = (translations?.week || weekDaysLabels).map(
    (day: string) => {
      return (
        <div key={day} className="analog-calendar__weekDay">
          {day}
        </div>
      );
    },
  );

  return (
    <div className="analog-calendar__days-wrapper analog-typography--button-m">
      <div className="analog-calendar__weekDays">{weekDaysJSX}</div>
      <div className="analog-calendar__dates">{daysJSX}</div>
    </div>
  );
};
