import { CSSProperties, useMemo } from "react";
import "./index.css";
import { IDividerProps } from "./types.tsx";
import { clsx } from "clsx";
import { Typography } from "../Typography";

export const Divider = ({
  text,
  direction = "horizontal",
  width,
  height,
  className,
}: IDividerProps) => {
  const rootClass: string = clsx(
    "analog-divider",
    `analog-divider--${direction}`,
    className,
  );

  const style: CSSProperties = useMemo(() => {
    const dimensions: CSSProperties = {};

    if (width !== undefined) {
      dimensions.width = `${width}px`;
    }

    if (height !== undefined) {
      dimensions.height = `${height}px`;
    }

    return dimensions;
  }, [width, height]);

  return (
    <div className={rootClass} style={style}>
      {text && <Typography className="analog-divider__text" text={text} />}
    </div>
  );
};
