import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./health-notes.module.scss";
import { PrimaryButton } from "components/primary-button/primary-button";
import { NotesPopup } from "components/notes-popup/notes-popup";
import { ReactComponent as EditPencil } from "images/editPencil.svg";
import { deleteNote, getNotes } from "api/medical-report";
import { EditNotesPopup } from "components/notes-popup/edit-notes-popup";
import { useSelector } from "react-redux";
import { ReactComponent as Delete } from "images/trash.svg";
import { DownChevron } from "react-select/src/components/indicators";

export enum Tabs {
  OverallWellnessTab,
  BodyCompositionTab,
  HeartMeasurementsTab,
  TrainingTab,
  PowerDerivativesTab,
  FatigueResistanceTab,
  FitnessFatigueTab,
  MenstrualCycleTab,
  TemperatureChartTab,
}

interface NoteData {
  id?: number;
  medicalReportId: number;
  note: string;
  createdBy: string;
}

export const HealthNotes = () => {
  const [notes, setNotes] = useState<NoteData[]>([]);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [newNotes, setNewNotes] = useState(false);
  const [selectedNote, setSelectedNote] = useState<NoteData>();
  const selectedMedicalReportId = useSelector(
    (state: any) => state.health.selectedMedicalReportId,
  );
  const [deletedSuccessfully, setDeletedSuccessfully] = useState(false);
  const selectedMedicalReport = useSelector(
    (state: any) => state?.health?.selectedMedicalReport,
  );

  const handleSubmit = () => {
    setShowNotePopup(true);
  };

  const handleEditClick = (note: NoteData) => {
    setShowEditPopup(true);
    setSelectedNote(note);
  };

  // api call to get notes details
  useEffect(() => {
    (async () => {
      const response = await getNotes(selectedMedicalReportId);
      setNotes(response);
    })();
  }, [newNotes, selectedMedicalReportId, deletedSuccessfully]);

  const handleDeleteClick = async (id: number) => {
    setDeletedSuccessfully(false);
    const resp = await deleteNote(id);
    setDeletedSuccessfully(true);
  };

  return (
    <div className={styles.healthTab}>
      <div className={styles.header}>
        <div className={styles.title}>Notes</div>
        <div className={styles.button}>
          <PrimaryButton
            className={styles.newNote}
            onClick={handleSubmit}
            title={"New Note"}
          />
        </div>
      </div>
      <div className={styles.healthNotes}>
        {selectedMedicalReport?.note && (
          <div className={styles.NotesBox}>
            <>
              <div>{selectedMedicalReport?.note}</div>
              <div className={styles.noteBoxBottom}>
                <div className={styles.addedByWrapper}>
                  <span>Added by {selectedMedicalReport?.createdBy} </span>
                </div>
                <div className={styles.editableWrapper}></div>
              </div>
            </>
          </div>
        )}

        {notes?.length > 0 &&
          notes?.map((note) => {
            return (
              <div key={note.id} className={styles.NotesBox}>
                <>
                  <div>{note?.note}</div>
                  <div className={styles.noteBoxBottom}>
                    <div className={styles.addedByWrapper}>
                      <span>Added by {note?.createdBy} </span>
                    </div>
                    <div className={styles.editableWrapper}>
                      {selectedMedicalReport?.editable && (
                        <span
                          className={styles.editNotes}
                          onClick={() => handleEditClick(note)}
                        >
                          Edit <EditPencil className={styles.editIcon} />
                        </span>
                      )}
                      {selectedMedicalReport?.editable && (
                        <span className={styles.deleteNotes}>
                          <Delete
                            className={styles.editIcon}
                            onClick={() =>
                              note?.id && handleDeleteClick(note?.id)
                            }
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </>
              </div>
            );
          })}

        {selectedMedicalReport.note === undefined && notes?.length <= 0 && (
          <div className={styles.noNotes}>No Notes have been recorded.</div>
        )}
      </div>

      {showNotePopup && (
        <NotesPopup
          onClose={() => setShowNotePopup(false)}
          setNewNotes={() => setNewNotes(!newNotes)}
          selectedMedicalReportId={selectedMedicalReportId}
        />
      )}

      {showEditPopup && (
        <EditNotesPopup
          onClose={() => setShowEditPopup(false)}
          setNewNotes={() => setNewNotes(!newNotes)}
          selectedNote={selectedNote}
          selectedMedicalReportId={selectedMedicalReportId}
        />
      )}
    </div>
  );
};
