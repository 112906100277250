import s from "./index.module.css";
import { countryCodesMapping } from "../../../../country-codes-mapping";
import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from "../../../../const";
import { useContext } from "react";
import { AthleteCardContext } from "../../context";
import { LogOut } from "lucide-react";
import { Skeleton } from "../../../../storybook";

export const Picture = () => {
  const { athlete, athleteLoading, handleSingOut } =
    useContext(AthleteCardContext);
  const countryAlpha2Code =
    athlete?.countryCode && countryCodesMapping[athlete.countryCode]
      ? countryCodesMapping[athlete?.countryCode]
      : "AE";
  const flagUrl = `${DEFAULT_CDN_URL}${countryAlpha2Code.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;

  return (
    <div className={s.header}>
      <div
        className={s.headerImage}
        style={{ backgroundImage: `url(${athlete?.picture})` }}
      />
      <div className={s.headerTransition} />
      <div className={s.headerCountry}>
        {athleteLoading ? (
          <Skeleton width="30px" height="30px" shape="round" />
        ) : (
          <img
            className={s.headerCountryFlag}
            src={flagUrl}
            alt={athlete?.countryCode}
          />
        )}
      </div>
      <div className={s.headerMenu} onClick={handleSingOut}>
        <LogOut />
      </div>
    </div>
  );
};
