import { GetDTO, IAthleteCardContext, IWellnessData } from "./types";
import { useCallback, useEffect, useState } from "react";
import { axiosInstance } from "../../axios-instance/axios-instance";
import { Athlete } from "../../types";
import { setAccount, setAuthState } from "../../store/slices/auth";
import { RoleType } from "../../enums";
import { useHistory } from "react-router-dom";
import { useDispatch } from "../../hooks/app-hooks";
import { useAppDispatch } from "../../store";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

export const useViewModel = (): IAthleteCardContext => {
  const [athlete, setAthlete] = useState<Athlete | null>(null);
  const [athleteLoading, setAthleteLoading] = useState<boolean>(true);
  const [wellnessData, setWellnessData] = useState<IWellnessData | null>(null);
  const { instance } = useMsal();
  const authenticatedMs = useIsAuthenticated();

  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    axiosInstance
      .get<GetDTO<Athlete[]>>(`/athletes`)
      .then((response) => {
        return response.data.data.length > 1 ? null : response.data.data[0];
      })
      .then((_athlete: Athlete | null) => {
        setAthlete(_athlete);
        return axiosInstance.get<IWellnessData>(
          `/wellness/overall/lastUpdated?athleteId=${_athlete?.athleteId}`,
        );
      })
      .then((response) => {
        setWellnessData(response.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setAthleteLoading(false);
      });
  }, []);

  const onLogout = useCallback(() => {
    dispatch(setAuthState(false));
    dispatch(setAccount(RoleType.cyclist));
    history.push("/sign-in");
  }, [dispatch, history]);

  const handleSingOut = useCallback(() => {
    if (authenticatedMs) {
      instance.logoutRedirect().then(onLogout);
    } else {
      onLogout();
    }
  }, [authenticatedMs, instance, onLogout]);

  return {
    athlete,
    athleteLoading,
    wellnessData,
    setWellnessData,
    handleSingOut,
  };
};
