import React, { FC } from "react";
import Select from "react-windowed-select";
import clsx from "clsx";

import { DataTypeSelector } from "enums";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { getCurrentDataType, setCurrentDataType } from "store/slices/shared";

import { ReactComponent as CalendarPrimary } from "images/calendar-primary.svg";

import styles from "./type-selector.module.scss";
import "./type-selector.overwrite.scss";
import { useAppDispatch } from "../../store";

interface SelectorOption {
  label: string;
  value: DataTypeSelector;
}

const options: SelectorOption[] = [
  {
    label: "Absolute",
    value: DataTypeSelector.ABSOLUTE,
  },
  {
    label: "Relative",
    value: DataTypeSelector.RELATIVE,
  },
];

interface Props {
  small?: boolean;
}

export const TypeSelector: FC<Props> = ({ small }) => {
  const dispatch = useAppDispatch();
  const currentDataType = useSelector(getCurrentDataType);

  const value: SelectorOption | undefined = options.find(
    (option) => option.value === currentDataType,
  );
  const handleChange = (newOption: SelectorOption) => {
    dispatch(setCurrentDataType(newOption.value));
  };

  return (
    <div className={clsx(styles.wrapper, "type-group-by-selector")}>
      {/* @ts-ignore */}
      <Select
        isClearable={false}
        isSearchable={false}
        options={options}
        value={value}
        defaultValue={value}
        onChange={handleChange}
        className={clsx(
          "type-selector__select",
          small && "type-selector__select--small",
        )}
        classNamePrefix="react-select"
      />
    </div>
  );
};
