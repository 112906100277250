import { ICalendarContext, ICalendarProps, TimePeriod } from "./types";
import "./index.css";
import { ReactNode, useMemo } from "react";
import { Days } from "./Days";
import { Months } from "./Months";
import { Years } from "./Years";
import { TimePeriodControl } from "./TimePeriodControl";
import { useViewModel } from "./useViewModel.tsx";
import { CalendarContext } from "./context.tsx";
import { clsx } from "clsx";

export const Calendar = (props: ICalendarProps) => {
  const context: ICalendarContext = useViewModel(props);
  const { timePeriod } = context;
  const rootClass = clsx("analog-calendar", props.className);

  const timePeriodMap: Record<TimePeriod, ReactNode> = useMemo(() => {
    return {
      [TimePeriod.Days]: <Days />,
      [TimePeriod.Months]: <Months />,
      [TimePeriod.Years]: <Years />,
    };
  }, []);

  return (
    <CalendarContext.Provider value={context}>
      <div className={rootClass}>
        <TimePeriodControl />
        {timePeriodMap[timePeriod]}
      </div>
    </CalendarContext.Provider>
  );
};
