import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";
import { PrimaryButton } from "components/primary-button/primary-button";
import styles from "./physiotherapy-popup.module.scss";
import Select from "react-windowed-select";
import "./physiotherapy-popup.overwrite.scss";
import { BodyLocationType } from "enums";
import { ActionMeta } from "react-select";
import { useSelector } from "react-redux";
import {
  convertEnumToOptions,
  convertToUnixTimestamp,
  convertUnixTimestampToDate,
  formatDateToYYYYMMDD,
} from "utils";
import { getCurrentAthlete, refreshAthleteList } from "store/slices/shared";
import { createMedicalReport, updateMedicalReport } from "api/medical-report";
import { BodyMap } from "components/body-map/body-map";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import {
  fetchMedicalReports,
  fetchSelectedMedicalReportDetails,
} from "store/slices/health";
import { useAppDispatch } from "../../store";

type Props = {
  onClose: () => void;
  openPyshioPopup?: boolean;
};

interface FormState {
  recordType: string;
  athleteId: number;
  side: string;
  onsetDate: number;
  estimatedRecovery: number;
  type?: string;
  illnessType?: string;
  bodyLocation?: string;
  rehabilitationStage?: string;
  modalityUsed?: any;
  assessmentTools?: any;
  note?: string;
}

interface SelectOptions {
  label: string;
  value: string;
}

interface BodyLocationOption {
  label: string;
  value: BodyLocationType;
}
interface Option {
  label: string;
  value: string;
}
interface MedicalReportDetails {
  id: number;
  athleteId: number;
  recordType: string;
  bodyLocation: string;
  side: string;
  rehabilitationStage: string;
  type: string;
  illnessType: string;
  assessmentTools: any;
  modalityUsed: any;
  onsetDate: number;
  estimatedRecovery: number;
  symptom: string;
  ossicsCode: string;
  severity: string;
  area: string;
  createdBy: string;
  recovered: boolean;
  note: string;
}

export const PhysiotherapyPopup: FC<Props> = ({
  onClose,
  openPyshioPopup = false,
}) => {
  const healthEnums = useSelector((state: any) => state.health.healthEnums);
  const selectedMedicalReportId = useSelector(
    (state: any) => state.health.selectedMedicalReportId,
  );
  const selectedMedicalReport: MedicalReportDetails = useSelector(
    (state: any) => state?.health?.selectedMedicalReport,
  );
  const currentAthlete = useSelector(getCurrentAthlete);

  const [selectedOption, setSelectedOption] = useState<any>({});
  const [form, setForm] = useState<FormState>({
    recordType: "PHYSIO",
    athleteId: currentAthlete?.id || 0,
    onsetDate: Math.floor(Date.now() / 1000),
    estimatedRecovery: Math.floor(
      (Date.now() + 7 * 24 * 60 * 60 * 1000) / 1000,
    ),
    side: openPyshioPopup ? selectedMedicalReport?.side : "BOTH",
    bodyLocation: "",
    rehabilitationStage: "",
    type: "",
    assessmentTools: [],
    modalityUsed: [],
    note: "",
  });

  const dispatch = useAppDispatch();

  const getEnumValue = useCallback(
    (parentKey: string, childkey: string) => {
      return healthEnums[parentKey]?.[childkey];
    },
    [healthEnums],
  );

  const bodyLocationOptions = useMemo(
    () => convertEnumToOptions(healthEnums?.bodyLocation || {}),
    [healthEnums?.bodyLocation],
  );
  const stageOfRehabilitationOptions = useMemo(
    () => convertEnumToOptions(healthEnums?.rehabilitationStage || {}),
    [healthEnums?.rehabilitationStage],
  );

  const typeOptions = useMemo(
    () => convertEnumToOptions(healthEnums?.type || {}),
    [healthEnums?.type],
  );

  const modalityUsedOptions = useMemo(
    () => convertEnumToOptions(healthEnums?.modalityUsed || {}),
    [healthEnums?.modalityUsed],
  );

  const assessmentToolsOptions = useMemo(
    () => convertEnumToOptions(healthEnums?.assessmentTools || {}),
    [healthEnums?.assessmentTools],
  );

  const sideOptions: SelectOptions[] = convertEnumToOptions(
    healthEnums?.side || {},
  );

  // On change input of Select type
  const handleInputChange = (e: any, actionMeta: ActionMeta<Option>) => {
    if (
      actionMeta.name === "modalityUsed" ||
      actionMeta.name === "assessmentTools"
    ) {
      const { name } = actionMeta;
      const optionFinders: Record<string, Option[]> = {
        modalityUsed: modalityUsedOptions,
        assessmentTools: assessmentToolsOptions,
      };
      setForm({
        ...form,
        [actionMeta.name as keyof Option]: e.map((item: Option) => item.value),
      });

      if (name && optionFinders[name]) {
        setSelectedOption({
          ...selectedOption,
          [name]: e.map((item: Option) =>
            optionFinders[name].find(
              (option: Option) => option.value === item.value,
            ),
          ),
        });
      }
      return;
    } else {
      // handle single select with react-select
      const { name } = actionMeta;
      const optionFinders: Record<string, Option[]> = {
        bodyLocation: bodyLocationOptions,
        rehabilitationStage: stageOfRehabilitationOptions,
        type: typeOptions,
        modalityUsed: modalityUsedOptions,
        assessmentTools: assessmentToolsOptions,
      };
      setForm({
        ...form,
        [actionMeta.name as keyof BodyLocationOption | keyof Option]: e.value,
      });

      if (name && optionFinders[name]) {
        setSelectedOption({
          ...selectedOption,
          [name]: optionFinders[name].find(
            (option) => option.value === e.value,
          ),
        });
      }
    }
  };

  const handleDateChange = (name: string) => (dates: Date[]) => {
    const selectedDate = dates[0];
    const timestamp = convertToUnixTimestamp(
      formatDateToYYYYMMDD(selectedDate),
    );
    setForm((prevForm) => ({
      ...prevForm,
      [name]: timestamp,
    }));
    setSelectedOption({
      ...selectedOption,
      [name]: convertUnixTimestampToDate(timestamp),
    });
  };

  // On change input of radio and textArea type
  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setSelectedOption({
      ...selectedOption,
      [e.target.name]: e.target.value,
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitClick = async () => {
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);

    let isMounted = true;

    try {
      // Remove empty fields from the form
      if (form.bodyLocation === "") delete form.bodyLocation;
      if (form.rehabilitationStage === "") delete form.rehabilitationStage;
      if (form.type === "") delete form.type;
      if (form.assessmentTools && form.assessmentTools?.length <= 0)
        delete form.assessmentTools;
      if (form.modalityUsed && form.modalityUsed?.length <= 0)
        delete form.modalityUsed;
      if (form.note === "") delete form.note;

      // encode modalityUsed & asssetmentTools to string before sending to API if they are not empty
      if (form.modalityUsed && form.modalityUsed.length > 0) {
        form.modalityUsed = JSON.stringify(form.modalityUsed);
      }
      if (form.assessmentTools && form.assessmentTools.length > 0) {
        form.assessmentTools = JSON.stringify(form.assessmentTools);
      }

      let response;
      if (!openPyshioPopup) {
        response = await createMedicalReport(form);
        if (isMounted) {
          // @ts-ignore
          dispatch(fetchMedicalReports(0));
          dispatch(refreshAthleteList());
        }
      } else {
        response = await updateMedicalReport(selectedMedicalReportId, form);
        if (isMounted) {
          dispatch(fetchSelectedMedicalReportDetails(selectedMedicalReportId));
          dispatch(refreshAthleteList());
        }
      }

      if (response && isMounted) {
        onClose();
      }
    } finally {
      if (isMounted) {
        setIsSubmitting(false);
      }
    }

    return () => {
      isMounted = false; // Cleanup function to prevent updates after unmount
    };
  };

  // to set previously selected value in setSelectedOption
  useEffect(() => {
    // MAP modality used multi-select options
    let modalityOptionsMulti = [];
    if (
      selectedMedicalReport?.modalityUsed &&
      selectedMedicalReport?.modalityUsed !== ""
    ) {
      const parsedArray = JSON?.parse(selectedMedicalReport?.modalityUsed);
      modalityOptionsMulti = parsedArray?.map((item: string) => ({
        label: getEnumValue("modalityUsed", item),
        value: item,
      }));
    }

    // MAP assessment tools multi-select
    let assessmentToolsOptionsMulti = [];
    if (
      selectedMedicalReport?.assessmentTools &&
      selectedMedicalReport?.assessmentTools !== ""
    ) {
      const parsedArray = JSON?.parse(selectedMedicalReport?.assessmentTools);
      assessmentToolsOptionsMulti = parsedArray?.map((item: string) => ({
        label: getEnumValue("assessmentTools", item),
        value: item,
      }));
    }

    if (openPyshioPopup) {
      setSelectedOption({
        bodyLocation: bodyLocationOptions.find(
          (option) => option.value === selectedMedicalReport?.bodyLocation,
        ),
        rehabilitationStage: stageOfRehabilitationOptions.find(
          (option) =>
            option.value === selectedMedicalReport?.rehabilitationStage,
        ),
        side: selectedMedicalReport?.side,
        type: typeOptions.find(
          (option) => option.value === selectedMedicalReport?.type,
        ),

        // for modalityUsed, de-stringify the string into array, and then pass those multiselect options to selec
        // modalityUsed: modalityUsedOptions.find(
        //   (option) => option.value === selectedMedicalReport?.modalityUsed
        // ),
        modalityUsed: modalityOptionsMulti,
        onsetDate: convertUnixTimestampToDate(selectedMedicalReport?.onsetDate),
        estimatedRecovery: convertUnixTimestampToDate(
          selectedMedicalReport?.estimatedRecovery,
        ),
        assessmentTools: assessmentToolsOptionsMulti,
        note: selectedMedicalReport?.note,
      });
    }
  }, [
    selectedMedicalReport,
    bodyLocationOptions,
    openPyshioPopup,
    stageOfRehabilitationOptions,
    typeOptions,
    modalityUsedOptions,
    assessmentToolsOptions,
    getEnumValue,
  ]);

  // to set initial value of form if edit popup is open
  useEffect(() => {
    if (openPyshioPopup && selectedOption) {
      setForm({
        recordType: "PHYSIO",
        athleteId: currentAthlete?.id || 0,
        bodyLocation: selectedOption.bodyLocation?.value,
        rehabilitationStage: selectedOption.rehabilitationStage?.value,
        side: selectedOption.side,
        type: selectedOption.type?.value,
        modalityUsed: selectedOption?.modalityUsed?.map(
          (item: Option) => item?.value,
        ),
        onsetDate:
          selectedOption?.onsetDate &&
          convertToUnixTimestamp(selectedOption?.onsetDate),
        estimatedRecovery:
          selectedOption?.estimatedRecovery &&
          convertToUnixTimestamp(selectedOption?.estimatedRecovery),
        assessmentTools: selectedOption?.assessmentTools?.map(
          (item: Option) => item?.value,
        ),
        note: selectedOption?.note,
      });
    }
  }, [
    openPyshioPopup,
    selectedOption,
    currentAthlete,
    selectedMedicalReport,
    getEnumValue,
  ]);

  return (
    <div className={clsx(styles.popupBox, "comparison-dropdown")}>
      <div
        className={clsx(styles.inputBox, styles.box)}
        data-testid="add-comparison-list"
      >
        <div className={styles.header}>
          <h3 className={styles.title}>Rehabilitation</h3>
          <Close
            className={styles.closeButton}
            onClick={onClose}
            aria-label="Close"
            title="Close"
          />
        </div>

        <div className={styles.popupDataContainer}>
          <div className={styles.formContainer}>
            <form>
              <div className={clsx(styles.formGroup, styles.selectBox)}>
                <label>Body Location</label>
                {/* @ts-ignore */}
                <Select
                  isClearable={false}
                  isSearchable={false}
                  options={bodyLocationOptions}
                  name="bodyLocation"
                  placeholder="Select Body Location"
                  value={selectedOption?.bodyLocation || null}
                  onChange={handleInputChange}
                  className={clsx(
                    "group-by-selector__select",
                    styles.selectBox,
                  )}
                  classNamePrefix="react-select"
                />
              </div>

              {form?.bodyLocation !== "HEAD_NECK" &&
                form?.bodyLocation !== "BACK_SPINE" && (
                  <div className={styles.formGroup}>
                    <label>Side</label>

                    <div className={clsx(styles["tw-toggle"], styles.radiobox)}>
                      {sideOptions?.map((option) => {
                        return (
                          <>
                            <input
                              type="radio"
                              name="side"
                              value={option.value}
                              id={option.label}
                              onChange={handleChange}
                              checked={
                                selectedOption?.side
                                  ? selectedOption.side === option.value
                                  : form.side === option.value
                              }
                            />
                            <label
                              className={`${styles.toggle} ${styles["toggle-yes"]}`}
                              htmlFor={option.label}
                            >
                              {option.label}
                            </label>
                          </>
                        );
                      })}
                      <span></span>
                    </div>
                  </div>
                )}

              <div className={styles.formGroup}>
                <label>Stage of Rehabilitation</label>
                {/* @ts-ignore */}
                <Select
                  name="rehabilitationStage"
                  isClearable={false}
                  isSearchable={false}
                  options={stageOfRehabilitationOptions}
                  placeholder={"Select Stage of Rehab"}
                  value={selectedOption?.rehabilitationStage}
                  // defaultValue={"Select Stage of Rehabilitation"}
                  onChange={handleInputChange}
                  className={clsx(
                    "group-by-selector__select",
                    styles.selectBox,
                  )}
                  classNamePrefix="react-select"
                />
              </div>

              <div className={styles.formGroup}>
                <label>Type</label>
                {/* @ts-ignore */}
                <Select
                  name="type"
                  isClearable={false}
                  isSearchable={false}
                  options={typeOptions}
                  placeholder="Select Type"
                  value={selectedOption?.type}
                  onChange={handleInputChange}
                  className={clsx(
                    "group-by-selector__select",
                    styles.selectBox,
                  )}
                  classNamePrefix="react-select"
                />
              </div>

              <div className={styles.formGroup}>
                <label>Note</label>
                <textarea
                  placeholder="Note"
                  name="note"
                  onChange={handleChange}
                  value={selectedOption?.note && selectedOption?.note}
                  className={styles.textArea}
                ></textarea>
                {/* <ReusableInputField
                    label=""
                    name="note"
                    placeholderText="Enter Note"
                    onChange={handleChange}
                    multiline
                  /> */}
              </div>

              <div className={styles.formGroup}>
                <label>Modality Used</label>
                {/* @ts-ignore */}
                <Select
                  name="modalityUsed"
                  isMulti={true}
                  isClearable={false}
                  isSearchable={false}
                  options={modalityUsedOptions}
                  placeholder="Select Modality Used"
                  value={selectedOption?.modalityUsed}
                  onChange={handleInputChange}
                  className={clsx(
                    "group-by-selector__select",
                    styles.selectBox,
                  )}
                  classNamePrefix="react-select"
                />
              </div>

              <div className={styles.formGroup}>
                <label>Assessment Tools</label>
                {/* @ts-ignore */}
                <Select
                  name="assessmentTools"
                  isMulti={true}
                  isClearable={false}
                  isSearchable={false}
                  options={assessmentToolsOptions}
                  placeholder="Select Assessment Tools"
                  value={selectedOption?.assessmentTools}
                  onChange={handleInputChange}
                  className={clsx(
                    "group-by-selector__select",
                    styles.selectBox,
                  )}
                  classNamePrefix="react-select"
                />
              </div>

              <div className={styles.formGroup}>
                <label>Onset Date</label>
                <Flatpickr
                  id="onsetDate"
                  value={selectedOption.onsetDate || new Date()}
                  onChange={handleDateChange("onsetDate")}
                  options={{
                    dateFormat: "Y-m-d",
                    locale: {
                      weekdays: {
                        shorthand: ["S", "M", "T", "W", "T", "F", "S"],
                        longhand: [
                          "Sunday",
                          "Monday",
                          "Tuesday",
                          "Wednesday",
                          "Thursday",
                          "Friday",
                          "Saturday",
                        ],
                      },
                      months: {
                        shorthand: [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ],
                        longhand: [
                          "January",
                          "February",
                          "March",
                          "April",
                          "May",
                          "June",
                          "July",
                          "August",
                          "September",
                          "October",
                          "November",
                          "December",
                        ],
                      },
                      firstDayOfWeek: 1,
                    },
                    shorthandCurrentMonth: true,
                  }}
                  className={clsx(styles.customDatePicker, styles.datepick)}
                />
              </div>

              <div className={styles.formGroup}>
                <label>Estimated Recovery</label>
                <Flatpickr
                  id="estimatedRecovery"
                  value={selectedOption.estimatedRecovery || new Date()}
                  onChange={handleDateChange("estimatedRecovery")}
                  options={{
                    dateFormat: "Y-m-d",
                    locale: {
                      weekdays: {
                        shorthand: ["S", "M", "T", "W", "T", "F", "S"],
                        longhand: [
                          "Sunday",
                          "Monday",
                          "Tuesday",
                          "Wednesday",
                          "Thursday",
                          "Friday",
                          "Saturday",
                        ],
                      },
                      months: {
                        shorthand: [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ],
                        longhand: [
                          "January",
                          "February",
                          "March",
                          "April",
                          "May",
                          "June",
                          "July",
                          "August",
                          "September",
                          "October",
                          "November",
                          "December",
                        ],
                      },
                      firstDayOfWeek: 1,
                    },
                    shorthandCurrentMonth: true,
                  }}
                  className={clsx(styles.customDatePicker, styles.datepick)}
                />
              </div>
            </form>
          </div>
          {/* Body Map TODO: Pass color props based on field selection */}
          <BodyMap
            recordType="PHYSIO"
            selectedSide={form?.side ? form?.side : selectedOption?.side}
            selectedBodyLocation={
              form?.bodyLocation
                ? form?.bodyLocation
                : selectedOption?.bodyLocation?.value
            }
            selectedArea={""}
            selectedSymptom=""
            selectedSeverity=""
            isAthleteCard={false}
          />
        </div>

        <div className={styles.footer}>
          <PrimaryButton
            className={styles.exportButton}
            disabled={isSubmitting}
            onClick={handleSubmitClick}
            title={"Save"}
          />
        </div>
      </div>
    </div>
  );
};
