import s from "./index.module.css";
import { useContext } from "react";
import { AthleteCardContext } from "../../context";
import { MCP } from "./components/MCP";
import { VO2Max } from "./components/VO2Max";
import { CTL } from "./components/CTL";
import { ATL } from "./components/ATL";
import { TSB } from "./components/TSB";
import { RHR } from "./components/RHR";
import { HRV } from "./components/HRV";
import { StatsHeader } from "./components/StatsHeader";
import { HRVRHRFeedback } from "./components/HRVRHRFeedback";

export const Stats = () => {
  return (
    <div className={s.stats}>
      <StatsHeader />

      <div className={s.statsCard}>
        <div className={s.statsCardHeader}>
          <CTL />
          <ATL />
          <TSB />
        </div>

        <div className={s.cardMiddle}>
          <MCP />
          <div className={s.cardMiddleDivider} />
          <VO2Max />
        </div>

        <div className={s.cardFooter}>
          <RHR />
          <HRV />
        </div>
      </div>

      <HRVRHRFeedback />
    </div>
  );
};
