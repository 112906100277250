import s from "./index.module.css";

type IProps = {
  name: string;
  value: number;
  total?: number;
};

export const Parameter = ({ name, value, total = 100 }: IProps) => {
  const style = {
    maxWidth: `${(value / total) * 100}%`,
  };

  return (
    <div className={s.parameter}>
      <div className={`${s.parameterName} analog-typography--body`}>{name}</div>
      <div className={s.parameterProgress}>
        <div className={s.parameterProgressInner} style={style} />
      </div>
    </div>
  );
};
