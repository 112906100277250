import type {
  IFormElementSkeletonProps,
  IFormElementSkeletonSize,
} from "./types.tsx";
import { Skeleton } from "../Skeleton";

export const FormElementSkeleton = (props: IFormElementSkeletonProps) => {
  const size: IFormElementSkeletonSize = props.size || "m";
  const heightMap: Record<IFormElementSkeletonSize, string> = {
    s: "32px",
    m: "40px",
    l: "48px",
  };

  return (
    <div className="analog-formElement__skeleton">
      <Skeleton width="140px" height="21px" />
      <Skeleton width="100%" height={heightMap[size]} />
    </div>
  );
};
