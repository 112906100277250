import { TimeOptions, ComparisonTimeOptions } from "enums";

export const athleteTimePeriods = {
  [TimeOptions.thisWeek]: "This Week",
  [TimeOptions.LastWeek]: "Last Week",
  [TimeOptions.LastThirtyDays]: "Last 30 days",
  [TimeOptions.LastSixtyDays]: "Last 60 days",
  [TimeOptions.LastNinetyDays]: "Last 90 days",
  [TimeOptions.Range]: "Date Range",
};

export const groupTimePeriods = {
  [TimeOptions.thisWeek]: "This Week",
  [TimeOptions.LastWeek]: "Last Week",
  [TimeOptions.LastThirtyDays]: "Last 30 days",
  [TimeOptions.LastSixtyDays]: "Last 60 days",
  [TimeOptions.LastNinetyDays]: "Last 90 days",
  [TimeOptions.Range]: "Date Range",
};

export type ComparisonTimePeriods = {
  [key in ComparisonTimeOptions]: string;
};

export const comparisonTimePeriods: ComparisonTimePeriods = {
  [TimeOptions.thisWeek]: "This Week",
  [TimeOptions.LastWeek]: "Last Week",
  [TimeOptions.LastThirtyDays]: "Last 30 days",
  [TimeOptions.LastSixtyDays]: "Last 60 days",
  [TimeOptions.LastNinetyDays]: "Last 90 days",
  [TimeOptions.LastThreeHundredSixtyFiveDays]: "Last 365 days",
};
