import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";
import { PrimaryButton } from "components/primary-button/primary-button";
import styles from "./drop-file-popup.module.scss";
import { DropFiles } from "components/drop-files/drop-files";

type FileType = {
  name: string;
  size: string;
  file: File;
};

type Props = {
  onClose: () => void;
  selectedFile: FileType[];
  setSelectedFile: React.Dispatch<React.SetStateAction<FileType[]>>;
  showPopup: boolean;
};

export const DropFilePopup: FC<Props> = ({
  onClose,
  selectedFile,
  setSelectedFile,
  showPopup,
}) => {
  return (
    <div className={clsx(styles.popupBox, "comparison-dropdown")}>
      <div
        className={clsx(styles.inputBox, styles.box)}
        data-testid="add-comparison-list"
      >
        <div className={styles.header}>
          <h3 className={styles.title}>Attachments</h3>
          <Close
            className={styles.closeButton}
            onClick={onClose}
            aria-label="Close"
            title="Close"
          />
        </div>

        <div className={styles.formContainer}>
          <DropFiles
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            showPopup={showPopup}
            onClose={onClose}
          />
        </div>
      </div>
    </div>
  );
};
