import s from "./index.module.css";
import { useContext } from "react";
import { AthleteCardContext } from "../../../../context";
import { getAge, parseDate } from "../../../../../../utils";
import { Skeleton } from "../../../../../../storybook";

export const StatsHeader = () => {
  const { athlete, athleteLoading } = useContext(AthleteCardContext);
  const age = athlete && getAge(parseDate(athlete?.dateOfBirth));

  if (athleteLoading) {
    return (
      <header className={s.header}>
        <Skeleton width="200px" height="24px" />
        <div className={s.basicStats}>
          <Skeleton width="240px" height="22px" />
        </div>
      </header>
    );
  }

  return (
    <header className={s.header}>
      <h3 className={`${s.headerTitle} analog-typography--h4 bold`}>
        {athlete?.firstName} {athlete?.lastName}
      </h3>
      <div className={s.basicStats}>
        <div className={`${s.basicStatsItem} analog-typography--headline`}>
          {age} Yrs
        </div>
        <div className={s.basicStatsDivider} />
        <div className={`${s.basicStatsItem} analog-typography--headline`}>
          H: {athlete?.height || "--"}
        </div>
        <div className={`${s.basicStatsItem} analog-typography--headline`}>
          W: {athlete?.weight || "--"}
        </div>
      </div>
    </header>
  );
};
