import { athletes } from "api/athletes";
import { wellness } from "api/wellness";
import { performance } from "api/performance";
import { chart } from "api/chart";

export const api = {
  athletes,
  wellness,
  performance,
  chart,
};
