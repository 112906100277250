import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { SideTabs } from "components/side-tabs/side-tabs";

import styles from "./health-tab.module.scss";
import { FlavorType } from "enums";
import { HealthNotes } from "./health-notes";
import { HealthAttachment } from "./health-attachment";
import { BodyMap } from "components/body-map/body-map";

export enum Tabs {
  OverallWellnessTab,
  BodyCompositionTab,
  HeartMeasurementsTab,
  TrainingTab,
  PowerDerivativesTab,
  FatigueResistanceTab,
  FitnessFatigueTab,
  MenstrualCycleTab,
  TemperatureChartTab,
}

type ActiveTabsMap = {
  [key: number]: boolean;
};

type Props = {
  tabs: {
    id: Tabs;
    name: string;
  }[];
  componentMap: { [key: number]: ReactNode };
  selectedMedicalReport: any;
};

export const HealthTab: FC<Props> = ({
  tabs,
  componentMap,
  selectedMedicalReport,
}) => {
  const clientType = process.env.REACT_APP_FLAVOR as FlavorType;
  const tabsToRenderRef = useRef(tabs);
  const componentsRef = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [activeTabs, setActiveTabs] = useState<ActiveTabsMap>({});

  useEffect(() => {
    const tabsToFilterOut = [Tabs.MenstrualCycleTab, Tabs.TemperatureChartTab];
    if (clientType === FlavorType.uaeMen) {
      tabsToRenderRef.current = tabs.filter(
        (tab) => !tabsToFilterOut.includes(tab.id),
      );
    } else {
      tabsToRenderRef.current = tabs;
    }
  }, [clientType, tabs]);

  useEffect(() => {
    const newTabs: ActiveTabsMap = {};
    tabsToRenderRef.current.forEach((tab, index) => {
      newTabs[tab.id] = index === 0;
    });
    setActiveTabs(newTabs);
  }, [tabs]);

  const tabClickHandler = useCallback(
    (tabId: Tabs) => {
      const newTabs = { ...activeTabs, [tabId]: !activeTabs[tabId] };
      setActiveTabs(newTabs);
      setTimeout(() => {
        componentsRef.current[`${tabId}`]?.scrollIntoView({
          behavior: "smooth",
        });
      }, 0);
    },
    [activeTabs],
  );

  return (
    <>
      <div className={styles.healthTab}>
        <div className={styles.healthNote}>
          <HealthNotes />
        </div>
        <div className={styles.rightTab}>
          <div className={styles.health}>
            <BodyMap
              recordType={selectedMedicalReport?.recordType}
              selectedSide={selectedMedicalReport?.side || "BOTH"}
              selectedBodyLocation={selectedMedicalReport?.bodyLocation || ""}
              selectedArea={selectedMedicalReport?.area || ""}
              selectedSymptom={selectedMedicalReport?.symptom || ""}
              selectedSeverity={selectedMedicalReport?.severity || ""}
              isAthleteCard={false}
            />
          </div>
          <div className={styles.healthAttachment}>
            <HealthAttachment />
          </div>
        </div>
      </div>
    </>
  );
};
