import "./index.css";
import {
  cssTransition,
  ToastContainer as ToastContainerBase,
  ToastContainerProps,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.minimal.css";
import { useCallback } from "react";

export const toastContainerId = "ANALOG_TOAST_CONTAINER;";

const transition = cssTransition({
  enter: "analog-toast-container--show",
  exit: "analog-toast-container--hide",
});

/** Call methods of a Toast static class to show notifications */
export const ToastContainer = (props: ToastContainerProps) => {
  const className = useCallback(() => {
    return "analog-toast-container";
  }, []);

  return (
    <ToastContainerBase
      className={className}
      autoClose={80000}
      closeButton={false}
      closeOnClick={false}
      draggable={false}
      hideProgressBar={true}
      icon={false}
      position={"top-right"}
      transition={transition}
      containerId={toastContainerId}
      {...props}
    />
  );
};
