import React, { FC } from "react";

import styles from "./bar.module.scss";

type Props = {
  value?: number;
  color?: string;
};

export const Bar: FC<Props> = ({ value, color }) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.bar}
        style={{ width: `${value}%`, backgroundColor: color }}
      />
    </div>
  );
};
