import React, { FC } from "react";
import { format } from "date-fns";
import clsx from "clsx";

import { useSelector } from "hooks/app-hooks";
import { useBreakpoints } from "hooks/use-breakpoints";
import { getComparisonName, getCurrentComparison } from "store/slices/shared";

import styles from "./score-card.module.scss";
import { Source } from "components/source/source";

type Props = {
  athleteValue?: number;
  comparisonValue?: number;
  title: string;
  color: string;
  className?: string;
  lastUpdateDate?: number;
  hideLastUpdate?: boolean;
  showNoData?: boolean;
  isCyclist?: boolean;
  source?: string;
};

export const ScoreCard: FC<Props> = ({
  athleteValue,
  comparisonValue,
  title,
  color,
  className,
  lastUpdateDate,
  hideLastUpdate,
  showNoData,
  isCyclist,
  source,
}) => {
  const comparison = useSelector(getComparisonName);
  const currentComparison = useSelector(getCurrentComparison);
  const { isDesktop } = useBreakpoints();
  return (
    <div
      className={clsx(
        styles.container,
        className,
        isCyclist && styles.containerCyclist,
      )}
    >
      {isCyclist && isDesktop && (
        <span className={styles.lastUpdated}>
          {lastUpdateDate && !hideLastUpdate && (
            <>
              <span className={styles.grey}>Last updated:</span>
              {` ${format(lastUpdateDate, "dd/MM/yyyy")}`}
            </>
          )}
          {(!lastUpdateDate || showNoData) && (
            <span className={styles.grey}>No data returned</span>
          )}
        </span>
      )}
      <div
        className={clsx(
          styles.line,
          isCyclist && isDesktop && styles.lineMargin,
        )}
      >
        <span
          className={styles.value}
          style={{ color: athleteValue ? color : undefined }}
        >
          {athleteValue || "-"}
        </span>
        {(!isCyclist || !isDesktop) && (
          <span className={styles.lastUpdated}>
            {lastUpdateDate && !hideLastUpdate && (
              <span>
                <span className={styles.grey}>Last updated:</span>
                {` ${format(lastUpdateDate, "dd/MM/yyyy")}`}
              </span>
            )}
            {(!lastUpdateDate || showNoData) && (
              <span className={styles.grey}>No data returned</span>
            )}
            <Source source={source} className={styles.source} />
          </span>
        )}
      </div>
      <div className={styles.title}>{title}</div>
      {currentComparison !== undefined && (
        <div className={styles.subtitle}>
          {comparison}:{" "}
          <span className={styles.average}>{comparisonValue || "-"}</span>
        </div>
      )}
    </div>
  );
};
