import { COLOR_GREEN, COLOR_LIGHT_GREEN } from "components/charts/colors";
import { DateRange } from "enums";
import { ChartMeta, SeriesValue } from "types";
import { calculateDateRange, dateToStringTimestamp, formatDate } from "utils";

import { APIBoundedChartResponse } from "api/chart";

export const getChartMeta = (
  name: string,
  dateRange: DateRange,
  athleteIds: number[],
): ChartMeta<APIBoundedChartResponse> => {
  const { startDate, endDate } = calculateDateRange(dateRange);
  return {
    api: {
      url: "/wellness/selfReportedWellness/:startDate/:endDate?athleteId=:athleteId",
      params: {
        ":startDate": dateToStringTimestamp(startDate),
        ":endDate": dateToStringTimestamp(endDate),
        ":athleteId": athleteIds.join("&athleteIds="),
      },
    },
    charts: [
      {
        name: `${name}Points`,
        getLabels: (data: APIBoundedChartResponse) => {
          const points = [...data.points];
          points.sort((a, b) => a.timeSinceEpoch - b.timeSinceEpoch);
          return points.map((point) => {
            return formatDate(new Date(point.timeSinceEpoch));
          });
        },
        getValues: (data: APIBoundedChartResponse) => ({
          totalScore: data.points.map((item) => ({
            value: item.data.totalScore,
          })),
          overallScore: data.points.map((item) => ({
            value: item.data.overallScore,
          })),
          fatigueScore: data.points.map((item) => ({
            value: item.data.fatigueScore,
          })),
          stressScore: data.points.map((item) => ({
            value: item.data.stressScore,
          })),
          moodScore: data.points.map((item) => ({
            value: item.data.moodScore,
          })),
          sleepScore: data.points.map((item) => ({
            value: item.data.sleepScore,
          })),
        }),
      },
    ],
  };
};

export const getLineChartSeries = (
  athleteName: string,
  athleteValues: SeriesValue[],
) => {
  const series = [
    {
      name: athleteName,
      chartType: "line",
      color: COLOR_GREEN,
      shadowColor: COLOR_LIGHT_GREEN,
      shadowBlur: 10,
      shadowOffsetY: 4,
      values: athleteValues,
    },
  ];
  return series;
};
