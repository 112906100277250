import s from "./index.module.css";
import { useContext } from "react";
import { AthleteCardContext } from "../../../../context";
import { Skeleton } from "../../../../../../storybook";

export const CTL = () => {
  const { athlete, athleteLoading } = useContext(AthleteCardContext);

  if (athleteLoading) {
    return <Skeleton width="60px" height="24px" />;
  }

  return (
    <div className={s.statValue}>
      <div className={`${s.tokenSecondary} analog-typography--headline`}>
        CTL
      </div>
      <div className={`${s.tokenPrimary} analog-typography--headline bold`}>
        {athlete?.ctl?.toFixed(0) || "--"}
      </div>
    </div>
  );
};
