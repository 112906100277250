import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

import { ChartSize } from "components/charts/constants";
import { MemoizedLegendButton } from "components/legend-button/legend-button";
import { ChartColor } from "components/charts/types";
import { chunkArray } from "utils";
import debounce from "lodash/debounce";

import styles from "./with-header.module.scss";
import { SourceBar } from "components/source-bottom-bar/source-bottom-bar";
import { trackChartInteraction } from "appInsights/logInsights";

type Series = {
  name: string;
  color: ChartColor;
  dashed?: boolean;
  hideInLegend?: boolean;
  colorRange?: { color: string; range: [number, number]; title: string }[];
  showMainCircle?: boolean;
  showSubCircles?: boolean;
  className?: string;
};

type WithHeaderProps = {
  name?: string;
  units?: string;
  type?: ChartSize;
  source?: string;
  series: (Series | undefined)[];
  headerComponent?: React.ReactNode;
  showLegendInSourceBar?: boolean;
  savedLabels?: () => Promise<void>;
  capitalizeFirstLetter?: boolean;
  hideSeries?: { [key: string]: { hidden?: boolean; count?: number } };
  setHideSeries?: React.Dispatch<
    React.SetStateAction<{
      [key: string]: { hidden?: boolean; count?: number };
    }>
  >;
};

type EChartLegendClickAction = {
  type: "legendToggleSelect";
  name: string;
};

type EChartLegendHoverAction = {
  type: "highlight" | "downplay";
  seriesName: string;
};

export type EChartAction = EChartLegendClickAction | EChartLegendHoverAction;

export function withHeader<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ChartWithHeader = (props: T & WithHeaderProps) => {
    const chartRef = useRef<ReactEcharts>(null);
    const eventListenersRef = useRef<{ click: any; mouseover: any } | null>(
      null,
    );

    const {
      name,
      units,
      series,
      source,
      headerComponent,
      type = ChartSize.Small,
      showLegendInSourceBar,
      savedLabels,
      hideSeries,
      setHideSeries,
      capitalizeFirstLetter,
    } = props;

    const filteredSeries = series
      .filter((el) => el && !el.hideInLegend)
      .map((el) => el as Series);

    const onChartReady = (echartsInstance: echarts.ECharts) => {
      trackChartInteraction("chartLoaded", {
        chartName: name,
      });

      if (eventListenersRef.current) {
        return;
      }

      const chartClickHandler = debounce((params: any) => {
        const value =
          params.value !== undefined ? String(params.value) : undefined;
        trackChartInteraction("click", {
          chartName: name,
          seriesName: params.seriesName as string,
          value: value,
        });
      }, 500);

      const chartMouseoverHandler = debounce((params: any) => {
        const value =
          params.value !== undefined ? String(params.value) : undefined;
        trackChartInteraction("hover", {
          chartName: name,
          seriesName: params.seriesName as string,
          value: value,
        });
      }, 500);

      echartsInstance.on("click", chartClickHandler);
      echartsInstance.on("mouseover", chartMouseoverHandler);

      eventListenersRef.current = {
        click: chartClickHandler,
        mouseover: chartMouseoverHandler,
      };
    };

    useEffect(() => {
      const currentChartRef = chartRef.current; // Capture current ref value

      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            trackChartInteraction("scrollIntoView", {
              chartName: name,
            });
          }
          if (entry.isIntersecting && currentChartRef) {
            const chartInstance = currentChartRef.getEchartsInstance(); // Use captured ref
            if (chartInstance) {
              chartInstance.resize();
            }
          }
        },
        { threshold: 0.5 },
      );

      if (currentChartRef && currentChartRef.ele) {
        observer.observe(currentChartRef.ele);
      }

      return () => {
        if (currentChartRef && currentChartRef.ele) {
          observer.unobserve(currentChartRef.ele);
        }
      };
    }, [name]);

    const handleEChartAction = useCallback((action: EChartAction) => {
      const instance = chartRef.current?.getEchartsInstance();
      if (instance) {
        instance.dispatchAction(action);
      }
    }, []);

    const seriesChunked = chunkArray<Series>(filteredSeries);

    return (
      <>
        <div className={styles.content}>
          {headerComponent}
          <div
            className={clsx(
              type === ChartSize.Big ? styles.bigHeader : styles.smallHeader,
              headerComponent && styles.alignRight,
            )}
          >
            {name && !headerComponent && (
              <div
                className={
                  type === ChartSize.Big ? styles.bigTitle : styles.smallTitle
                }
              >
                <span className={styles.bold}>{name}</span>
                &nbsp;
                {units}
              </div>
            )}
            {!showLegendInSourceBar && (
              <div
                className={clsx(styles.legendItems, !name && styles.fullWidth)}
              >
                {seriesChunked.map((seriesChunk) => (
                  <div
                    key={seriesChunk[0].name}
                    className={clsx(
                      styles.legendItem,
                      filteredSeries.length <= 2 && styles.oneLineLegend,
                    )}
                  >
                    {seriesChunk.map(
                      (item) =>
                        !item.hideInLegend && (
                          <MemoizedLegendButton
                            key={item.name}
                            onEChartAction={handleEChartAction}
                            name={item.name}
                            color={item.color}
                            dashed={item.dashed}
                            type={type}
                          />
                        ),
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={styles.chart}>
            {}
            <WrappedComponent
              {...(props as T)}
              ref={chartRef}
              name={name}
              onChartReady={onChartReady}
              hideSeries={hideSeries}
              setHideSeries={setHideSeries}
            />
          </div>
        </div>

        {props.source && (
          <SourceBar source={source} chartType={type}>
            {showLegendInSourceBar && (
              <div
                className={clsx(
                  styles.sourceBarLegendItems,
                  !name && styles.fullWidth,
                )}
              >
                {seriesChunked.map((seriesChunk) => (
                  <div
                    key={seriesChunk[0].name}
                    className={clsx(styles.sourceBarLegendItem)}
                  >
                    {seriesChunk.map((item) => {
                      return (
                        !item.hideInLegend && (
                          <MemoizedLegendButton
                            active={!hideSeries?.[item.name]?.hidden}
                            savedLabels={savedLabels}
                            key={item.name}
                            onEChartAction={handleEChartAction}
                            name={item.name}
                            color={item.color}
                            dashed={item.dashed}
                            showMainCircle={item.showMainCircle}
                            showSubCircles={item.showSubCircles}
                            type={type}
                            ranges={item.colorRange?.map((range) => ({
                              min: range.range[0],
                              max: range.range[1],
                              color: range.color,
                              title: range.title,
                            }))}
                          />
                        )
                      );
                    })}
                  </div>
                ))}
              </div>
            )}
          </SourceBar>
        )}
      </>
    );
  };

  ChartWithHeader.displayName = `withHeader(${displayName})`;

  return ChartWithHeader;
}
