import type { ITruncatedTextProps } from "./types.ts";
import "./index.css";
import { useEffect, useRef, useState } from "react";
import { Tooltip } from "../Tooltip";
import { Typography } from "../Typography";
import { clsx } from "clsx";

export const TruncatedText = ({
  text,
  className,
  tooltipProps,
}: ITruncatedTextProps) => {
  const rootClass: string = clsx("analog-truncated-text", className);
  const ref = useRef<HTMLDivElement>(null);
  const [withTooltip, setWithTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new ResizeObserver(() => {
      if (!ref.current) {
        return;
      }

      if (ref.current.scrollWidth > ref.current.clientWidth) {
        setWithTooltip(true);
      }
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={rootClass} ref={ref}>
      {text}
      {withTooltip && (
        <Tooltip
          {...tooltipProps}
          placement={tooltipProps?.placement || "bottom-start"}
          delay={
            tooltipProps?.delay || {
              open: 500,
              close: 0,
            }
          }
          showArrow={tooltipProps?.showArrow || false}
          content={
            tooltipProps?.content || (
              <Typography variant="subtitle" text={text} />
            )
          }
          portal
          anchor={<div className="analog-truncated-text__tooltip-anchor" />}
        />
      )}
    </div>
  );
};
