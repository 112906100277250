function unsecuredCopyToClipboard(text: string) {
  console.log("Clipboard API is not supported");
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Could not copy element:", err);
  }
  document.body.removeChild(textArea);
}

export function onCopy(text: string): Promise<void> {
  if (!navigator?.clipboard) {
    return Promise.reject("navigator.clipboard is not defined");
  }

  return navigator.clipboard.writeText(text).catch(() => {
    unsecuredCopyToClipboard(text);
  });
}
