import React, { RefObject, useMemo, useRef, useState } from "react";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

import { COLOR_TOOLTIP, DEFAULT_FONT_COLOR } from "components/charts/colors";
import { ChartProps } from "components/charts/types";
import { NoData } from "components/charts/no-data/no-data";

import styles from "./radar-chart.module.scss";
import { MaxLabel } from "./custom-label/max-label";
import { trackChartInteraction } from "appInsights/logInsights";
import debounce from "lodash/debounce";

export type RadarChartIndicator = {
  name: string;
  max: number;
  indicatorColor: string;
  unit?: string;
  selectedOption?: string;
};

type RadarChartProps = ChartProps<{
  decimalPoint?: number;
  indicators: RadarChartIndicator[];
  series: (RadarChartSeries | undefined)[];
}>;

export type RadarChartSeries = {
  name: string;
  color: string;
  values: (number | undefined)[];
  dashed?: boolean;
};

enum LabelPositions {
  TopLeft = 0,
  Left = 1,
  BottomLeft = 2,
  BottomCenter = 3,
  BottomRight = 4,
  Right = 5,
  TopRight = 6,
  TopCenter = 7,
}

const getLabelPositioningStyle: (
  index: number,
  halfHeight: number,
  halfWidth: number,
) => React.CSSProperties = (index, halfHeight, halfWidth) => {
  switch (index) {
    case LabelPositions.TopCenter:
      return {
        bottom: halfHeight + 145,
        right: halfWidth,
        transform: "translateX(50%) translateY(0)",
      };
    case LabelPositions.BottomCenter:
      return {
        bottom: halfHeight - 180,
        right: halfWidth,
        flexDirection: "column-reverse",
        transform: "translateX(50%) translateY(0)",
      };
    case LabelPositions.Left:
      return {
        bottom: halfHeight - 15,
        right: halfWidth + 145,
        textAlign: "right",
        transform: "rotate(270deg)",
        zIndex: 2,
      };
    case LabelPositions.TopLeft:
      return {
        bottom: halfHeight + 100,
        right: halfWidth + 100,
        textAlign: "right",
        transform: "rotate(315deg)",
        zIndex: 3,
      };
    case LabelPositions.BottomLeft:
      return {
        top: halfHeight + 100,
        right: halfWidth + 105,
        flexDirection: "column-reverse",
        transform: "rotate(45deg)",
        textAlign: "right",
      };
    case LabelPositions.Right:
      return {
        top: halfHeight - 15,
        left: halfWidth + 150,
        transform: "rotate(90deg)",
      };
    case LabelPositions.BottomRight:
      return {
        top: halfHeight + 100,
        left: halfWidth + 110,
        flexDirection: "column-reverse",
        transform: "rotate(-45deg)",
      };
    case LabelPositions.TopRight:
      return {
        bottom: halfHeight + 100,
        left: halfWidth + 100,
        transform: "rotate(45deg)",
      };
    default:
      return { display: "none" };
  }
};

export const AthleteRadarChart = React.forwardRef<
  ReactEcharts,
  RadarChartProps
>(({ series, indicators, decimalPoint = 0, name }, ref) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const eventListenersRef = useRef<{ click: any; mouseover: any } | null>(null);

  const filteredSeries: RadarChartSeries[] = series
    .filter((el) => el)
    .map((el) => el as RadarChartSeries);

  const hasData = useMemo(
    () =>
      filteredSeries.some((seriesItem) => {
        return seriesItem.values.filter((value) => value).length > 0;
      }),
    [filteredSeries],
  );

  const onChartReady = (echartsInstance: echarts.ECharts) => {
    trackChartInteraction("chartLoaded", {
      chartName: name,
    });

    if (eventListenersRef.current) {
      return;
    }

    const chartClickHandler = debounce((params: any) => {
      const value =
        params.value !== undefined ? String(params.value) : undefined;
      trackChartInteraction("click", {
        chartName: name,
        seriesName: params.seriesName as string,
        value: value,
      });
    }, 500);

    const chartMouseoverHandler = debounce((params: any) => {
      const value =
        params.value !== undefined ? String(params.value) : undefined;
      trackChartInteraction("hover", {
        chartName: name,
        seriesName: params.seriesName as string,
        value: value,
      });
    }, 500);

    echartsInstance.on("click", chartClickHandler);
    echartsInstance.on("mouseover", chartMouseoverHandler);

    eventListenersRef.current = {
      click: chartClickHandler,
      mouseover: chartMouseoverHandler,
    };
  };

  const onEvents = useMemo(
    () => ({
      rendered: () => {
        const current = (ref as RefObject<ReactEcharts>)?.current;
        if (current) {
          setWidth(current.ele.clientWidth);
          setHeight(current.ele.clientHeight);
        }
      },
    }),
    [ref],
  );

  return (
    <div className={styles.container}>
      {!hasData && <NoData type="bar" />}
      {width !== 0 &&
        height !== 0 &&
        indicators.map((indicator, index) => (
          <MaxLabel
            key={indicator.name}
            decimalPoint={decimalPoint}
            style={getLabelPositioningStyle(index, height / 2, width / 2)}
            indicator={indicator}
          />
        ))}
      <ReactEcharts
        ref={ref}
        opts={{ renderer: "svg" }}
        style={{ height: "100%" }}
        onChartReady={onChartReady}
        onEvents={onEvents}
        option={{
          legend: {
            show: false,
          },
          grid: {},
          xAxis: {
            show: false,
          },
          yAxis: {
            show: false,
          },
          tooltip: {
            order: "seriesDesc",
            backgroundColor: COLOR_TOOLTIP,
            borderColor: COLOR_TOOLTIP,
            valueFormatter: (value: number) => value.toFixed(decimalPoint),
            textStyle: {
              color: DEFAULT_FONT_COLOR,
              fontSize: 12,
              fontFamily: "Montserrat",
            },
          },
          radar: {
            indicator: indicators,
            startAngle: 135,
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#2E3748",
                width: 1,
                opacity: hasData ? 1 : 0.5,
              },
            },
            splitArea: {
              areaStyle: {
                color: [],
              },
            },
            axisName: {
              show: true,
              color: "transparent",
            },
            axisLine: {
              lineStyle: {
                color: "#2E3748",
              },
            },
          },
          series: [
            {
              type: "radar",
              symbol: hasData ? "circle" : "none",
              data: [
                ...filteredSeries.map((item) => ({
                  value: item.values,
                  name: item.name,
                  areaStyle: {
                    opacity: item.dashed ? 0 : 0.2,
                  },
                  itemStyle: {
                    color: item.color,
                  },
                  lineStyle: {
                    join: "round",
                    cap: "round",
                    type: item.dashed ? "dashed" : "solid",
                  },
                })),
              ],
            },
          ],
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  pane: { size: "55%", startAngle: 0 },
                },
              },
            ],
          },
        }}
      />
    </div>
  );
});

AthleteRadarChart.displayName = "AthleteRadarChart";
