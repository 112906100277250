import { addLeadingZero } from "./addLeadingZero";

export const convertDateToString = (date: Date | undefined): string => {
  if (!date) {
    return "";
  }

  const dd = addLeadingZero(date.getDate());
  const mm = addLeadingZero(date.getMonth() + 1);
  const yyyy = date.getFullYear();

  return `${dd}.${mm}.${yyyy}`;
};
