import { trackError } from "appInsights/logInsights";
import { axiosInstance } from "axios-instance/axios-instance";

interface PowerHeldResponse {
  status?: string;
  timestamp?: string;
  message?: string;
  debugMessage?: string;
  code?: number;
  data?: any;
}

interface DataFields {
  [key: string]: any;
}

export const postData = async (
  athleteId: number,
  dataFields: DataFields,
  inputBy: number,
  date: string | null,
  endpoint: string,
  fullUrl?: string,
): Promise<PowerHeldResponse | null> => {
  const requestBody = {
    athleteId,
    ...dataFields,
    inputBy,
    ...(date && { date }),
  };

  try {
    const response = await axiosInstance.post<PowerHeldResponse>(
      fullUrl ? fullUrl : `/performance/input/sft/${endpoint}`,
      requestBody,
      {},
    );
    return response.data || null;
  } catch (error: any) {
    trackError(error as Error, { location: "postData", endpoint });
    console.error(
      "Request failed:",
      error.response ? error.response.data : error,
    );

    throw error;
  }
};
