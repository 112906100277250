import s from "./index.module.css";
import { useCallback, useState } from "react";
import { ISliderKeys } from "../FeedbackForm";
import { Slider, Typography } from "../../../../../../storybook";

type IProps = {
  question: string;
  options: string[];
  name: string;
  onChange: (name: ISliderKeys, value: string) => void;
};

export const FeedbackFormSlider = ({
  question,
  options,
  name,
  onChange,
}: IProps) => {
  const [displayValue, setDisplayValue] = useState<string>(options[0]);
  const handleChange = useCallback(
    (value: number) => {
      setDisplayValue(options[value]);
      onChange(name as ISliderKeys, options[value]);
    },
    [name, onChange, options],
  );

  return (
    <div className={s.formSlider}>
      <Typography as="div" className={s.question} text={question} />
      <Slider
        max={options.length - 1}
        onChange={handleChange}
        displayValue={displayValue}
      />
      <div className={s.boundaries}>
        <Typography className={s.boundary} text={options[0]} />
        <Typography className={s.boundary} text={options[options.length - 1]} />
      </div>
    </div>
  );
};
