import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from "const";
import React, { useEffect, useMemo, useState } from "react";

import { countryCodesMapping } from "country-codes-mapping";

import { getAge, parseDate } from "utils";
import { getCurrentAthlete } from "store/slices/shared";
import { useSelector } from "hooks/app-hooks";

import styles from "./athlete-details.module.scss";
import { Athlete } from "types";

type UserInfoProps = {
  athlete?: Athlete;
};

const AthleteDetails: React.FC<UserInfoProps> = ({ athlete }) => {
  if (!athlete) return null;
  return (
    <div className={styles.wrapper}>
      <div className={styles.avatar}>
        <img className={styles.image} src={athlete.picture} alt="User avatar" />
      </div>
      <div className={styles.info}>
        <div className={styles.athleteHeader}>
          <div className={styles.name}>
            <span>{athlete.firstName}</span> <span>{athlete.lastName}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AthleteDetails);
