import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useState,
} from "react";

const getValue = (e: ChangeEvent<HTMLInputElement>): string | boolean => {
  if (e.target.type === "checkbox") {
    return e.target.checked;
  }
  return e.target.value;
};

export function useForm<Form>(
  initialValues: Form,
): [Form, ChangeEventHandler, Dispatch<SetStateAction<Form>>] {
  const [form, setForm] = useState(initialValues);
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [e.target.name]: getValue(e) }));
  };
  return [form, changeHandler, setForm];
}
