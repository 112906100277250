const IllnessChestInfection = ({ opacity }: { opacity: number }) => {
  return (
    <svg
      width="160"
      height="410"
      viewBox="0 0 160 410"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={opacity}
    >
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.021 177.086C109.001 177.016 108.99 176.981 108.99 176.981C105.655 146.193 111.758 130.14 114.053 125.374C114.155 125.164 114.474 125.279 114.419 125.506C112.452 133.647 114.307 140.627 116.17 147.637C117.165 151.378 118.161 155.126 118.581 159.065C118.623 159.461 118.669 159.871 118.72 160.293C120.079 155.959 120.814 151.321 120.814 146.5C120.814 122.476 102.541 103 79.9996 103C57.4582 103 39.1848 122.476 39.1848 146.5C39.1848 151.515 39.9812 156.333 41.4466 160.816C41.5227 160.208 41.5909 159.623 41.6503 159.065C42.0698 155.126 43.0663 151.378 44.0604 147.637C45.9238 140.627 47.7791 133.647 45.8115 125.506C45.7567 125.279 46.0762 125.164 46.1775 125.374C48.4728 130.14 54.5762 146.193 51.2409 176.981C51.2409 176.981 51.2109 177.081 51.1546 177.276C58.5394 185.138 68.7368 190 79.9996 190C91.3526 190 101.623 185.06 109.021 177.086Z"
        fill="#FF4500"
      />
    </svg>
  );
};

export default IllnessChestInfection;
