import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

import { useSelector } from "hooks/app-hooks";
import { AttentionPopup } from "components/session-expired-popup/attention-popup";
import {
  getAccount,
  getAuthState,
  setAccount,
  setAuthState,
} from "store/slices/auth";
import { getCoachProfile, getProfile } from "api/profile";
import {
  fetchAllAthletes,
  getCoachProfile as getCoachProfileSelector,
  setCoachProfile,
} from "store/slices/shared";
import { RoleType } from "enums";
import { useAppDispatch } from "../../store";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Loading } from "../loading/loading.tsx";
import { intercept } from "../../axios-instance/axios-instance.ts";

type PrivateRouteProps = {
  children: ReactElement;
  path: string;
  exact?: boolean;
};

export const PrivateRoute = ({
  children,
  path,
  exact = false,
}: PrivateRouteProps) => {
  const dispatch = useAppDispatch();
  const role = useSelector(getAccount);
  const coachProfileInfo = useSelector(getCoachProfileSelector);
  const userProfileLoaded = useRef<boolean>(false);

  const { instance } = useMsal();
  const account = useAccount();
  const authenticatedCredentials = useSelector(getAuthState);
  const authenticatedMS = useIsAuthenticated();
  const authenticated = authenticatedMS || authenticatedCredentials;
  const [msAccessTokenIsSet, setMsAccessTokenIsSet] = useState<boolean>(false);
  const msAuthHasToken = authenticated && authenticatedMS && msAccessTokenIsSet;

  useEffect(() => {
    if (!account) {
      intercept();
      return;
    }

    const onAcquireToken = (accessToken: string) => {
      const token = `Bearer ${accessToken}`;
      dispatch(setAuthState(true, token));
      intercept();
      setMsAccessTokenIsSet(true);
    };

    if (account?.idToken) {
      onAcquireToken(account.idToken);
    } else {
      const request = {
        scopes: ["openid"],
      };

      instance.acquireTokenSilent(request).then((response) => {
        onAcquireToken(response.accessToken);
      });
    }
  }, [account, dispatch, instance]);

  useEffect(() => {
    if (!authenticatedCredentials && !msAuthHasToken) {
      return;
    }

    if (role === RoleType.coach) {
      if (!coachProfileInfo) {
        getCoachProfile().then((coachProfile) => {
          dispatch(setCoachProfile(coachProfile));
        });
      }
    }

    if (!userProfileLoaded.current) {
      getProfile().then((profile) => {
        if (profile) {
          userProfileLoaded.current = true;
          dispatch(fetchAllAthletes(profile));
          // @ts-ignore
          dispatch(setAccount(profile.role ?? RoleType.coach));
        }
      });
    }
  }, [
    dispatch,
    coachProfileInfo,
    role,
    msAuthHasToken,
    authenticatedCredentials,
  ]);

  if (authenticatedMS && !account) {
    return <Loading />;
  }

  return (
    <>
      {authenticated === null ? (
        <AttentionPopup
          subtitle="Your session has expired, please login again."
          description="The page will be reloaded."
          onClose={() => dispatch(setAuthState(false))}
        />
      ) : (
        <Route
          path={path}
          render={({ location }) =>
            authenticated ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: "/sign-in",
                  state: { from: location },
                }}
                exact={exact}
              />
            )
          }
        />
      )}
    </>
  );
};
