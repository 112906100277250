import { useForm } from "react-hook-form";
import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AxiosError } from "axios";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { setNewPassword, verifyOTP } from "../../api/profile.ts";
import { Toast } from "../../storybook";
import { getTeamName, getURIQueryParams } from "../../utils.ts";
import { QueryParams } from "../../types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../router/routes.ts";

type IFormData = {
  password: string;
  reTypePassword: string;
};

export const useViewModel = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryParamsRef = useRef<QueryParams | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(false);
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);

  useEffect(() => {
    const { otp, email, team } = getURIQueryParams();

    if (otp && email && team) {
      verifyOTP(email, otp, team)
        .then(() => {
          queryParamsRef.current = { otp, email, team };
          setValidating(false);
        })
        .catch(() => {
          history.push(`/${ROUTES.SIGN_IN}`);
        });
    } else {
      history.push(`/${ROUTES.SIGN_IN}`);
    }
  }, [history]);

  const schema = useMemo(() => {
    return z
      .object({
        password: z.string().min(1, { message: t("validation.required") }),
        reTypePassword: z
          .string()
          .min(1, { message: t("validation.required") }),
      })
      .refine((data) => data.password === data.reTypePassword, {
        message: t("validation.passwords_must_match"),
        path: ["reTypePassword"],
      });
  }, [t]);

  const form = useForm<IFormData>({
    defaultValues: {
      password: "",
      reTypePassword: "",
    },
    resolver: zodResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const handleResetPassword = useCallback(
    async (formData: IFormData) => {
      setLoading(true);

      return setNewPassword({
        otp: queryParamsRef.current?.otp ?? "",
        email: queryParamsRef.current?.email ?? "",
        newPassword: formData.password,
        team: getTeamName(),
      })
        .then(() => {
          setPasswordChanged(true);
          setLoading(false);
        })
        .catch((e: AxiosError) => {
          Toast.error({
            title: t("error.reset_password_title"),
            message: e.message,
          });
          setLoading(false);
        });
    },
    [t],
  );

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      handleSubmit((formData: IFormData) => {
        setLoading(true);
        handleResetPassword(formData);
      })();
    },
    [handleResetPassword, handleSubmit],
  );

  return {
    form,
    onSubmit,
    errors,
    loading,
    validating,
    passwordChanged,
  };
};
