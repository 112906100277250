import React, { useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./ReusableInputField.module.scss";

export interface ReusableInputFieldProps {
  name: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeMultiline?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  error?: boolean;
  label: string;
  min?: number;
  max?: number;
  unit?: string;
  showCharCount?: boolean;
  type?: string;
  showMinMax?: boolean;
  multiline?: boolean;
  placeholderText?: string;
  toolTipComponent?: React.ReactNode;
}

export const ReusableInputField: React.FC<ReusableInputFieldProps> = ({
  name,
  value = "",
  onChange,
  onChangeMultiline,
  error,
  label,
  min,
  max,
  unit = "CM",
  showCharCount = false,
  type = "number",
  showMinMax = true,
  multiline = false,
  placeholderText = "Enter value",
  toolTipComponent,
}) => {
  const [defValue, setDefValue] = useState<string>(value);

  useEffect(() => {
    setDefValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (!showCharCount || newValue.length <= 250) {
      setDefValue(newValue);
      if (onChange) {
        onChange(e);
      }
    }
  };
  const handleChangeMultiline = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    if (!showCharCount || newValue.length <= 250) {
      setDefValue(newValue);
      if (onChangeMultiline) {
        onChangeMultiline(e);
      }
    }
  };

  return (
    <div className={styles.inputBlock}>
      <label htmlFor={name} className={styles.label}>
        {label} {toolTipComponent}
        <div className={styles.inputWrapper}>
          {multiline ? (
            <textarea
              id={name}
              name={name}
              value={defValue}
              placeholder={placeholderText}
              maxLength={250}
              onChange={handleChangeMultiline}
              className={clsx(styles.textarea, error && styles.errorInput)}
            />
          ) : (
            <input
              type={type}
              id={name}
              name={name}
              value={defValue}
              placeholder={placeholderText}
              onChange={handleChange}
              className={clsx(styles.reusableInput, error && styles.errorInput)}
            />
          )}
          {showMinMax && min && max && (
            <span className={styles.rangeInfo}>
              {`${unit} (${min.toFixed(1)} - ${max.toFixed(1)})`}
            </span>
          )}
          {showCharCount && (
            <div className={styles.charCount}>{defValue.length}/250</div>
          )}
        </div>
      </label>
    </div>
  );
};
