import s from "./index.module.css";
import {
  COLOR_WHITE,
  HRV_RHR_DARK_GREEN,
  HRV_RHR_DARK_ORANGE,
  HRV_RHR_LIGHT_GREEN,
  HRV_RHR_LIGHT_YELLOW,
} from "../../../../../../components/charts/colors";
import { useContext } from "react";
import { AthleteCardContext } from "../../../../context";
import { Skeleton } from "../../../../../../storybook";

export const RHR = () => {
  const { athlete, athleteLoading } = useContext(AthleteCardContext);

  const getRHRVariance = (value: number | undefined) => {
    if (!value) {
      return null;
    }
    let color = COLOR_WHITE;
    if (value > 2 && value < 4) {
      color = HRV_RHR_LIGHT_YELLOW;
    } else if (value >= 4) {
      color = HRV_RHR_DARK_ORANGE;
    } else if (value < -2 && value > -4) {
      color = HRV_RHR_LIGHT_GREEN;
    } else if (value <= -4) {
      color = HRV_RHR_DARK_GREEN;
    }
    const sign = value > 0 ? "+" : "";
    const signValue = `${sign}${value.toFixed(0)}`;
    return (
      <div
        className={`${s.tokenPrimary} analog-typography--subtitle`}
        style={{ color }}
      >
        ({signValue})
      </div>
    );
  };

  if (athleteLoading) {
    return <Skeleton width="100px" height="24px" />;
  }

  return (
    <div className={s.statValue}>
      <div className={`${s.tokenSecondary} analog-typography--subtitle`}>
        RHR
      </div>
      {getRHRVariance(athlete?.rhrVariance)}
      <div className={`${s.tokenPrimary} analog-typography--headline`}>
        {athlete?.rhr?.toFixed(0) || "--"}
      </div>
    </div>
  );
};
