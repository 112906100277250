import React, { FC } from "react";
import clsx from "clsx";
import { ReactComponent as SearchIconSVG } from "images/search.svg";
import styles from "./group-search.module.scss";
import "./styles.scss";

type Props = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  input: string;
  placeHolderText?: string;
};

export const GroupSearch: FC<Props> = ({
  handleChange,
  input,
  placeHolderText,
}) => {
  return (
    <div className={styles.container}>
      <div className={clsx(styles.wrapper, "search-input")}>
        <input
          type="text"
          placeholder={placeHolderText ?? "Search For Group"}
          value={input}
          onChange={handleChange}
          className={clsx(styles.control, "custom-input-class")}
        />
        <SearchIconSVG className={styles.searchIcon} />
      </div>
    </div>
  );
};
