import React, { FC } from "react";
import Select from "react-windowed-select";
import clsx from "clsx";

import { useDispatch, useSelector } from "hooks/app-hooks";
import {
  getAthleteList,
  getSelectedGroup,
  setSelectedGroup,
} from "store/slices/shared";

import styles from "./group-selector.module.scss";
import "./group-selector.overwrite.scss";
import { Group } from "types";
import { useAppDispatch } from "../../store";

interface SelectorOption {
  label: string;
  value: Group;
}

interface Props {
  small?: boolean;
  groups: Group[] | undefined;
}

export const GroupSelector: FC<Props> = ({ small, groups }) => {
  const dispatch = useAppDispatch();
  const currentGroup = useSelector(getSelectedGroup);
  const allAthlete =
    useSelector(getAthleteList)?.map((athlete) => athlete.id) || [];
  const allAthleteGroup = {
    id: -1,
    name: "All Athletes",
    athleteIds: allAthlete,
    description: "",
  };
  const options = [
    { label: allAthleteGroup.name, value: allAthleteGroup },
    ...(groups?.map(
      (group) => ({ label: group.name, value: group }) as SelectorOption,
    ) || []),
  ];

  const value: SelectorOption | undefined = options.find(
    (option) => option.value.id === currentGroup?.id,
  );
  const handleChange = (newOption: SelectorOption) => {
    dispatch(setSelectedGroup(newOption.value));
  };

  return (
    <div className={clsx(styles.wrapper, "date-group-by-selector")}>
      {/* @ts-ignore */}
      <Select
        isClearable={false}
        isSearchable={false}
        options={options}
        value={value}
        defaultValue={value}
        onChange={handleChange}
        className={clsx(
          "group-selector__select",
          small && "group-selector__select--small",
        )}
        classNamePrefix="react-select"
      />
    </div>
  );
};
