import { IDialogContext, IDialogProps } from "./types.tsx";
import { useBoolean } from "../../hooks/useBoolean.tsx";
import {
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";

export const useViewModel = (props: IDialogProps): IDialogContext => {
  const { onDismiss, outsidePress = true } = props;

  const { value: isFullScreen, toggle: toggleFullScreen } = useBoolean(false);

  const data = useFloating({
    open: true,
    onOpenChange: onDismiss,
  });

  const floatingContext = data.context;
  const dismiss = useDismiss(floatingContext, {
    outsidePressEvent: "click",
    outsidePress,
  });
  const role = useRole(floatingContext);
  const { getFloatingProps } = useInteractions([dismiss, role]);

  return {
    ...props,
    isFullScreen,
    toggleFullScreen,
    onDismiss,
    floatingContext,
    getFloatingProps,
  };
};
