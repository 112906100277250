import { NULL_DATE } from "const";
import { roundFloat } from "utils";

export type APILastUpdateScore = {
  date: string;
  data: number;
  source?: string;
};

export type APIWellnessLastUpdateScore = {
  date?: string;
  timeSinceEpoch?: number;
  data: {
    generalScore: number;
    fatigueScore: number;
    stressScore: number;
    moodScore: number;
    sleepScore: number;
    totalScore: number;
  };
};

export const adaptLastUpdateScore = (score: APILastUpdateScore) => {
  if (!score || score.date === NULL_DATE) {
    return {
      date: undefined,
      data: undefined,
      source: score?.source,
    };
  }
  return {
    date: new Date(score.date).getTime(),
    data: roundFloat(score.data, 1),
    source: score?.source,
  };
};

export const adaptWellnessLastUpdateScore = (
  score: APIWellnessLastUpdateScore,
) => {
  if (!score?.timeSinceEpoch) {
    return {
      date: undefined,
      data: undefined,
    };
  }

  return {
    date: score.timeSinceEpoch,
    data: {
      generalScore: score.data.generalScore,
      fatigueScore: score.data.fatigueScore,
      stressScore: score.data.stressScore,
      moodScore: score.data.moodScore,
      sleepScore: score.data.sleepScore,
      totalScore: score.data.totalScore,
    },
  };
};
